<div class="p-4 quiz-dialog" *ngIf="quiz">
    <div class="text-center quiz-title">
        <h2>{{quiz.quizTitle}}</h2>
    </div>
    <div class="row m-0 p-0" style="align-items: center;">
        <img src="{{data.teacher.profilePic}}" class="rounded-circle teacher-pic" alt="" width="50" height="50">
        &nbsp;&nbsp;&nbsp;
        <span class="text-secondary teacher-name" style="font-size: 1.2em;"><b> {{data.teacher.teacherName}} </b></span>
    </div>
    <div class="row m-0 p-0 text-secondary mt-4">
        <div class="offset-1 col-2 p-0">
            <span class="quiz-details">Subject:</span>
        </div>
        <div class="col-3 p-0">
            <span class="quiz-details">{{quiz.subjectName}}</span>
        </div>
        <div class="col-3 p-0">
            <span class="quiz-details">Start Date:</span>
        </div>
        <div class="col-3 p-0 row m-0">
            <div class="quiz-details">{{quiz.startDate}}</div>
            <div class="quiz-details">&nbsp; {{timeStr12hrs(quiz.startTime)}}</div>
        </div>
        <div class="offset-1 col-2 p-0 mt-2">
            <span class="quiz-details">Quiz Type:</span>
        </div>
        <div class="col-3 p-0 mt-2">
            <span class="quiz-details">{{quiz.type}}</span>
        </div>
        <div class="col-3 p-0 mt-2">
            <span class="quiz-details">Due Date:</span>
        </div>
        <div class="col-3 p-0 row m-0 mt-2">
            <div class="quiz-details">{{quiz.endDate}}</div>
            <div class="quiz-details">&nbsp; {{timeStr12hrs(quiz.endTime)}}</div>
        </div>
        <div class="offset-1 col-2 p-0 mt-2">
            <span class="quiz-details">No. of Questions:</span>
        </div>
        <div class="col-3 p-0 mt-2">
            <span class="quiz-details">{{quiz.noQuestions}}</span>
        </div>
        <div class="col-3 p-0 mt-2">
            <span class="quiz-details">Time Period:</span>
        </div>
        <div class="col-3 p-0 mt-2">
            <span class="quiz-details" [hidden]="quiz.durationHours===0">{{quiz.durationHours}} Hours </span>
            <span class="quiz-details" [hidden]="quiz.durationMinutes===0">{{quiz.durationMinutes}} Minutes </span>
        </div>
        <ng-template [ngIf]="quizLock" [ngIfElse]=lockQuiz>
            <span class="offset-1 btn btn-purple text-white mt-4" style="pointer-events: none;opacity: 0.4;"
                *ngIf="val==1">Already Submitted</span>
            <span class="offset-1 btn btn-purple text-white mt-4" (click)="navgateToQuizSheet()"
                *ngIf="val==2">Start</span>
        </ng-template>
        <ng-template #lockQuiz>
            <div class="offset-1 btn btn-purple-disabled text-white mt-4"
                (click)="getMessage(quiz.classId,quiz.startDate)">
                <div class="row m-0" style="align-items: center;justify-items: center;">
                    <span class="material-icons text-white">lock</span>
                    <span class="ml-3">Unlock this Quiz</span>
                </div>
            </div>
        </ng-template>
    </div>
</div>