import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatabaseManager } from '../database/database-manager';
import { ClassPayment, PaymentManagerComponent } from '../payment-manager/payment-manager.component';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { SigninComponent } from '../signin/signin.component';

@Component({
  selector: 'app-class-dialog',
  templateUrl: './class-dialog.component.html',
  styleUrls: ['./class-dialog.component.scss']
})
export class ClassDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ClassDialogComponent>, private sharedService: DataService,
    public router: Router, private dataService: DatabaseManager, public dialog: MatDialog, private auth: AuthService) {
  }

  ngOnInit(): void {

  }

  payNow() {
    this.dialogRef.close();
    var myData: ClassPayment = {
      myClass: this.data.class,
      date: new Date()
    }
    if (this.auth.isLoggedIn) {
      this.dialog.open(PaymentManagerComponent, {
        data: myData,
      });
    } else {
      this.dialog.open(SigninComponent);
    }
  }

  getCurrencyStr(value: number): string {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'LKR',
    });
    return formatter.format(value);
  }

}
