import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DigiConfernce } from '../models/digi-conference';
import { PayDigiConferenceComponent } from '../pay-digi-conference/pay-digi-conference.component';

@Component({
  selector: 'app-digi-conference-details',
  templateUrl: './digi-conference-details.component.html',
  styleUrls: ['./digi-conference-details.component.scss'],
})
export class DigiConferenceDetailsComponent implements OnInit {
  conferenceDetails: DigiConfernce;

  constructor(
    @Inject(MAT_DIALOG_DATA) public conference,
    private dialog: MatDialog,
    private matref: MatDialogRef<DigiConferenceDetailsComponent>
  ) {}

  ngOnInit(): void {
    this.conferenceDetails = this.conference;
  }

  enrollNow() {
    this.dialog.open(PayDigiConferenceComponent, {
      data: this.conferenceDetails,
    });
    this.matref.close();
  }
}
