<div class="py-2">
  <div class="row m-0 p-0">
    <div
      class="col-lg-10 col-12 m-0 p-0 border-bottom"
      style="align-items: center"
    >
      <div class="row m-0 p-0 pointer mt-1" (click)="hideFilter()">
        <i class="material-icons" style="font-size: 1.5em">filter_alt</i>
        <span style="font-size: 1.2em">FILTER</span>
      </div>
      <div
        class="row m-0 p-0 my-3"
        [class.hiddenFilter]="isFilterHidden == true"
      >
        <div class="mr-5 filterDivs">
          <h4 class="mx-2 py-2 border-bottom text-secondary"><b>Field</b></h4>
          <div>
            <span class="px-2 py-1" (click)="setSearchType('subjectName')"
              >Subject Name</span
            >
            <span class="px-2 py-1" (click)="setSearchType('teacherName')"
              >Teacher Name</span
            >
          </div>
        </div>
      </div>
    </div>
    <h1 class="col-12 p-0 mt-2">Mock Exam</h1>
  </div>
  <div class="row m-0 p-0" *ngIf="studentClasses.length !== 0">
    <div
      class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4"
      *ngFor="let class of studentClasses"
    >
      <div class="pointer" (click)="navigateToMockExam(class)">
        <img
          src="{{ class.subjectPic }}"
          alt="{{ class.subjectName }}"
          class="col-12 m-0 p-0 mb-1 item-image-size"
        />
        <div class="row m-0 p-0" style="align-items: center">
          <span class="item-title-fs"
            ><b
              >{{ class.day }} {{ getTime(class.startingTime) }} -
              {{ getTime(class.endingTime) }}</b
            ></span
          >
          <span
            class="py-0 px-3 px-md-2 px-lg-3 rounded-pill text-white"
            style="font-size: 0.7em; margin-left: auto; margin-right: 0%"
            [style.background-color]="
              class.classType === 'paper'
                ? '#EE5A24'
                : class.classType === 'group'
                ? '#B53471'
                : '#1B1464'
            "
          >
            {{ class.classType }}
          </span>
        </div>
        <div class="row m-0 p-0" style="align-items: center">
          <img
            src="{{ getImgUrl(class.teacherPic) }}"
            class="rounded-circle"
            alt="{{ class.teacherPic }}"
            width="30"
            height="30"
          />
          <span class="ml-2" style="font-size: 0.8em">{{
            class.teacherName
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="center" *ngIf="loadingList && studentClasses.length !== 0">
    <ng-lottie
      style="
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
        padding-bottom: 50px;
      "
      [options]="loadingDot"
      width="80px"
      height="auto"
    >
    </ng-lottie>
  </div>
  <div class="row m-0 p-0 mt-5 text-center" *ngIf="studentClasses.length === 0">
    <ng-lottie
      class="lottie-container"
      style="margin-left: auto; margin-right: auto"
      [options]="option"
      width="100%"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-4">
      <h2>No Classes Available...</h2>
    </div>
  </div>
</div>
