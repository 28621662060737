import { DatePipe } from "@angular/common";

export class DateTime {

    private datepipe: DatePipe
    constructor() {
        this.datepipe = new DatePipe("en-US");
    }

    /**
     * 
     * @param time24Hrs 
     * @returns 
     */
    public get12HrsTime(time24Hrs: string): string {
        let hrs: number = Number.parseInt(time24Hrs.split(":")[0]);
        var midday = "AM";
        if (hrs > 12) {
            hrs = hrs - 12;
            midday = "PM";
        }
        if (hrs === 0)
            hrs = 12;
        return hrs.toString() + ":" + time24Hrs.split(":")[1] + " " + midday;
    }

    /**
     * 
     * @param scheduledData 
     * @returns 
     */
    public getScheduledString(scheduledData: string[]): string {
        let hrs: number = Number.parseInt(scheduledData[2].split(":")[0]);
        var midday = "AM";
        if (hrs > 12) {
            hrs = hrs - 12;
            midday = "PM";
        }

        var endTimeString = hrs.toString() + ":" + scheduledData[2].split(":")[1] + " " + midday;
        const scheduledDate = new Date(scheduledData[0]);
        return this.datepipe.transform(scheduledDate, "yyyy-MM-dd h:mm a") + " - " + endTimeString;
    }

    /**
     * 
    * @param time1 in 24hrs format
     * @param time2 in 24hrs format
     * @returns 1 if time1 > time2, -1 if time1 < time2
     */
    public compareTime(time1: string, time2: string): number {
        let hrs1: number = Number.parseInt(time1.split(":")[0]);
        let min1: number = Number.parseInt(time1.split(":")[1]);
        let hrs2: number = Number.parseInt(time2.split(":")[0]);
        let min2: number = Number.parseInt(time2.split(":")[1]);
        var gap = (hrs1 * 60 + min1) - (hrs2 * 60 + min2);
        if (gap > 0)
            return 1;
        else
            return -1;
    }

    /**
     * 
     * @param time 
     * @returns 
     */
    public isTimePassed(time: string) {
        let hrs: number = Number.parseInt(time.split(":")[0]);
        let min: number = Number.parseInt(time.split(":")[1]);
        var now = new Date();
        var curTime = (now.getHours() * 60 + now.getMinutes()) * 60 * 1000;
        var gap = curTime - ((hrs * 60 + min) * 60 * 1000);
        if (gap > 0)
            return -1;
        else
            return 1;
    }
}