import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { DatabaseManager } from '../database/database-manager';
import { Video } from '../models/video';
import { Bookmark } from '../models/bookmark';
import { Discussion } from '../models/discussion';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ProfileValidatorService } from '../services/profile-validator.service';

// TimeAgo
import TimeAgo from 'javascript-time-ago';
import { AlertService } from '../services/alert.service';
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import {
  ClassPayment,
  PaymentManagerComponent,
} from '../payment-manager/payment-manager.component';
import { Class } from '../models/class';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { PaymentValidator } from '../payment-manager/payment-validator';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit {
  paragraph =
    ' This is long text replaced with reading more/less link example in Angular application<br> \n \n paragraph text shown here continue THis is long text replaced with read more/less link example in Angular application paragraph text shown here continue continue This is long text replaced with reading more/less link example in Angular application paragraph text shown here continue THis is long text replaced with read more/less link example in Angular application paragraph text shown here continue continue THis is long text replaced with reading more/less link example in Angular application paragraph text shown here continue THis is long text replaced with read more/less link example in Angular application paragraph text shown herecontinue continue';

  //settings
  private readonly LIKE_REP_LIMIT = 2;
  private readonly VIEW_VIEW_TIMOUT = 10000;
  private readonly UP_NEXT_COUNT = 10;
  private readonly EDIT_AVAILABLE_TIME = 7200; // IN SECONDS
  private readonly DELETE_AVAILABLE_TIME = 7200; // IN SECONDS
  private readonly VIDEO_REQUEST_URL =
    'https://us-central1-digipanthiya-bc66f.cloudfunctions.net/getSignedVideo';
  // private readonly TEST_VIDEO_REQUEST_URL = "http://localhost:1338/getSignedVideo";
  // private readonly VIDEO_REQUEST_URL = this.TEST_VIDEO_REQUEST_URL;

  private timeAgo = new TimeAgo('en-US');
  searchContainer = document.getElementById('searchContainer');
  private likeRepetition: number = 0;
  private dislikeRepetition: number = 0;
  inVideo: boolean = false;
  static inVideo: boolean = false;
  public myId: string = '';
  public currentVideo: Video;
  public loadingDiscussion: boolean = true;
  public discussions: Array<Discussion> = new Array<Discussion>();
  public replies = new Map<string, Array<Discussion>>();
  public myBookmarks: Array<Bookmark> = new Array<Bookmark>();
  public myCurTimeline: String = '00:00';
  private readonly NO_DIP: string = 'assets/templates/noprofile.png';
  public readonly NO_THUMBNAIL = './../assets/templates/video_thumb.jpg';
  public teacherPic: string = this.NO_DIP;
  uploadedDate: string = '';
  videoSrc: SafeUrl = '';

  buffer: boolean = true;
  // up next section
  public upNext: Array<Video> = new Array<Video>();
  public upNextLock: Array<boolean> = new Array<boolean>();
  public upNextPlaying: Array<boolean> = new Array<boolean>();
  public autoPlay: boolean = false;
  public isReadMore = true;
  private videoListCount: number = -1;
  // other
  private readonly VIDEO_TYPE_FEATURE = 2;
  private readonly VIDEO_TYPE_CLASS = 1;
  public isFeatureWindow: boolean = false;

  isVideoLiked = false;
  isVideoUnliked = false;

  option: AnimationOptions = {
    path: '/assets/lti/lti_video.json',
    loop: false,
  };

  buffering: AnimationOptions = {
    path: 'assets/lti/lf30_editor_hq2ulvlv.json',
    loop: true,
  };

  constructor(
    private http: HttpClient,
    private sharedService: DataService,
    private auth: AuthService,
    private sanitizer: DomSanitizer,
    private imageCompress: NgxImageCompressService,
    private db: DatabaseManager,
    private datepipe: DatePipe,
    private route: ActivatedRoute,
    private validator: ProfileValidatorService,
    private alertService: AlertService,
    public paymentValidator: PaymentValidator,
    private dialog: MatDialog
  ) {
    this.searchContainer.hidden = true;
  }

  ngOnInit(): void {
    this.inVideo = true;
    this.loadVideo().then((result) => {
      if (result === this.VIDEO_TYPE_CLASS) {
        this.loadDiscussion();
        this.isFeatureWindow = false;
      } else {
        this.isFeatureWindow = true;
      }
      this.setViewTimer();
      this.loadBookmarks();
      this.sharedService
        .getTeacherInfo(this.currentVideo.teacherId)
        .then((result) => {
          if (result !== undefined) {
            this.teacherPic = result.profilePic;
            if (this.isFeatureWindow)
              this.currentVideo.teacherName = result.teacherName;
          } else this.teacherPic = this.NO_DIP;
        });
      this.loadSuggestions();
    });
  }

  loadVideo() {
    return new Promise<number>((resolve) => {
      if (this.sharedService.sharedVideo === undefined) {
        //load uisng parms
        var subjectId = this.route.snapshot.paramMap.get('subjectId');
        var classId = this.route.snapshot.paramMap.get('classId');
        var teacherId = this.route.snapshot.paramMap.get('teacherId');
        var videoId = this.route.snapshot.paramMap.get('videoId');
        if (subjectId !== null && classId !== null) {
          this.db.getVideo(teacherId, classId, videoId).then((video) => {
            this.currentVideo = video;
            this.setupElements();
            resolve(this.VIDEO_TYPE_CLASS);
          });
        } else {
          this.db.getFeaturedVideo(teacherId, videoId).then((video) => {
            this.currentVideo = video;
            this.setupElements();
            resolve(this.VIDEO_TYPE_FEATURE);
          });
        }
      } else {
        this.currentVideo = this.sharedService.sharedVideo;
        this.setupElements();
        resolve(this.VIDEO_TYPE_CLASS);
      }
    });
  }

  private setupElements() {
    //set date
    const scheduledDate = new Date(this.currentVideo.uploadedDate);
    this.uploadedDate = this.datepipe.transform(scheduledDate, 'MMM d, y');
    //load like and dislike status
    this.db
      .isVideoLiked(this.auth.userData.uid, this.currentVideo.videoId)
      .then((res) => {
        this.isVideoLiked = res;
      });
    this.db
      .isVideoDisliked(this.auth.userData.uid, this.currentVideo.videoId)
      .then((res) => {
        this.isVideoUnliked = res;
      });
    this.setUrl();
    this.myId = this.auth.userData.uid;
  }

  private setViewTimer() {
    setTimeout(() => {
      var viewCnt = this.currentVideo.views + 1;
      if (this.isFeatureWindow) {
        this.db.addFeatureView(
          this.currentVideo.teacherId,
          this.currentVideo.videoId,
          viewCnt
        );
      } else {
        this.db.addVideoView(
          this.currentVideo.teacherId,
          this.currentVideo.classId,
          this.currentVideo.videoId,
          viewCnt
        );
      }
    }, this.VIEW_VIEW_TIMOUT);
  }

  likeVideo(): void {
    if (this.likeRepetition < this.LIKE_REP_LIMIT) {
      if (this.isVideoLiked) {
        this.isVideoLiked = false;
        this.currentVideo.likes--;
        this.db.removeVideoLike(this.currentVideo);
        this.db.removeFromLikedVideos(
          this.auth.userData.uid,
          this.currentVideo.videoId
        );
      } else {
        this.db.addVideoLike(this.currentVideo);
        this.db.addToLikedVideos(
          this.auth.userData.uid,
          this.currentVideo.videoId
        );
        this.isVideoLiked = true;
        this.likeRepetition = this.likeRepetition + 1;
        this.currentVideo.likes++;
        if (this.isVideoUnliked) {
          this.isVideoUnliked = false;
          this.currentVideo.dislikes--;
          this.db.removeVideoUnlike(this.currentVideo);
          this.db.removeFromDislikedVideos(
            this.auth.userData.uid,
            this.currentVideo.videoId
          );
        }
      }
    }
  }

  unlikeVideo(): void {
    if (this.dislikeRepetition < this.LIKE_REP_LIMIT) {
      if (this.isVideoUnliked) {
        this.isVideoUnliked = false;
        this.currentVideo.dislikes--;
        this.db.removeVideoUnlike(this.currentVideo);
        this.db.removeFromDislikedVideos(
          this.auth.userData.uid,
          this.currentVideo.videoId
        );
      } else {
        this.db.addVideoUnlike(this.currentVideo);
        this.db.addToDislikedVideos(
          this.auth.userData.uid,
          this.currentVideo.videoId
        );
        this.isVideoUnliked = true;
        this.dislikeRepetition = this.dislikeRepetition + 1;
        this.currentVideo.dislikes++;
        if (this.isVideoLiked) {
          this.isVideoLiked = false;
          this.currentVideo.likes--;
          this.db.removeVideoLike(this.currentVideo);
          this.db.removeFromLikedVideos(
            this.auth.userData.uid,
            this.currentVideo.videoId
          );
        }
      }
    }
  }

  async getFileFromUrl(url, name, defaultType = 'video/mp4') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: response.headers.get('content-type') || defaultType,
    });
  }

  /**
   * Get url from the source and convert it into blob url and sanitizze the url
   */
  private async setUrl() {
    this.buffer = true;
    let getheaders = new HttpHeaders().set('Accept', 'application/json');
    let params = new HttpParams().set('videoUrl', this.currentVideo.videoUrl);
    this.http
      .get<any>(this.VIDEO_REQUEST_URL, {
        params: params,
        headers: getheaders,
      })
      .subscribe((data) => {
        this.videoSrc = data.signedUrl;
        this.buffer = false;
        // fetch(this.currentVideo.videoUrl)
        //  fetch("https://dokc7soks2rzm.cloudfront.net/123.mp4?Expires=1644299140&Key-Pair-Id=APKAID76IDVMY32Y2F3Q&Signature=DNkXMI~zkbwtAfMW0udHwVks63p814gOHIoPvpKyGOc4pcd9690okws97TrpXJsARffC7av0Jk9GzuKrd22JZQoVIWgE5xvB4IJ1JtnExwHyPYZR7aszZb5YV4AoI54vCGEbd8PD60n~7W6A-URPR3gW5fXm3NXKIEZ~uDpJOpCiZjTDPqOEaJq~PrwQvqqQULTz0aXLHTAjmflX2ulLGsP-FnaPW8pxoNd0fBhFmzz112vMetNjUJNjCpTCf7vSX-b6E5i6gQbhlVgMJJviS1DfKAjp-JbSsOF1St8Ab4izX~xxV7aNyhR0hjnTr2GCwZzmcp9IdivuZh9NkXbaSw__")
        // fetch(data.signedUrl, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //   },
        // })
        //   .then((res) => res.blob()) // Gets the response and returns it as a blob
        //   .then((blob) => {
        //     //now create blob url
        //     let objectURL = URL.createObjectURL(blob);
        //     //now sanitize blob url
        //     let path = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        //     this.videoSrc = path;
        //     this.buffer = false;
        //   });
      });
  }

  private loadDiscussion() {
    this.db
      .getDiscussion(
        this.currentVideo.teacherId,
        this.currentVideo.classId,
        this.currentVideo.videoId
      )
      .then((results) => {
        results.forEach((element: Discussion) => {
          element.isDeletable = this.isDeleteAvilable(element.timestamp);
          element.isEditable = this.isEditAvilable(element.timestamp);
          element.ago = this.timeAgo
            .format(new Date(element.timestamp))
            .toString();
          this.discussions.push(element);
        });
        this.loadingDiscussion = false;
        this.discussions.sort((a, b) => b.timestamp - a.timestamp);
      });
  }

  loadSubDiscussion(messageId: string) {
    if (this.replies.get(messageId) === undefined)
      this.db
        .getSubDiscussion(
          this.currentVideo.teacherId,
          this.currentVideo.classId,
          this.currentVideo.videoId,
          messageId
        )
        .then((results) => {
          let disc: Array<Discussion> = new Array<Discussion>();
          results.forEach((element: Discussion) => {
            element.ago = this.timeAgo
              .format(new Date(element.timestamp))
              .toString();
            element.isDeletable = this.isDeleteAvilable(element.timestamp);
            element.isEditable = this.isEditAvilable(element.timestamp);
            disc.push(element);
          });
          disc.sort((a, b) => b.timestamp - a.timestamp);
          this.replies.set(messageId, disc);
        });
  }

  public addDiscussion(message: string): void {
    this.imageCompress
      .compressFile(this.validator.getUser().profilePic, -1, 20, 20)
      .then((result) => {
        let comment: Discussion = {
          messageId: '',
          isStudent: true,
          userId: this.auth.userData.uid,
          userName:
            this.validator.getUser().firstName +
            ' ' +
            this.validator.getUser().lastName,
          userPic: result,
          timestamp: new Date().getTime(),
          ago: '',
          message: message,
          replyAvailable: false,
          isDeletable: false,
          isEditable: false,
        };
        this.db
          .addDiscussion(
            this.currentVideo.teacherId,
            this.currentVideo.classId,
            this.currentVideo.videoId,
            comment
          )
          .then((result) => {
            comment.ago = this.timeAgo
              .format(new Date(comment.timestamp))
              .toString();
            comment.isDeletable = this.isDeleteAvilable(comment.timestamp);
            comment.isEditable = this.isEditAvilable(comment.timestamp);
            if (result) {
              this.discussions.push(comment);
              this.discussions.sort((a, b) => b.timestamp - a.timestamp);
            }
          });
      });
  }

  public addSubDiscussion(message: string, mainCmt: Discussion): void {
    this.imageCompress
      .compressFile(this.validator.getUser().profilePic, -1, 20, 20)
      .then((result) => {
        let comment: Discussion = {
          messageId: '',
          isStudent: true,
          userId: this.auth.userData.uid,
          userName:
            this.validator.getUser().firstName +
            ' ' +
            this.validator.getUser().lastName,
          userPic: result,
          timestamp: new Date().getTime(),
          ago: '',
          message: message,
          replyAvailable: false,
          isDeletable: false,
          isEditable: false,
        };
        this.db
          .addSubDiscussion(
            this.currentVideo.teacherId,
            this.currentVideo.classId,
            this.currentVideo.videoId,
            mainCmt.messageId,
            comment
          )
          .then((result) => {
            if (result) {
              comment.ago = this.timeAgo
                .format(new Date(comment.timestamp))
                .toString();
              comment.isDeletable = this.isDeleteAvilable(comment.timestamp);
              comment.isEditable = this.isEditAvilable(comment.timestamp);
              let disc: Array<Discussion> = this.replies.get(mainCmt.messageId);
              if (disc === undefined) disc = new Array<Discussion>();
              disc.push(comment);
              disc.sort((a, b) => b.timestamp - a.timestamp);
              this.replies.set(mainCmt.messageId, disc);
              mainCmt.replyAvailable = true;
            }
          });
      });
  }

  public showSubDiscussion(id: string): void {
    var x = document.getElementById(id);
    x.style.display = 'block';
  }

  public hideSubDiscussion(id: string): void {
    var x = document.getElementById(id);
    x.style.display = 'none';
  }

  public addThisBookmark(bookmarkTest: string, video: any) {
    var bookmark: Bookmark = {
      bookmarkId: '',
      time: video.currentTime,
      timeString: this.hms(video.currentTime),
      description: bookmarkTest,
    };
    this.db
      .addBookmark(this.auth.userData.uid, this.currentVideo.videoId, bookmark)
      .then((result) => {
        if (result) {
          this.hideBkInput();
          this.myBookmarks.push(bookmark);
          this.myBookmarks.sort((a, b) => a.time - b.time);
        }
      });
  }

  public loadBookmarks() {
    this.db
      .getBookmarks(this.auth.userData.uid, this.currentVideo.videoId)
      .then((result) => {
        this.myBookmarks = result;
        this.myBookmarks.sort((a, b) => a.time - b.time);
      });
  }

  public loadBkInput(video: any) {
    video.pause();
    this.myCurTimeline = this.hms(video.currentTime);
    var x = document.getElementById('bkinput');
    x.style.display = '';
  }

  public hideBkInput() {
    var x = document.getElementById('bkinput');
    x.style.display = 'none';
  }

  private hms(seconds): string {
    var date = new Date(1970, 0, 1);
    date.setSeconds(seconds);
    return date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
  }

  public seekToPosition(seconds: number, video: any) {
    video.currentTime = seconds;
  }

  public removeBookmark(bookmarkId: string) {
    this.db
      .removeBookmark(
        this.auth.userData.uid,
        this.currentVideo.videoId,
        bookmarkId
      )
      .then((res) => {
        if (res) {
          this.myBookmarks.splice(
            this.myBookmarks.findIndex((x) => x.bookmarkId == bookmarkId),
            1
          );
        }
      });
  }

  private isEditAvilable(timestamp: number): boolean {
    var nowTime = new Date().getTime();
    var diff = Math.round((nowTime - timestamp) / 1000);
    return diff <= this.EDIT_AVAILABLE_TIME;
  }

  private isDeleteAvilable(timestamp: number): boolean {
    var nowTime = new Date().getTime();
    var diff = Math.round((nowTime - timestamp) / 1000);
    return diff <= this.DELETE_AVAILABLE_TIME;
  }

  public removeDiscussion(messageId: string) {
    this.db
      .removeDiscussion(
        this.currentVideo.teacherId,
        this.currentVideo.classId,
        this.currentVideo.videoId,
        messageId
      )
      .then((out) => {
        if (out) {
          this.discussions.splice(
            this.discussions.findIndex((x) => x.messageId == messageId),
            1
          );
        }
      });
  }

  public editDiscussion(discussion: Discussion, input: string) {
    var editId = input;
    let newMsg = (document.getElementById(input + 'TEXT') as HTMLInputElement)
      .value;
    this.db
      .editDiscussion(
        this.currentVideo.teacherId,
        this.currentVideo.classId,
        this.currentVideo.videoId,
        discussion.messageId,
        newMsg
      )
      .then((out) => {
        if (out) {
          discussion.message = newMsg;
          this.hideEditDis(editId);
        }
      });
  }

  public removeSubDiscussion(comment: Discussion, messageId: string) {
    this.db
      .removeSubDiscussion(
        this.currentVideo.teacherId,
        this.currentVideo.classId,
        this.currentVideo.videoId,
        comment.messageId,
        messageId
      )
      .then((out) => {
        if (out) {
          this.replies.get(comment.messageId).splice(
            this.replies
              .get(comment.messageId)
              .findIndex((x) => x.messageId == messageId),
            1
          );
          if (this.replies.get(comment.messageId).length === 0) {
            this.db.noDicussionReply(
              this.currentVideo.teacherId,
              this.currentVideo.classId,
              this.currentVideo.videoId,
              comment.messageId
            );
            comment.replyAvailable = false;
          }
        }
      });
  }

  public showEditDis(id: string): void {
    var x = document.getElementById(id + 'edt');
    var y = document.getElementById(id + 'input');
    x.style.display = 'block';
    y.style.display = 'none';
  }

  public hideEditDis(id: string): void {
    var x = document.getElementById(id + 'edt');
    var y = document.getElementById(id + 'input');
    y.style.display = 'block';
    x.style.display = 'none';
  }

  getMessage(classId: string, date: string, time: string) {
    this.alertService
      .showAlert(
        'You should resolve your payment to unlock this metirial',
        MessageButtons.ok,
        MessageType.accessBlocked
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        if (result === DialogResult.ok) {
          this.payNow(classId, date + ' ' + time);
        }
      });
  }

  payNow(classId: string, uploadedDate: string) {
    this.db
      .getSingleClass(this.auth.userData.uid, classId)
      .then((thisClass: Class) => {
        var myData: ClassPayment = {
          myClass: thisClass,
          date: new Date(uploadedDate),
        };
        this.dialog.open(PaymentManagerComponent, {
          data: myData,
        });
      });
  }

  playThis(video: Video, queueIndex: number) {
    // next video logic
    this.videoListCount = queueIndex;
    this.clearData();
    this.currentVideo = video;
    this.upNextPlaying.forEach((element, index) => {
      if (index === queueIndex) this.upNextPlaying[index] = true;
      else this.upNextPlaying[index] = false;
    });
    this.setupElements();
    if (!this.isFeatureWindow) {
      this.loadDiscussion();
    }
    this.setViewTimer();
    this.loadBookmarks();
    this.sharedService
      .getTeacherInfo(this.currentVideo.teacherId)
      .then((result) => {
        if (result !== undefined) {
          this.teacherPic = result.profilePic;
          if (this.isFeatureWindow)
            this.currentVideo.teacherName = result.teacherName;
        } else this.teacherPic = this.NO_DIP;
      });
  }

  private clearData() {
    this.myBookmarks = new Array<Bookmark>();
    this.discussions = new Array<Discussion>();
    this.replies = new Map<string, Array<Discussion>>();
  }

  showMoreText() {
    this.isReadMore = !this.isReadMore;
  }

  loadSuggestions() {
    if (!this.isFeatureWindow) {
      this.db.getSuggestVideos(this.currentVideo).then((result: Video[]) => {
        this.upNextLock = new Array<boolean>(result.length).fill(false);
        this.upNextPlaying = new Array<boolean>(result.length).fill(false);
        this.upNext = result;
        this.upNext.forEach((video, index) => {
          var dt = new Date(video.uploadedDate);
          this.paymentValidator
            .validateAccess(this.auth.userData.uid, video.classId, dt)
            .then((result) => {
              this.upNextLock[index] = result;
            });
        });
        setTimeout(() => {
          document.getElementById('masterVideo').addEventListener(
            'ended',
            () => {
              this.playNext();
            },
            false
          );
        }, 2000);
      });
    } else {
      this.db
        .getFeaturedVideos(this.currentVideo.teacherId)
        .then((result: Video[]) => {
          this.upNextLock = new Array<boolean>(result.length).fill(true);
          this.upNextPlaying = new Array<boolean>(result.length).fill(false);
          this.upNext = result;
          setTimeout(() => {
            document.getElementById('masterVideo').addEventListener(
              'ended',
              () => {
                this.playNext();
              },
              false
            );
          }, 2000);
        });
    }
  }

  public playNext() {
    if (this.autoPlay) {
      while (this.upNext.length !== this.videoListCount) {
        this.videoListCount = this.videoListCount + 1;
        if (this.upNextLock[this.videoListCount]) {
          this.playThis(this.upNext[this.videoListCount], this.videoListCount);
          return;
        }
      }
    }
  }
}
