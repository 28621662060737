import { Component, OnInit, ViewChild } from '@angular/core';
import { Quiz } from '../models/quiz';
import { Marks } from "../models/marks";
import { DatabaseManager } from '../database/database-manager';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DatePipe } from '@angular/common';
import { CountdownComponent } from 'ngx-countdown';
import { Student } from '../models/student';
import { AlertService } from '../services/alert.service';
import { DialogResult, MessageButtons, MessageType } from '../messagebox/messagebox.component';

@Component({
  selector: 'app-quizanswersheet',
  templateUrl: './quizanswersheet.component.html',
  styleUrls: ['./quizanswersheet.component.scss'],
})

export class QuizanswersheetComponent implements OnInit {

  searchContainer = document.getElementById("searchContainer");

  @ViewChild('countdown') counter: CountdownComponent;
  quiz: Quiz;
  student: Student;
  selectedChoice: Array<number>;
  answers: Array<number>;
  timeLeft: number;
  quizTimer: any;
  teacherId: string
  classId: string
  quizId: string;
  isQuizNotFinished: boolean = true;

  constructor(private dataservice: DatabaseManager, public router: Router, private auth: AuthService, private datepipe: DatePipe, private route: ActivatedRoute, private alertServtice: AlertService) {
    this.searchContainer.hidden = true;
    auth.isUserAvailable().then(res => {
      if (res) {
        this.loadQuiz();
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.isQuizNotFinished) {
      this.alertServtice
        .showAlert("Your quiz will mark as finished!", MessageButtons.YesNo, MessageType.warning)
        .afterClosed().subscribe((result: DialogResult) => {
          if (result === DialogResult.close) {
            this.router.navigate(['/quizanswersheet', this.teacherId, this.classId, this.quizId]);
          } else if (result === DialogResult.yes) {
            this.finishQuiz();
            if (this.quizTimer) {
              clearInterval(this.quizTimer);
            }
          }
        });
    } else {
      this.alertServtice
        .showAlert("Your quiz will mark as finished!", MessageButtons.ok, MessageType.warning);
    }
  }

  loadQuiz() {
    this.teacherId = this.route.snapshot.paramMap.get("teacherId");
    this.classId = this.route.snapshot.paramMap.get("classId");
    this.quizId = this.route.snapshot.paramMap.get("quizId");
    this.dataservice.getQuiz(this.teacherId, this.classId, this.quizId).then((element: Quiz) => {
      this.quiz = element;
      this.dataservice.getSavedMCQ(this.student.studentId, this.quizId).then(element => {
        if (element != null) {
          this.selectedChoice = element.savedAnswers;
          this.timeLeft = element.duration;
        } else {
          this.selectedChoice = Array(this.quiz.noQuestions).fill("0");
          this.timeLeft = this.setTimeInMinutes(this.quiz.durationHours, this.quiz.durationMinutes) * 60;
          this.dataservice.createSavedMCQ(this.auth.userData.uid, this.quizId, { savedAnswers: this.selectedChoice, duration: this.timeLeft });
        }
      });
      this.answers = this.quiz.answers;
    });
    this.dataservice.getStudent(this.auth.userData.uid).then((element: Student) => {
      this.student = element;
    });
  }

  questions(quiz: number): Array<number> {
    return Array(quiz);
  }

  toModulus(quiz: number): any {
    var rows = Math.trunc(quiz / 15);
    var remain = 0;

    if (quiz % 15 !== 0) {
      remain = quiz % 15;
    }
    return { rows: rows, remain: remain };
  }

  setTimeInMinutes(durationHours: number, durationMinutes: number): number {
    var timeInMinutes: number = 0;
    if (durationHours !== 0) {
      timeInMinutes = timeInMinutes + durationHours * 60;
    }
    if (durationMinutes !== 0) {
      timeInMinutes = timeInMinutes + durationMinutes;
    }
    return timeInMinutes;
  }

  finishQuiz() {
    if (this.quizTimer) {
      clearInterval(this.quizTimer);
    }
    var marking: number = 0;
    for (let i = 0; i < this.selectedChoice.length; i++) {
      const choice = this.selectedChoice[i];
      for (let j = 0; j < this.quiz.noQuestions; j++) {
        const answer: any = this.answers[j];
        if (i === j) {
          if (choice == answer.answer) {
            marking = marking + 1;
          }
        }
      }
    }
    var markSheet: Marks = {
      markId: "",
      marks: marking,
      answerSheetUrl: "",
      duration: this.getDuration(),
      isEvaluated: false,
      markedDate: "",
      markedTime: "",
      studentId: this.student.studentId,
      studentName: this.student.firstName + " " + this.student.lastName,
      studentPic: this.student.profilePic,
      submittedDate: this.datepipe.transform(new Date(), "yyyy-MM-dd"),
      submittedTime: this.datepipe.transform(new Date(), "HH:mm")
    };
    this.addMarks(markSheet);
    this.dataservice.deleteSavedMCQ(this.auth.userData.uid, this.quizId);
    this.router.navigate(['exam']);
  }

  addMarks(markSheet: Marks) {
    this.dataservice.createMarksKey(this.quiz.teacherId, this.quiz.classId, this.quiz.quizId, markSheet);
  }

  checkTimer() {
    var left = this.counter.left / 1000;
    this.dataservice.updateSavedMCQ(this.student.studentId, this.quizId, { duration: left });
  }

  handleEvent(event) {
    this.quizTimer = setInterval(() => { this.checkTimer() }, 1000);
    if (event.action === "done") {
      this.isQuizNotFinished = false;
      this.finishQuiz();
    }
  }

  onAnswerChange() {
    setTimeout(() => {
      this.dataservice.updateSavedMCQ(this.student.studentId, this.quizId, { savedAnswers: this.selectedChoice });
    }, 50);
  }

  getDuration(): string {
    var date = new Date(1577817000000 + this.counter.left);
    return (date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds());
  }

}
