<!-- <div class="text-center" style="display: grid;align-items: center;">
    <img src="{{profileValidator.getUserProfilePic()}}" alt="" width="350px" height="350px" class="rounded">
    <h2 class="mt-4"><b>Change Your Profile Picture</b></h2>
    <input type="file" accept="image/png, image/jpeg,image/jpg" hidden id="selectImg"
        (change)="fileChangeEvent($event)" />
    <label class="border text-primary py-3 m-0 mt-2" for="selectImg" (change)="fileChangeEvent($event)"
        style="font-size: 18sp;">Select a New Profile Picture</label>
    <span class="border-bottom text-danger py-3" style="font-size: 18sp;" (click)="removeImage()">Remove Picture</span>
    <span class="mb-2 py-3" style="font-size: 18sp;" mat-dialog-close>Close</span>
</div> -->
<div class="bg-white rounded-lg upload-container-pp" *ngIf="!this.imageChangedEvent">
    <div class="text-center" style="display: grid;align-items: center;">
        <img src="{{profileValidator.getUserProfilePic()}}" alt="profile picture" width="100%" height="100%"
            class="rounded profile-picture">
        <h4 class="mt-4 pic-heading"><b>Change Your Profile Picture</b></h4>
        <input type="file" accept="image/png, image/jpeg,image/jpg" hidden id="selectImg"
            (change)="fileChangeEvent($event)" />
        <label class="border text-purple py-3 m-0 mt-2 pic-text pointer" for="selectImg"
            (change)="fileChangeEvent($event)">Select a New Profile Picture</label>
        <span class="border-bottom text-danger py-3 pic-text pointer" (click)="removeImage()">Remove Picture</span>
        <span class="mb-2 py-3 pic-text pointer" mat-dialog-close>Close</span>
    </div>
</div>
<!-- <div *ngIf="this.imageChangedEvent && this.isImageValid" class="text-center bg-white">
    <div style="overflow: auto;align-items: center;" class="row m-0 pt-3">
        <image-cropper *ngIf="!showCropped" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="1/1" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
            (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
        </image-cropper>
        <img *ngIf="showCropped" [src]="croppedImage" style="margin-left: auto;margin-right: auto;" />
    </div>
    <div class="container-fluid py-3">
        <span class="btn btn-outline-secondary" (click)="back()">Back</span>
        <span [disabled]="showCropped" class="ml-2 btn btn-primary" (click)="save()">
            preview
        </span>
        <span class="ml-2 btn btn-primary" (click)="upload()">upload</span>
    </div>
</div> -->
<div class="bg-white rounded-lg upload-container-pp" *ngIf="this.imageChangedEvent && this.isImageValid">
    <div class="mt-3 text-center">
        <div style="overflow: auto;align-items: center;" class="row m-0 pt-3">
            <image-cropper style="max-height: 50vh;" *ngIf="!showCropped" [imageChangedEvent]="imageChangedEvent"
                format="jpeg" [maintainAspectRatio]="true" [aspectRatio]="1/1" (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
            </image-cropper>
            <img *ngIf="showCropped" [src]="croppedImage" width="100%" height="100%"
                style="margin-left: auto;margin-right: auto;max-height: 50vh;" />
        </div>
        <div class="container-fluid py-3">
            <span class="btn btn-outline-secondary pic-text" (click)="back()">{{backText}}</span>
            <button [disabled]="showCropped" class="ml-2 btn btn-purple pic-text text-white" (click)="save()">
                Crop
            </button>
            <span class="ml-2 btn btn-purple pic-text text-white" (click)="upload()">Upload</span>
        </div>
    </div>
</div>