import { Component, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-er404',
  templateUrl: './er404.component.html',
  styleUrls: ['./er404.component.scss']
})
export class Er404Component implements OnInit {
  
  searchContainer = document.getElementById("searchContainer");

  option: AnimationOptions = {
    path: '/assets/lti/lti_404.json', loop: true
  };

  constructor() {
    this.searchContainer.hidden = true;
   }

  ngOnInit(): void {
  }

}
