<div class="offset-md-2 col-md-8 p-0"
    style="background-image: url('../../assets/images/FAQ.png');height: 92vh;background-repeat: no-repeat;background-size: cover;background-position: center;">
    <h1 class="text-center" style="padding-top: 8%;"><b>How can We help You?</b></h1>
    <div class="rounded bg-white" style="margin-top: 7%;">
        <div>
            <ng-template [ngIf]="activeLink == -1 || activeLink != 1">
                <div (click)="linkActive(1)" class="row m-0 p-0 col-12 px-4 py-3 border-bottom"
                    style="align-items: center;">
                    <div class="weblink-title col-7 col-sm-9 col-lg-10 p-0">
                        <p>What is digipanthiya.com ?</p>
                    </div>
                    <span class="material-icons text-secondary pointer weblink-arrow"
                        style="margin-left: auto;margin-right: 0%;">keyboard_arrow_down</span>
                </div>
            </ng-template>
            <ng-template [ngIf]="activeLink == 1">
                <div class="row m-0 p-0 col-12 px-4 py-3 border-bottom" style="align-items: center;">
                    <div class="container-fluid p-0">
                        <div class="row m-0 pb-2 border-bottom" style="align-items: center;" (click)="linkDeactive()">
                            <div class="weblink-title col-7 col-sm-9 col-lg-10 p-0">
                                <p>What is digipanthiya.com ?</p>
                            </div>
                            <span class="material-icons text-secondary pointer weblink-arrow"
                                style="margin-left: auto;margin-right: 0%;">keyboard_arrow_left</span>
                        </div>
                        <div class="mt-3 weblink-details" >
                            <p>Are you someone who hopes to learn something? or are you someone who hopes to teach
                                something you know? Then digipanthiya.com is the best platform for you. It is the number
                                one platform for anyone to teach and learn. We ‘re ready to provide that interactive
                                learning experience. </p>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <div>
            <ng-template [ngIf]="activeLink == -1 || activeLink != 2">
                <div (click)="linkActive(2)" class="row m-0 p-0 col-12 px-4 py-3 border-bottom"
                    style="align-items: center;">
                    <div class="weblink-title col-8 col-sm-9 col-lg-10 p-0">
                        <p>Is this only for School children?</p>
                    </div>
                    <span class="material-icons text-secondary pointer weblink-arrow"
                        style="margin-left: auto;margin-right: 0%;">keyboard_arrow_down</span>
                </div>
            </ng-template>
            <ng-template [ngIf]="activeLink == 2">
                <div class="row m-0 p-0 col-12 px-4 py-3 border-bottom" style="align-items: center;">
                    <div class="container-fluid p-0">
                        <div class="row m-0 pb-2 border-bottom" style="align-items: center;" (click)="linkDeactive()">
                            <div class="weblink-title col-8 col-sm-9 col-lg-10 p-0">
                                <p>Is this only for School children ?</p>
                            </div>
                            <span class="material-icons text-secondary pointer weblink-arrow"
                                style="margin-left: auto;margin-right: 0%;">keyboard_arrow_left</span>
                        </div>
                        <div class="mt-3 weblink-details">
                            <p>We’re not limiting our service to School children.
                                If you’re willing to teach or learn any other professional course, IT related course or
                                even physical training class,
                                you can use this platform.
                                Please give a call to 076 939 4630 and create your class now.</p>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>