import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from '../util/datetime';
import { DatabaseManager } from '../database/database-manager';
import { LiveClass } from '../models/liveClass';
import { DataService } from '../services/data.service';
import { AnimationOptions } from 'ngx-lottie';
import { AuthService } from '../services/auth.service';
import { Class } from '../models/class';
import { DialogResult, MessageButtons, MessageType } from '../messagebox/messagebox.component';
import { AlertService } from '../services/alert.service';
import { ClassPayment, PaymentManagerComponent } from '../payment-manager/payment-manager.component';
import { MatDialog } from '@angular/material/dialog';
import { PaymentValidator } from '../payment-manager/payment-validator';

@Component({
  selector: 'app-liveclass-profile',
  templateUrl: './liveclass-profile.component.html',
  styleUrls: ['./liveclass-profile.component.scss']
})
export class LiveclassProfileComponent implements OnInit {

  searchContainer = document.getElementById("searchContainer");
  liveClasses: LiveClass[] = [];
  liveLock: Array<boolean>;
  classId: string;
  teacherId: string;
  classForTitle: string;
  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json', loop: false
  };

  constructor(private dataservice: DatabaseManager, private sharedService: DataService, private datepipe: DatePipe, private router: Router, private route: ActivatedRoute, private authService: AuthService, private alertService: AlertService, private dialog: MatDialog, public validator: PaymentValidator) {
    this.searchContainer.hidden = true;
    this.authService.isUserAvailable().then(res => {
      this.loadLiveClasses();
      this.dataservice.getSingleClass(this.authService.userData.uid, this.classId).then((element: Class) => {
        this.classForTitle = element.subjectName;
      });
    });
  }

  ngOnInit(): void {
  }

  loadLiveClasses() {
    if (this.sharedService.sharedSub != undefined) {
      this.classId = JSON.parse(this.sharedService.sharedSub).classId;
      this.teacherId = JSON.parse(this.sharedService.sharedSub).teacherId;
    } else {
      this.teacherId = this.route.snapshot.paramMap.get("teacherId");
      this.classId = this.route.snapshot.paramMap.get("classId");
    }
    this.dataservice.getLiveClassSet(this.teacherId, this.classId).then((elements: LiveClass[]) => {
      this.liveClasses = elements;
      this.liveLock = new Array<boolean>(elements.length).fill(false);
      this.validateLiveClass();
    });
  }

  getScheduledDate(day: string, staringTime: string, endingTime: string) {
    var stratingTime = staringTime;
    var dayOfWeek: number = 0;
    var scheduledDate: Date;

    if (day === "Mon") {
      dayOfWeek = 1;
    } else if (day === "Tue") {
      dayOfWeek = 2;
    } else if (day === "Wed") {
      dayOfWeek = 3;
    } else if (day === "Thu") {
      dayOfWeek = 4;
    } else if (day === "Fri") {
      dayOfWeek = 5;
    } else if (day === "Sat") {
      dayOfWeek = 6;
    } else {
      dayOfWeek = 7;
    }

    if ((new Date().getDay() === 0 && dayOfWeek === 7) || new Date().getDay() === dayOfWeek) {
      scheduledDate = new Date(new Date().setHours(Number.parseInt(stratingTime.split(":")[0]), Number.parseInt(stratingTime.split(":")[1]), 0, 0));
    } else {
      var addAWeek = 0
      if (dayOfWeek < new Date().getDay()) {
        addAWeek = 7;
      }
      const r = Math.abs((addAWeek + dayOfWeek) - new Date().getDay());
      scheduledDate = new Date(new Date().setHours(Number.parseInt(stratingTime.split(":")[0]), Number.parseInt(stratingTime.split(":")[1]), 0, 0) + r * (1000 * 60 * 60 * 24));
    }

    //prepare array
    let data: string[] = [this.datepipe.transform(scheduledDate, "yyyy-MM-dd HH:mm"), staringTime, endingTime];
    return data;
  }

  timeTo12Hrs(scheduleData: string[]): string {
    //using custom datetime class
    //check
    return new DateTime().getScheduledString(scheduleData);
  }

  timeStr12hrs(time: string) {
    return new DateTime().get12HrsTime(time);
  }

  checkTimeStatus(scheduleData: string[]): any {
    const currentDate = new Date();
    const scheduledDate = new Date(scheduleData[0]);
    var gap = new DateTime().isTimePassed(scheduleData[2]);

    var timeStatus = "Scheduled";
    if (scheduledDate.getTime() <= currentDate.getTime()) {
      if (gap === 1)
        timeStatus = "Started";
      else
        timeStatus = "Ended";
    }
    return timeStatus;
  }

  extraClzTimeStatus(date: string, startingTime: string, endingTime: string): any {
    const currentDate = new Date();
    const scheduledDate = new Date(date + "T" + startingTime);
    var isToday = (this.datepipe.transform(new Date(date), 'yyyy-MM-dd') == this.datepipe.transform(new Date(), 'yyyy-MM-dd'));
    var gap = new DateTime().isTimePassed(endingTime);

    var timeStatus = "Scheduled";
    if (scheduledDate.getTime() <= currentDate.getTime()) {
      if ((gap === 1) && (isToday))
        timeStatus = "Started";
      else
        timeStatus = "Ended";
    }
    return timeStatus;
  }

  openZoomMeeting(status, liveClassId) {
    if (status !== "Ended") {
      this.sharedService.sharedSub = JSON.stringify({ teacherId: this.teacherId, classId: this.classId, liveClassId: liveClassId });
      // window.open('online_class', '_blank');
      this.router.navigate(['online-class', this.teacherId, this.classId, liveClassId]);
    }
  }

  // validateLiveClass(classId: string, date: string) {
  //   var dt = new Date(date);
  //   return this.validator.validateAccess(this.authService.userData.uid, classId, dt);
  // }

  validateLiveClass() {
    this.liveClasses.forEach((live, index) => {
      var dt = new Date(this.getScheduledDate(live.day, live.startingTime, live.endingTime)[0]);
      this.validator.validateAccess(this.authService.userData.uid, live.classId, dt).then(result => {
        this.liveLock[index] = result;
      });
    });
  }

  getMessage(classId: string, date: string) {
    this.alertService
      .showAlert(
        "You should resolve your payment to unlock this metirial",
        MessageButtons.ok,
        MessageType.accessBlocked
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        if (result === DialogResult.ok) {
          this.payNow(classId, date);
        }
      });
  }

  payNow(classId: string, uploadedDate: string) {
    this.dataservice.getSingleClass(this.authService.userData.uid, classId).then((thisClass: Class) => {
      var myData: ClassPayment = {
        myClass: thisClass,
        date: new Date(uploadedDate)
      }
      this.dialog.open(PaymentManagerComponent, {
        data: myData,
      });
    });
  }

  checkExtraDate(extraDate: string): boolean {
    var extra = new Date(extraDate);
    var current = new Date();

    var isScheduled: boolean;

    if (extra > current) {
      isScheduled = true;
    } else {
      isScheduled = false;
    }

    return isScheduled;
  }
}
