import { Component, OnInit } from '@angular/core';
import { Subject } from '../models/subject';
import { DatabaseManager } from '../database/database-manager';
import { DataService } from '../services/data.service';
import { Router } from '@angular/router';
import { Teacher } from '../models/teacher';
import { Subject_Enrollment } from '../models/Subject_Enrollmen';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../services/auth.service';
import { CustomParm, ParmType } from '../util/custom-parms';
import { SigninComponent } from '../signin/signin.component';
import { AnimationOptions } from 'ngx-lottie';
import {
  GradeSearchParm,
  SerachParm,
  SubjectSearchParm,
} from '../util/search-parms';
import { SearchService } from '../services/search.service';
import { Grade } from '../models/grade';
import { Meta, Title } from '@angular/platform-browser';
import { AdvertisementService } from '../services/advertisement.service';
enum SearchParmold {
  SubjectName = 0,
  GradeName = 1,
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  //constants
  private readonly DEFAULT_SEARCH_FIELD: string = 'gradeName';
  private readonly DEFAULT_SUBJECT_COUNT: number = 16;
  private static readonly WEBPAGE_OFFSET: number = 1;

  // result-list controls

  private static savedGrades: Grade[] = [];
  private lastKey: any = 0;
  private listFlag: number = 0;
  private gradeSearch: SerachParm = {
    field: '',
    keyword: '',
  };
  loadingList: boolean = false;
  isFilterHidden = true;
  isSearchResult: boolean = false;
  // elelments
  subjectPane = document.getElementById('subjectPane');
  searchContainer = document.getElementById('searchContainer');

  grades: Grade[] = [];
  subjectEn: Subject_Enrollment;
  teacher: Teacher;
  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };
  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json',
    loop: true,
  };
  AdPos: Array<number> = [];

  adsAvailable = false;

  adgap = 3;
  constructor(
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    private searchService: SearchService,
    public router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private title: Title,
    private metaTagService: Meta,
    private adService: AdvertisementService
  ) {
    this.initSearchResult();
    // trigger when something searched
    this.searchContainer.hidden = false;
    this.searchService.searchObserver.subscribe((result) => {
      this.isSearchResult = true;
      this.clearSubjectList();
      this.setSearchKeyword(result);
      // set default search type if it is not selected
      if (this.gradeSearch.field === '')
        this.gradeSearch.field = this.DEFAULT_SEARCH_FIELD;
      this.initSearchResult();
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Welcome | Digi Panthiya');
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'The largest learning management system which is newly introduced to Sri Lanka. The future of online teaching and learning is now in front of you.',
    });
    if (this.grades.length > 0) {
      this.AdPos = [];
      for (let index = 0; index < this.grades.length; index++) {
        if (index % this.adgap === 0) {
          this.AdPos.push(Math.round(Math.random() * this.adgap) + index);
        }
      }
    }

    //checking parms when loading
    this.checkingParms(window.history.state.parm);
    // set scroll event
    window.addEventListener('scroll', () => this.scrollHandler(), true); //third parameter
  }
  isAdsAvailable() {
    if (this.adsAvailable) {
      return true;
    } else {
      this.adsAvailable = this.adService.isAdsAvailable();
      return this.adsAvailable;
    }
  }

  isAd(i: number) {
    return this.AdPos.includes(i);
  }

  checkingParms(parm: CustomParm) {
    if (parm !== undefined)
      switch (parm.type) {
        case ParmType.login:
          this.dialog.open(SigninComponent);
          break;
      }
  }

  ngOnDestroy() {
    // remove scroll listener
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  scrollHandler() {
    let triggerHeight =
      this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (
      triggerHeight >=
      this.subjectPane.scrollHeight - HomeComponent.WEBPAGE_OFFSET.valueOf()
    ) {
      this.initSearchResult();
    }
  }

  private initSearchResult() {
    if (this.isSearchResult) {
      if (this.listFlag === 0) {
        if (this.grades.length !== 0)
          this.lastKey = this.grades[this.grades.length - 1].gradeName;
        this.loadSubjects();
      }
    } else {
      if (this.listFlag === 0 && !this.readMemory()) {
        if (this.grades.length !== 0)
          this.lastKey = this.grades[this.grades.length - 1].gradeId;
        this.loadSubjects();
      }
    }
  }

  private loadSubjects(): void {
    this.loadingList = true;
    this.listFlag = 1;
    this.dataservice
      .getGrades(this.DEFAULT_SUBJECT_COUNT, this.lastKey, this.gradeSearch)
      .then((element: Grade[]) => {
        for (let index = 0; index < element.length; index++) {
          if (index % this.adgap === 0) {
            this.AdPos.push(Math.round(Math.random() * this.adgap) + index);
          }
        }
        this.grades = this.grades.concat(element);
        HomeComponent.savedGrades = HomeComponent.savedGrades.concat(element);
        this.listFlag = 0;
        this.loadingList = false;
        if (element.length === 0) this.listFlag = -1;
      });
  }

  private readMemory(): boolean {
    if (this.grades.length === 0 && HomeComponent.savedGrades.length !== 0) {
      this.grades = HomeComponent.savedGrades;
      return true;
    } else {
      return false;
    }
  }

  /**
   * Set search keyword
   * @param keyword searching keyword
   */
  setSearchKeyword(keyword: string) {
    this.gradeSearch.keyword = keyword;
  }

  /**
   * Set search type
   * @param parmType set null to use default field
   */
  setSearchType(parmType: string) {
    this.isFilterHidden = true;
    switch (parmType) {
      case GradeSearchParm.GradeName:
        this.gradeSearch.field = 'gradeName';
        break;
      default:
        this.gradeSearch.field = 'gradeId';
        break;
    }
  }

  /**
   * Clear subject list with the last key
   */
  clearSubjectList() {
    this.grades = [];
    this.lastKey = '';
    this.listFlag = 0;
  }

  public navigateTutors(gradeId: string) {
    this.router.navigate(['subjects', gradeId]); // navigate karanwa app routing module .ts walata
  }

  hideFilter(): void {
    if (this.isFilterHidden) {
      this.isFilterHidden = false;
    } else {
      this.isFilterHidden = true;
    }
  }

  get data(): string {
    return this.sharedService.sharedSub;
  }
}
