import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface Notification {
  bellColor: string;
  message: string;
  priority: number;//1 is the first priority
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationObserver: Subject<Notification> = new Subject<Notification>();

  constructor() { }

  private updateNotification(notification: Notification) {
    this.notificationObserver.next(notification);
  }

  public sendNewMessage(notification: Notification) {
    this.updateNotification(notification);
  }

}
