import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { DatabaseManager } from '../database/database-manager';
import { Student } from '../models/student';
import { AuthService } from '../services/auth.service';
import { LoginState } from '../services/user';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  registerType: string = "signin";
  private timeLeft: number = 60;
  private counterId;
  passwordError: string;
  oobCode: string;
  routeQueryParams$: Subscription;

  constructor(public authService: AuthService, private dataservice: DatabaseManager, public dialogRef: MatDialogRef<SigninComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private route: ActivatedRoute) {
    this.startAttempt();
    if (data != undefined) {
      this.registerType = data;
    }
    this.routeQueryParams$ = route.queryParams.subscribe(params => {
      if (params['oobCode']) {
        this.oobCode = params['oobCode'];
      }
    })
  }

  ngOnInit(): void {
    this.authService.clearAuthMessage();
  }

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
  }

  loginWithGoogle() {
    this.authService.GoogleAuth();
  }

  changeRegType(type: string) {
    this.authService.clearAuthMessage();
    this.registerType = type;
  }

  startAttempt() {
    this.counterId = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 60;
      }
      if (this.authService.loginState === LoginState.VerfiedLogin) {
        clearInterval(this.counterId);
        this.onClose(true);
      } else if ((this.authService.loginState === LoginState.notVerified) && this.registerType === "signin") {
        clearInterval(this.counterId);
        this.onClose(true);
      }

    }, 1000)
  }

  onClose(parm?: boolean): void {
    this.dialogRef.close(parm);
  }

  checkPasswords(newpw: string, confirmpw: string) {
    if (newpw === confirmpw) {
      this.passwordError = undefined;
    } else {
      this.passwordError = "Please Enter the Same Password"
    }
  }

  resetPassword(password: string) {
    if (this.oobCode != undefined) {
      this.authService.resetPassword(this.oobCode, password);
    }
  }

}
