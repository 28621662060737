<div class="bg-white rounded step p-0" style="max-width: 500px">
  <div class="w-100 row">
    <!-- <div class="col-11"> -->
    <h3 class="mx-2"><b>Conference Details</b></h3>
    <!-- </div> -->
    <span
      class="material-icons"
      mat-dialog-close
      style="margin-left: auto; margin-right: -20px"
      >close</span
    >

    <!-- <div style="margin-right: 0%; width: fit-content">
      <span
        mat-dialog-close
        style="margin-left: auto; margin-right: 0%; float: right"
        class="material-icons"
        >close</span
      >
    </div> -->
  </div>

  <mat-horizontal-stepper
    linear
    #stepper
    *ngIf="paymentState && !processDone && !systemError"
  >
    <mat-step>
      <h2 class="text-center">Conference Details</h2>
      <div class="row">
        <div class="col-md-12 p-0">
          <div class="my-2">
            <label for="conferenceTitle"
              >Conference Title : {{ conferenceDetails.conferenceTitle }}
            </label>
          </div>
          <div class="my-2">
            <label for="conferenceTitle"
              >Description : {{ conferenceDetails.confernceDesciption }}</label
            >
          </div>
          <div class="my-2">
            <label for="conferenceTitle"
              >Schedule : {{ conferenceDetails.day }} |
              {{ conferenceDetails.startingTime }} -
              {{ conferenceDetails.endingTime }}</label
            >
          </div>
          <div class="my-2">
            <label for="conferenceTitle"
              >Price :
              <span class="text-success">{{
                conferenceDetails.conferencePrice | currency: "LKR ":true
              }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="w-100 mt-3">
        <div class="buttons" style="float: right">
          <button class="btn btn-danger" mat-dialog-close>Cancel</button>
          <button class="btn btn-purple mx-2" matStepperNext>Confirm</button>
        </div>
      </div>
    </mat-step>

    <mat-step>
      <h2 class="text-center">Payment Method</h2>
      <div
        class="p-0 py-sm-3 pb-3 pb-sm-0"
        style="
          overflow-y: auto;
          padding: -20px;
          max-height: 70vh;
          overflow-x: hidden;
        "
      >
        <div class="py-2 border-bottom">
          <span style="font-size: 1.1em; font-weight: bold"
            >Payment Ref: &nbsp; {{ getOrderId() }}
          </span>
        </div>
        <div class="py-2 border-bottom">
          <span style="font-size: 1em; font-weight: bold"
            >TOTAL: &nbsp;{{ getTotal() }}</span
          >
        </div>
        <p class="mt-2" style="font-size: 0.9em0; font-weight: bold">
          Select your payment method:
        </p>
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="paymentMethod"
        >
          <!-- <mat-radio-button value='1'>Payhere</mat-radio-button> -->
          <mat-radio-button
            value="2"
            style="margin-left: 20px; font-size: 0.9em"
            >Bank Deposite
          </mat-radio-button>
        </mat-radio-group>
        <br />
        <!-- <div class="mt-3" *ngIf="paymentMethod==='1'" style=" display: inline-block;">
          <div class="center" style="width: 100%;">
            <img style="margin-right: auto;margin-left: auto; align-items: center;"
              src="../../assets/templates/payments.png" alt="PayHere" width="180" />
          </div>
          <br>
          <a class="centerr" style="cursor: pointer;" (click)="payHere()"><img
              src="https://www.payhere.lk/downloads/images/pay_with_payhere.png" alt="Pay with PayHere" width="190" />
          </a>
        </div> -->
        <div class="row m-0 mt-3 p-0" *ngIf="paymentMethod === '2'">
          <div class="col-12 p-0">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="mt-3">
                  <h4>Payment Instructions</h4>
                </mat-panel-title>
                <mat-panel-description class="mt-3 littleTxt">
                  Click to {{ panelOpenState ? "hide" : "view" }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ol>
                  <li>Bank Deposit Details</li>
                  <p
                    class="littleTxt"
                    style="color: #2e3131; padding-left: 5px"
                  >
                    Account Number : 88514407 <br />
                    Account Name : International Digi Services Network (Pvt)Ltd
                    <br />
                    Bank : Bank of Ceylon <br />
                    Branch : Boralesgamuwa
                  </p>
                  <li>Take a clear image or a copy of your payment slip.</li>
                  <li>Upload your file below.</li>
                </ol>
              </ng-template>
            </mat-expansion-panel>
            <div class="bankInput mt-3">
              <input
                class="center"
                id="bankslip"
                type="file"
                (change)="selectFile($event)"
              />
              <mat-progress-bar
                class="mt-2"
                mode="determinate"
                [value]="progress.percentage"
              >
              </mat-progress-bar>
            </div>
          </div>
          <br />
          <div class="mt-4" style="margin-left: auto; margin-right: 0%">
            <span
              class="px-3 py-2 rounded btn-outline-purple"
              style="font-size: 0.8em"
              (click)="proceedUpload()"
              *ngIf="!fileUploaded"
              >Upload</span
            >
            <span
              class="px-3 py-2 rounded btn-purple ml-3 text-white"
              style="font-size: 0.8em"
              (click)="finishUpload()"
              [ngStyle]="{
                'pointer-events': fileUploaded ? 'auto' : 'none',
                opacity: fileUploaded ? '1' : '0.4'
              }"
              >Save</span
            >
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="row m-0 p-0 p-4 text-center" *ngIf="systemError">
    <ng-lottie
      style="margin-left: auto; margin-right: auto"
      [options]="option3"
      width="150px"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-3">
      <p style="font-size: 1.2em; color: gray">
        Oops, something went wrong.
        <span *ngIf="paymentMethod === '1'"
          >For any inquiry, please contact
          <a href="tel:+9476 939 4630">(+94) 076 939 4630</a>
        </span>
      </p>
    </div>
  </div>
  <div class="row m-0 p-0 px-4 pt-4 pb-2 text-center" *ngIf="!paymentState">
    <div class="col-12">
      <p style="font-size: 1.5em">Validating Payment</p>
    </div>
    <ng-lottie
      class="mt-3"
      style="margin-left: auto; margin-right: auto"
      [options]="option2"
      width="250px"
      height="auto"
    ></ng-lottie>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="row m-0 p-0 p-4 text-center" *ngIf="processDone">
    <ng-lottie
      style="margin-left: auto; margin-right: auto"
      [options]="option1"
      width="250px"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-3">
      <p style="font-size: 1.5em">Thank you for your payment.</p>
      <p *ngIf="paymentMethod === '2'">
        We will check your payment and approve it within few hours. For any
        inquiry, please contact
        <a href="tel:+9476 939 4630">(+94) 076 939 4630</a>
      </p>
      <span
        (click)="closeMe()"
        class="btn btn-purple text-white mt-3"
        style="width: 75.59px"
        >Exit</span
      >
    </div>
  </div>
</div>
