import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import { Class } from '../models/class';
import { Note } from '../models/note';
import { Teacher } from '../models/teacher';
import {
  ClassPayment,
  PaymentManagerComponent,
} from '../payment-manager/payment-manager.component';
import { PaymentValidator } from '../payment-manager/payment-validator';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { SearchService } from '../services/search.service';
import { NoteSearchParm, SerachParm } from '../util/search-parms';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  private readonly DEFAULT_SEARCH_FIELD: string = NoteSearchParm.NoteTitle;
  private readonly DEFAULT_CLASS_COUNT: number = 12;
  private static readonly WEBPAGE_OFFSET: number = 1;

  private lastKey: any = '';
  private listFlag: number = 0;
  private noteSearch: SerachParm = {
    field: '',
    keyword: '',
  };
  loadingList: boolean = false;
  isFilterHidden = true;
  isSearchResult: boolean = false;

  subjectPane = document.getElementById('subjectPane');
  searchContainer = document.getElementById('searchContainer');

  notes: Note[] = [];
  noteLock: Array<boolean>;
  teacher: Teacher;
  teacherId: string;
  classId: string;

  selectedTab = 0;
  clid = 1;
  darken = -1;
  activeLink = -1;

  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };
  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json',
    loop: true,
  };

  constructor(
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private authService: AuthService,
    private alertService: AlertService,
    private dialog: MatDialog,
    public validator: PaymentValidator,
    private title: Title
  ) {
    this.searchContainer.hidden = false;
    if (this.sharedService.sharedSub !== undefined) {
      this.teacherId = JSON.parse(sharedService.sharedSub).teacherId;
      this.classId = JSON.parse(sharedService.sharedSub).classId;
    } else {
      this.teacherId = route.snapshot.paramMap.get('teacherId');
      this.classId = route.snapshot.paramMap.get('classId');
    }
    this.getTeacher();
    this.initSearchResult();

    this.searchService.searchObserver.subscribe((result) => {
      this.isSearchResult = true;
      this.clearClassList();
      this.setSearchKeyword(result);
      // set default search type if it is not selected
      if (this.noteSearch.field === '') {
        this.noteSearch.field = this.DEFAULT_SEARCH_FIELD;
      }
      this.initSearchResult();
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Notices | Digi Panthiya');
    window.addEventListener('scroll', () => this.scrollHandler(), true);
  }

  ngOnDestroy() {
    // remove scroll listener
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  scrollHandler() {
    let triggerHeight =
      this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (
      triggerHeight >=
      this.subjectPane.scrollHeight - NotesComponent.WEBPAGE_OFFSET.valueOf()
    ) {
      this.initSearchResult();
    }
  }

  private initSearchResult() {
    if (this.isSearchResult) {
      if (this.listFlag === 0) {
        if (this.notes.length !== 0)
          this.lastKey = [
            this.notes[this.notes.length - 1].srhNoteTitle,
            this.notes[this.notes.length - 1].srhTimestamp,
          ];
        this.getNotes();
      }
    } else {
      if (this.listFlag === 0) {
        if (this.notes.length !== 0)
          this.lastKey = [this.notes[this.notes.length - 1].srhTimestamp];
        this.getNotes();
      }
    }
  }

  getNotes() {
    this.loadingList = true;
    this.listFlag = 1;

    this.dataservice
      .getTeacherNotes(
        this.teacherId,
        this.classId,
        this.lastKey,
        this.noteSearch,
        this.DEFAULT_CLASS_COUNT
      )
      .then((elements: Note[]) => {
        this.noteLock = new Array<boolean>(elements.length).fill(false);
        this.notes = this.notes.concat(elements);
        this.listFlag = 0;
        this.loadingList = false;
        if (elements.length === 0) {
          this.listFlag = -1;
        }
        this.validateYourNotes();
      });
  }

  setSearchKeyword(keyword: string) {
    this.noteSearch.keyword = keyword;
  }

  setSearchType(parmType: string) {
    this.isFilterHidden = true;
    switch (parmType) {
      case NoteSearchParm.NoteTitle:
        this.noteSearch.field = NoteSearchParm.NoteTitle;
        break;
      default:
        this.noteSearch.field = NoteSearchParm.NoteTitle;
        break;
    }
  }

  clearClassList() {
    this.notes = [];
    this.lastKey = '';
    this.listFlag = 0;
  }

  hideFilter(): void {
    if (this.isFilterHidden) {
      this.isFilterHidden = false;
    } else {
      this.isFilterHidden = true;
    }
  }

  getTeacher() {
    this.dataservice.getTeacher(this.teacherId).then((element: Teacher) => {
      this.teacher = element;
    });
  }

  setClMenu(id): void {
    this.clid = id;
  }

  darkenDiv(id) {
    this.darken = id;
  }

  normalDiv() {
    this.darken = -1;
  }

  linkActive(id) {
    this.activeLink = id;
  }

  linkDeactive() {
    this.activeLink = -1;
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent) {
    this.selectedTab = tabChangeEvent.index;
  }

  validateYourNotes() {
    this.notes.forEach((note, index) => {
      var dt = new Date(note.publishDate);
      this.validator
        .validateAccess(this.authService.userData.uid, note.classId, dt)
        .then((result) => {
          this.noteLock[index] = result;
        });
    });
  }

  getMessage(classId: string, date: string) {
    this.alertService
      .showAlert(
        'You should resolve your payment to unlock this metirial',
        MessageButtons.ok,
        MessageType.accessBlocked
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        if (result === DialogResult.ok) {
          this.payNow(classId, date);
        }
      });
  }

  payNow(classId: string, uploadedDate: string) {
    this.dataservice
      .getSingleClass(this.authService.userData.uid, classId)
      .then((thisClass: Class) => {
        var myData: ClassPayment = {
          myClass: thisClass,
          date: new Date(uploadedDate),
        };
        this.dialog.open(PaymentManagerComponent, {
          data: myData,
        });
      });
  }
}
