import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { YourNotesComponent } from './your-notes/your-notes.component';
import { MockExamComponent } from './mock-exam/mock-exam.component';
import { NoticesComponent } from './notices/notices.component';
import { TutorsComponent } from './tutors/tutors.component';
import { ProfileComponent } from './profile/profile.component';
import { VideoComponent } from './video/video.component';
import { NotesComponent } from './notes/notes.component';
import { TestComponent } from './test/test.component';
import { QuizanswersheetComponent } from './quizanswersheet/quizanswersheet.component';
import { MyClassesComponent } from './my-classes/my-classes.component';
import { MyclassesProfileComponent } from './myclasses-profile/myclasses-profile.component';
import { ZoomViewerComponent } from './zoom-viewer/zoom-viewer.component';
import { AuthGuard } from './guards/auth.guard';
import { LiveClassesComponent } from './live-classes/live-classes.component';
import { LiveclassProfileComponent } from './liveclass-profile/liveclass-profile.component';
import { FaqComponent } from './faq/faq.component';
import { ContactusComponent } from './contactus/contactus.component';
import { Er404Component } from './er404/er404.component';
import { ExamComponent } from './exam/exam.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { OpenDiscussionGroupComponent } from './open-discussion-group/open-discussion-group.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-notes',
    component: YourNotesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'exam',
    component: ExamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mock-exam/:teacherId/:classId',
    component: MockExamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notices',
    component: NoticesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'tutors/:subjectId',
    component: TutorsComponent,
  },
  {
    path: 'subjects/:gradeId',
    component: SubjectsComponent,
  },
  {
    path: 'profile/:teacherId/:subjectId',
    component: ProfileComponent,
  },
  {
    path: 'video/:teacherId/:subjectId/:classId/:videoId',
    component: VideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'video/:teacherId/:videoId',
    component: VideoComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notes/:teacherId/:classId',
    component: NotesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'quizanswersheet/:teacherId/:classId/:quizId',
    component: QuizanswersheetComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-classes',
    component: MyClassesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'myclass-profile/:teacherId/:subjectId/:classId',
    component: MyclassesProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'online-class/:teacherId/:classId/:liveClassId',
    component: ZoomViewerComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'live-conference/:teacherId/:conferenceId',
    component: ZoomViewerComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'test',
    component: TestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'live-classes',
    component: LiveClassesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'liveclass-profile/:teacherId/:classId',
    component: LiveclassProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'contact',
    component: ContactusComponent,
  },
  {
    path: 'faq',
    component: FaqComponent,
  },
  {
    path: 'digi-conference',
    component: OpenDiscussionGroupComponent,

    // canActivate: [AuthGuard]
  },
  // {
  //   path: 'ebrary', component: EbraryComponent,
  //   // canActivate: [AuthGuard]
  // },
  {
    path: '**',
    component: Er404Component,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
