<div class="px-3 py-4" style="min-height: 92vh;" *ngIf="quiz">
    <div class="container-fluid">
        <div class="row" style="align-items: center;">
            <div class="quiz-title">
                <span><b>{{quiz.quizTitle}}</b></span>
            </div>
            <div class="quiz-details" style="margin-left: auto;margin-right: 0%;" *ngIf="timeLeft">
                <countdown #countdown [config]="{leftTime: timeLeft}" (event)="handleEvent($event)">
                </countdown>
                &nbsp;&nbsp;&nbsp;
                <span>Remaining</span>
            </div>
        </div>
    </div>
    <div class="mt-2 quiz-details">
        <span>Question file:</span>
        <a href="{{quiz.fileUrl}}" download="" target="_blank">File.pdf</a>
    </div>
    <div class="container-fluid" *ngIf="selectedChoice">
        <div class="row">
            <div class="offset-1 offset-sm-0 col-5 col-sm-3 col-md-3 offset-lg-0 col-lg-3 mt-5 quiz-number"
                *ngFor="let rows of questions(toModulus(quiz.noQuestions).rows);let j = index;">
                <div class="mt-md-2" *ngFor="let q of questions(15);let i=index">
                    <span>{{(j*15)+(i+1)}}.</span>
                    <input class="ml-2 ml-lg-3" type="radio" name="{{(j*15)+(i+1)}}" value="1"
                        [(ngModel)]="selectedChoice[(j*15)+(i)]" (click)='onAnswerChange()' />
                    <input [hidden]="!(quiz.mcqChoise > 2 || quiz.mcqChoise == 2)" class="ml-2 ml-lg-3" type="radio"
                        name="{{(j*15)+(i+1)}}" value="2" [(ngModel)]="selectedChoice[(j*15)+(i)]"
                        (click)='onAnswerChange()' />
                    <input [hidden]="!(quiz.mcqChoise > 3 || quiz.mcqChoise == 3)" class="ml-2 ml-lg-3" type="radio"
                        name="{{(j*15)+(i+1)}}" value="3" [(ngModel)]="selectedChoice[(j*15)+(i)]"
                        (click)='onAnswerChange()' />
                    <input [hidden]="!(quiz.mcqChoise > 4 || quiz.mcqChoise == 4)" class="ml-2 ml-lg-3" type="radio"
                        name="{{(j*15)+(i+1)}}" value="4" [(ngModel)]="selectedChoice[(j*15)+(i)]"
                        (click)='onAnswerChange()' />
                    <input [hidden]="!(quiz.mcqChoise > 5 || quiz.mcqChoise == 5)" class="ml-2 ml-lg-3" type="radio"
                        name="{{(j*15)+(i+1)}}" value="5" [(ngModel)]="selectedChoice[(j*15)+(i)]"
                        (click)='onAnswerChange()' />
                </div>
            </div>
            <div class="offset-1 offset-sm-0 col-5 col-sm-3 col-md-3 offset-lg-0 col-lg-3 mt-5 quiz-number">
                <div *ngIf="toModulus(quiz.noQuestions).remain!=0">
                    <div class="mt-md-2" *ngFor="let q of questions(toModulus(quiz.noQuestions).remain);let i=index">
                        <span>{{(toModulus(quiz.noQuestions).rows*15)+(i+1)}}.</span>
                        <input class="ml-2 ml-lg-3" type="radio" name="{{(toModulus(quiz.noQuestions).rows*15)+(i+1)}}"
                            value="1" [(ngModel)]="selectedChoice[(toModulus(quiz.noQuestions).rows*15)+(i)]" />
                        <input [hidden]="!(quiz.mcqChoise > 2 || quiz.mcqChoise == 2)" class="ml-2 ml-lg-3" type="radio"
                            name="{{(toModulus(quiz.noQuestions).rows*15)+(i+1)}}" value="2"
                            [(ngModel)]="selectedChoice[(toModulus(quiz.noQuestions).rows*15)+(i)]" />
                        <input [hidden]="!(quiz.mcqChoise > 3 || quiz.mcqChoise == 3)" class="ml-2 ml-lg-3" type="radio"
                            name="{{(toModulus(quiz.noQuestions).rows*15)+(i+1)}}" value="3"
                            [(ngModel)]="selectedChoice[(toModulus(quiz.noQuestions).rows*15)+(i)]" />
                        <input [hidden]="!(quiz.mcqChoise > 4 || quiz.mcqChoise == 4)" class="ml-2 ml-lg-3" type="radio"
                            name="{{(toModulus(quiz.noQuestions).rows*15)+(i+1)}}" value="4"
                            [(ngModel)]="selectedChoice[(toModulus(quiz.noQuestions).rows*15)+(i)]" />
                        <input [hidden]="!(quiz.mcqChoise > 5 || quiz.mcqChoise == 5)" class="ml-2 ml-lg-3" type="radio"
                            name="{{(toModulus(quiz.noQuestions).rows*15)+(i+1)}}" value="5"
                            [(ngModel)]="selectedChoice[(toModulus(quiz.noQuestions).rows*15)+(i)]" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mt-3">
        <div class="row">
            <button class="btn btn-purple text-white" style="margin-left: auto;margin-right: 0%;"
                (click)="this.router.navigate(['/'])">Finish</button>
        </div>
    </div>
</div>