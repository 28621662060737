import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  activeLink = -1;
  searchContainer = document.getElementById("searchContainer");

  constructor() {
    this.searchContainer.hidden = true;
   }

  ngOnInit(): void {
  }

  linkActive(id) {
    this.activeLink = id;
  }

  linkDeactive() {
    this.activeLink = -1;
  }

}
