import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { elementAt, map } from 'rxjs/operators';
import { Class } from '../models/class';
import { LiveClass } from '../models/liveClass';
import { Marks } from '../models/marks';
import { Payment } from '../models/payment';
import { Note } from '../models/note';
import { Notice } from '../models/notice';
import { Quiz } from '../models/quiz';
import { Student } from '../models/student';
import { Subject } from '../models/subject';
import { Grade } from '../models/grade';
import { Inquiry } from '../models/inquiry';
import { Review } from '../models/review';
import { Teacher } from '../models/teacher';
import { PaymentSet } from '../payment-manager/payment-set';
import { Video } from '../models/video';
import { SavedMCQ } from '../models/savedMCQ';
import { DigiConfernce } from '../models/digi-conference';
import { ClassAd } from '../models/classad';
import { PayhereClsVerification } from '../models/payhereclass';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import {
  SubjectSearchParm,
  SerachParm,
  ClassSearchParm,
  NoteSearchParm,
  SearchOrder,
  VideoSearchParm,
  TeacherSearchParm,
  GradeSearchParm,
} from '../util/search-parms';
import { DatePipe } from '@angular/common';
import firebase, { auth } from 'firebase/app';
import 'firebase/firestore';
import { Discussion } from '../models/discussion';
import { Bookmark } from '../models/bookmark';
import { PostalCharge } from '../models/postalCharge';
import { async } from 'rxjs/internal/scheduler/async';

import { ConferencePayment } from '../models/conference-payment';

//injectable module
@Injectable({
  providedIn: 'root',
})
export class DatabaseManager {
  //angular fire ref
  angularfireRef: AngularFireList<any>;

  //database paths
  private static readonly DBPATH_STUDENT = 'Student';
  private static readonly DBPATH_QUIZ = 'Quiz';
  private static readonly DBPATH_MARKS = 'Marks';
  private static readonly DBPATH_SUBJECT = 'Subject';
  private static readonly DBPATH_GRADE = 'Grade';
  private static readonly DBPATH_TEACHER = 'Teacher';
  private static readonly DBPATH_PRICE_SET = 'Prices';
  private static readonly DBPATH_CONTENT = 'Content';
  private static readonly DBPATH_STUDENT_CLASS = 'StudentClass';
  private static readonly DBPATH_CLASS_STUDENT_ENROLL =
    'ClassStudent_Enrollment';
  private static readonly DBPATH_TEACHER_CLASS = 'Teacher_Class';
  private static readonly DBPATH_STUDENT_TEACHER = 'StudentTeacher';
  private static readonly DBPATH_STUDENT_HISTORY = 'History';
  private static readonly DBPATH_TEACHER_LIVE_CLASS = 'TeacherLiveClass';
  private static readonly DBPATH_TEACHER_ENROLLMENT = 'Teacher_Enrollment';
  private static readonly DBPATH_SUBJECT_ENROLLMENT = 'Subject_Enrollments';
  private static readonly DBPATH_STUDENT_NOTICES = 'StudentNotice';
  private static readonly DBPATH_TEACHER_NOTES = 'TeacherNotes';
  private static readonly DBPATH_PAYMENT = 'StudentPayment';
  private static readonly DBPATH_POSTAL_SERVICE = 'PostalTransaction';
  private static readonly DBPATH_INQUIRY = 'Inquires';
  private static readonly DBPATH_FEATURED_CONTENT = 'FeatureContent';
  private static readonly DBPATH_REVIEWS = 'Reviews';
  private static readonly DBPATH_DISCUSSIONS = 'Discussion';
  private static readonly DBPATH_SUB_DISCUSSIONS = 'Replies';
  private static readonly DBPATH_SAVED_MCQ = 'SavedMCQ';
  private readonly DBPATH_CONTENT_LIKES = 'ContentLikes';
  private readonly DBPATH_BOOKMARKS = 'Bookmarks';
  private readonly DBPATH_SAVED_BOOKMARKS = 'SavedBookmarks';
  private readonly DOC_VIDEO_LIKES = 'VideoLikes';
  private static readonly DBPATH_CONFERENCE = 'Conference';
  private static readonly DBPATH_CONFERENCE_PAYMENT = 'ConferencePayment';
  private static readonly DBPATH_ADVERTISEMENTS = 'Advertisements';
  private static readonly DBPATH_PAYHERE_PAYMENTS = 'PayherePayment';
  private static readonly DBPATH_PAYHERE_CLASS = 'ClassPayment';
  private static readonly DBPATH_PAYHERE_CONFERENCE = 'ConferencePayment';

  private studentRef: AngularFirestoreCollection<Student> = null;
  private teacherRef: AngularFirestoreCollection<Teacher> = null;
  private subjectRef: AngularFirestoreCollection<Subject> = null;

  private student: Student;

  constructor(
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private datepipe: DatePipe
  ) {
    this.studentRef = firestore.collection(DatabaseManager.DBPATH_STUDENT);
    this.teacherRef = firestore.collection(DatabaseManager.DBPATH_TEACHER);
    this.subjectRef = firestore.collection(DatabaseManager.DBPATH_SUBJECT);
  }

  //****************** Grade */
  getGrades(resultLimit: number, lastKey: any, searchParm: SerachParm) {
    return new Promise<Grade[]>((resolve, reject) => {
      var collectionRef;
      switch (searchParm.field) {
        case '':
          collectionRef = this.firestore.collection(
            DatabaseManager.DBPATH_GRADE,
            (results) =>
              results.orderBy('gradeId').startAfter(lastKey).limit(resultLimit)
          );
          break;
        case GradeSearchParm.GradeName:
          collectionRef = this.firestore.collection(
            DatabaseManager.DBPATH_GRADE,
            (results) =>
              results
                .where(GradeSearchParm.GradeName, '>=', searchParm.keyword)
                .where(
                  GradeSearchParm.GradeName,
                  '<=',
                  searchParm.keyword + '~'
                )
                .orderBy(GradeSearchParm.GradeName)
                .startAfter(lastKey)
                .limit(resultLimit)
          );
          break;
        default:
          throw new Error('Wrong search parm');
      }
      collectionRef.get().subscribe((querySnapshot) => {
        let grades: Grade[] = <Grade[]>(
          querySnapshot.docs.map((doc) => doc.data())
        );
        resolve(grades);
      });
    });
  }

  //****************** subject */
  getSubjects(
    gradeId: string,
    resultLimit: number,
    lastKey: any,
    searchParm: SerachParm
  ) {
    return new Promise<Subject[]>((resolve, reject) => {
      var collectionRef;
      switch (searchParm.field) {
        case '':
          collectionRef = this.firestore.collection(
            DatabaseManager.DBPATH_SUBJECT,
            (results) =>
              results
                .where(GradeSearchParm.GradeId, '==', gradeId)
                .orderBy('subjectId')
                .startAfter(lastKey)
                .limit(resultLimit)
          );
          break;
        case SubjectSearchParm.SubjectName:
          collectionRef = this.firestore.collection(
            DatabaseManager.DBPATH_SUBJECT,
            (results) =>
              results
                .where(SubjectSearchParm.SubjectName, '>=', searchParm.keyword)
                .where(
                  SubjectSearchParm.SubjectName,
                  '<=',
                  searchParm.keyword + '~'
                )
                .where(GradeSearchParm.GradeId, '==', gradeId)
                .orderBy(SubjectSearchParm.SubjectName)
                .startAfter(lastKey)
                .limit(resultLimit)
          );
          break;
        default:
          throw new Error('Wrong search parm');
      }
      collectionRef.get().subscribe((querySnapshot) => {
        let subjects: Subject[] = <Subject[]>(
          querySnapshot.docs.map((doc) => doc.data())
        );
        resolve(subjects);
      });
    });
  }
  getSubject(subjectId: string) {
    return new Promise<Subject>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_SUBJECT)
        .doc(subjectId)
        .get()
        .subscribe((doc) => {
          resolve(doc.data() as Subject);
        });
    });
  }

  getAllAdvertisement(limit: number) {
    return new Promise<ClassAd[]>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_ADVERTISEMENTS, (result) =>
          result
            .where('paymentStatus', '==', 1)
            .where('addApprovalState', '==', 1)
            .where('gradeId', '==', 'All')
            .where('expirationDate', '>', Date.now())
            .orderBy('expirationDate', 'asc')
            .limit(limit)
        )
        .get()
        .subscribe((res) => {
          let advertisements: Array<ClassAd> = <Array<ClassAd>>(
            res.docs.map((docs) => docs.data())
          );
          resolve(advertisements);
        });
    });
  }

  getReaminingAds(lastAdId: string, limit: number) {
    return new Promise<ClassAd[]>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_ADVERTISEMENTS, (result) =>
          result
            .where('paymentStatus', '==', 1)
            .where('addApprovalState', '==', 1)
            .where('gradeId', '==', 'All')
            .where('expirationDate', '>', Date.now())
            .orderBy('expirationDate', 'asc')
            .startAfter(lastAdId)
            .limit(limit)
        )
        .get()
        .subscribe((res) => {
          let advertisements: Array<ClassAd> = <Array<ClassAd>>(
            res.docs.map((docs) => docs.data())
          );
          resolve(advertisements);
        });
    });
  }

  getAdvertisementByGrade(gradeId: string, limit: number) {
    return new Promise<ClassAd[]>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_ADVERTISEMENTS, (result) =>
          result
            .where('paymentStatus', '==', 1)
            .where('addApprovalState', '==', 1)
            .where('gradeId', '==', gradeId)
            .where('expirationDate', '>', Date.now())
            .orderBy('expirationDate', 'asc')
            .limit(limit)
        )
        .get()
        .subscribe((res) => {
          let advertisements: Array<ClassAd> = <Array<ClassAd>>(
            res.docs.map((docs) => docs.data())
          );
          resolve(advertisements);
        });
    });
  }

  getRemainingAdvertisementByGrade(
    gradeId: string,
    limit: number,
    lastAdId: string
  ) {
    return new Promise<ClassAd[]>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_ADVERTISEMENTS, (result) =>
          result
            .where('paymentStatus', '==', 1)
            .where('addApprovalState', '==', 1)
            .where('gradeId', '==', gradeId)
            .where('expirationDate', '>', Date.now())
            .orderBy('expirationDate', 'asc')
            .startAfter(lastAdId)
            .limit(limit)
        )
        .get()
        .subscribe((res) => {
          let advertisements: Array<ClassAd> = <Array<ClassAd>>(
            res.docs.map((docs) => docs.data())
          );
          resolve(advertisements);
        });
    });
  }
  //For reviews

  createReview(teacherId: string, review: any) {
    review.reviewId = this.firestore.createId();
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_REVIEWS)
      .doc(review.reviewId)
      .set(review);
  }

  getReviews(teacherId: string, resultLimit: number, lastKey: any) {
    return new Promise<Review[]>((resolve, reject) => {
      var collectionRef;
      if (lastKey == 0) {
        collectionRef = this.teacherRef
          .doc(teacherId)
          .collection(DatabaseManager.DBPATH_REVIEWS, (results) =>
            results
              .orderBy('timestamp', SearchOrder.Descending)
              .limit(resultLimit)
          );
      } else {
        collectionRef = this.teacherRef
          .doc(teacherId)
          .collection(DatabaseManager.DBPATH_REVIEWS, (results) =>
            results
              .orderBy('timestamp', SearchOrder.Descending)
              .startAfter(lastKey)
              .limit(resultLimit)
          );
      }
      collectionRef.get().subscribe((querySnapshot) => {
        let reviews: Review[] = <Review[]>(
          querySnapshot.docs.map((doc) => doc.data())
        );
        resolve(reviews);
      });
    });
  }

  deleteReview(teacherId: string, reviewId: string) {
    this.firestore
      .collection(DatabaseManager.DBPATH_TEACHER)
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_REVIEWS)
      .doc(reviewId)
      .delete();
  }

  updateTeacherRating(teacherId: string, rating: number) {
    this.teacherRef
      .doc(teacherId)
      .get()
      .subscribe((element) => {
        var ratingSet = element.data().ratingSet;
        if (rating == 5) {
          ratingSet[4] = ratingSet[4] + 1;
        } else if (rating == 4) {
          ratingSet[3] = ratingSet[3] + 1;
        } else if (rating == 3) {
          ratingSet[2] = ratingSet[2] + 1;
        } else if (rating == 2) {
          ratingSet[1] = ratingSet[1] + 1;
        } else {
          ratingSet[0] = ratingSet[0] + 1;
        }
        this.teacherRef.doc(teacherId).update({ ratingSet: ratingSet });
      });
  }

  reduceTeacherRating(teacherId: string, rating: number) {
    console.log(rating);
    this.teacherRef
      .doc(teacherId)
      .get()
      .subscribe((element) => {
        var ratingSet = element.data().ratingSet;
        if (rating == 5) {
          ratingSet[4] = ratingSet[4] - 1;
        } else if (rating == 4) {
          ratingSet[3] = ratingSet[3] - 1;
        } else if (rating == 3) {
          ratingSet[2] = ratingSet[2] - 1;
        } else if (rating == 2) {
          ratingSet[1] = ratingSet[1] - 1;
        } else {
          ratingSet[0] = ratingSet[0] - 1;
        }
        this.teacherRef.doc(teacherId).update({ ratingSet: ratingSet });
      });
  }

  //********************for teacher *****************

  createTeacherWithKey(key: string, myTeacher: Teacher) {
    //push data to database
    this.teacherRef.doc(key).set(myTeacher);
  }

  updateTeacher(key: string, value: any): Promise<void> {
    return this.teacherRef.doc(key).update(value);
  }

  deleteTeacher(key: string): Promise<void> {
    return this.teacherRef.doc(key).delete();
  }

  /**
   * return teacher json by using teacherId
   * @param teacherId
   */
  getTeacher(teacherId: string) {
    return new Promise<Teacher>((resolve, reject) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_TEACHER)
        .doc(teacherId)
        .get()
        .subscribe((element) => {
          if (element.data()) {
            resolve(<Teacher>element.data());
          } else reject();
        });
    });
  }

  getTeachersWithSubject(
    subjectId: string,
    lastKey: any,
    searchParm: SerachParm,
    resultLimit: number
  ) {
    return new Promise<Teacher[]>((resolve, reject) => {
      if (lastKey == '') lastKey = ['', ''];
      var collectionRef;
      switch (searchParm.field) {
        case '':
          if (lastKey[0] == '') {
            collectionRef = this.firestore.collection(
              DatabaseManager.DBPATH_TEACHER,
              (results) =>
                results
                  .where('teacherSubjects', 'array-contains', subjectId)
                  .orderBy(TeacherSearchParm.TeacherId, SearchOrder.Ascending)
                  .limit(resultLimit)
            );
          } else {
            collectionRef = this.firestore.collection(
              DatabaseManager.DBPATH_TEACHER,
              (results) =>
                results
                  .where('teacherSubjects', 'array-contains', subjectId)
                  .orderBy(TeacherSearchParm.TeacherId, SearchOrder.Ascending)
                  .startAfter(lastKey[0])
                  .limit(resultLimit)
            );
          }
          break;
        case TeacherSearchParm.TeacherName:
          collectionRef = this.firestore.collection(
            DatabaseManager.DBPATH_TEACHER,
            (results) =>
              results
                .where(TeacherSearchParm.TeacherName, '>=', searchParm.keyword)
                .where(
                  TeacherSearchParm.TeacherName,
                  '<=',
                  searchParm.keyword + '~'
                )
                .where('teacherSubjects', 'array-contains', subjectId)
                .orderBy(TeacherSearchParm.TeacherName, SearchOrder.Ascending)
                .orderBy(TeacherSearchParm.TeacherId, SearchOrder.Ascending)
                .startAfter(lastKey[0], lastKey[1])
                .limit(resultLimit)
          );
          break;
        default:
          throw new Error('Wrong Search Param');
      }
      collectionRef.get().subscribe((elements) => {
        var teachers: Teacher[] = <Teacher[]>(
          elements.docs.map((doc) => doc.data())
        );
        resolve(teachers);
      });
    });
  }

  //*********for quiz**************

  deleteQuiz(key: string): Promise<void> {
    return;
    // this.studentRef.doc(key).delete();
  }

  getQuiz(teacherId: string, classId: string, quizId: string) {
    return new Promise((resolve, reject) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_QUIZ)
        .doc(quizId)
        .get()
        .subscribe((quiz) => {
          if (quiz) {
            resolve(quiz.data());
          } else reject();
        });
    });
  }

  getQuizes(teacherId: string, classId: string) {
    return new Promise((resolve, reject) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_QUIZ)
        .get()
        .subscribe((quizes) => {
          var quizList: Quiz[] = <Quiz[]>quizes.docs.map((doc) => doc.data());
          resolve(quizList);
        });
    });
  }

  hasStudentAnswered(
    teacherId: string,
    classId: string,
    quizId: string,
    studentId: string
  ) {
    return new Promise((resolve, reject) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_QUIZ)
        .doc(quizId)
        .collection(DatabaseManager.DBPATH_MARKS, (result) =>
          result.where('studentId', '==', studentId)
        )
        .get()
        .subscribe((element) => {
          if (element.empty) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
    });
  }

  createSavedMCQ(studentId: string, quizId: string, savedMCQ: SavedMCQ) {
    this.db.database
      .ref(DatabaseManager.DBPATH_SAVED_MCQ)
      .child(studentId)
      .child(quizId)
      .set(savedMCQ);
  }

  updateSavedMCQ(studentId: string, quizId: string, updateContent: any) {
    this.db.database
      .ref(DatabaseManager.DBPATH_SAVED_MCQ)
      .child(studentId)
      .child(quizId)
      .update(updateContent);
  }

  deleteSavedMCQ(studentId: string, quizId: string) {
    this.db.database
      .ref(DatabaseManager.DBPATH_SAVED_MCQ + '/' + studentId + '/' + quizId)
      .remove();
  }

  getSavedMCQ(studentId: string, quizId: string) {
    return new Promise<SavedMCQ>((resolve, reject) => {
      this.db.database
        .ref(DatabaseManager.DBPATH_SAVED_MCQ)
        .child(studentId)
        .child(quizId)
        .once('value')
        .then((element) => {
          resolve(element.val());
        });
    });
  }

  // ***********end Quiz***********

  //************ nonevaluated quiz **********
  createMarksKey(
    teacherId: string,
    classId: string,
    quizId: string,
    studentMarks: Marks
  ) {
    studentMarks.markId = this.firestore.createId();
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(classId)
      .collection(DatabaseManager.DBPATH_QUIZ)
      .doc(quizId)
      .collection(DatabaseManager.DBPATH_MARKS)
      .doc(studentMarks.markId)
      .set(studentMarks);
  }

  //************** end nonevaluated*********

  private completedQuizes = [];
  private lastCQKey: string = '-1';
  private endDate: string = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
  CompletedQuizesNext(
    teacherId: string,
    classId: string,
    startAfter: string,
    limitSize: number,
    studentId: string
  ) {
    return new Promise<Array<any>>((resolve, reject) => {
      if (this.completedQuizes.length === 0 || this.lastCQKey !== startAfter) {
        const topCollection = this.teacherRef
          .doc(teacherId)
          .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
          .doc(classId);
        let collectionRef;
        if (startAfter) {
          collectionRef = topCollection.collection(
            DatabaseManager.DBPATH_QUIZ,
            (results) =>
              results
                .where('endDate', '<', this.endDate)
                .orderBy('endDate', SearchOrder.Descending)
                .orderBy('endTime', SearchOrder.Descending)
                .startAfter(startAfter)
                .limit(limitSize)
          );
        } else {
          collectionRef = topCollection.collection(
            DatabaseManager.DBPATH_QUIZ,
            (results) =>
              results
                .where('endDate', '<', this.endDate)
                .orderBy('endDate', SearchOrder.Descending)
                .orderBy('endTime', SearchOrder.Descending)
                .limit(limitSize)
          );
        }
        collectionRef.get().subscribe((querySnapshot) => {
          var cQuizes: Array<any> = [];
          this.lastCQKey = startAfter;
          this.completedQuizes = <Quiz[]>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          if (this.completedQuizes.length !== 0) {
            this.completedQuizes.forEach((element) => {
              this.teacherRef
                .doc(teacherId)
                .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
                .doc(classId)
                .collection(DatabaseManager.DBPATH_QUIZ)
                .doc(element.quizId)
                .collection(DatabaseManager.DBPATH_MARKS)
                .get()
                .subscribe((querySnapshot) => {
                  var quiz = {
                    quizId: element.quizId,
                    quizTitle: element.quizTitle,
                    subjectName: element.subjectName,
                    startDate: element.startDate,
                    endDate: element.endDate,
                    type: element.type,
                    isEvaluated: false,
                    marks: '',
                  };
                  var data = <Array<any>>(
                    querySnapshot.docs.map((doc) => doc.data())
                  );
                  if (data.length > 0) {
                    quiz.marks = data[0].marks;
                    quiz.isEvaluated = true;
                  }
                  cQuizes.push(quiz);
                  if (cQuizes.length === this.completedQuizes.length) {
                    this.completedQuizes = cQuizes;
                    resolve(this.completedQuizes);
                  }
                });
            });
          } else {
            reject();
          }
        });
      } else {
        resolve(this.completedQuizes);
      }
    });
  }

  CompletedQuizesPrev(
    teacherId: string,
    classId: string,
    endBefore: string,
    limitSize: number
  ) {
    return new Promise<Quiz[]>((resolve, reject) => {
      const topCollection = this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId);
      let collectionRef;
      if (endBefore) {
        collectionRef = topCollection.collection(
          DatabaseManager.DBPATH_QUIZ,
          (results) =>
            results
              .where('endDate', '<=', this.endDate)
              .orderBy('endDate', SearchOrder.Descending)
              .orderBy('endTime', SearchOrder.Descending)
              .endBefore(endBefore)
              .limitToLast(limitSize)
        );
      } else {
        collectionRef = topCollection.collection(
          DatabaseManager.DBPATH_QUIZ,
          (results) =>
            results
              .where('endDate', '<=', this.endDate)
              .orderBy('endDate', SearchOrder.Descending)
              .orderBy('endTime', SearchOrder.Descending)
              .limit(limitSize)
        );
      }
      collectionRef.get().subscribe((querySnapshot) => {
        let quizes: Quiz[] = <Quiz[]>(
          querySnapshot.docs.map((doc) => doc.data())
        );
        if (quizes.length !== 0 && quizes.length === limitSize) {
          resolve(quizes);
        } else {
          reject();
        }
      });
    });
  }

  // ***********Subject_Enrollment***********
  getSubject_Enrollment(subjectId: string) {
    return new Promise((resolve) => {
      this.subjectRef
        .doc(subjectId)
        .collection(DatabaseManager.DBPATH_SUBJECT_ENROLLMENT)
        .doc('SubjectEnrollDoc')
        .get()
        .subscribe((element) => {
          resolve(<string[]>element.data().idSet);
        });
    });
  }

  /**
   * Checks the given class is valid for the student
   * @param studentId
   * @param classId
   * return promise<boolean>
   */
  validStudentClass(studentId: string, classId: string) {
    return new Promise<boolean>((resolve) => {
      this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_STUDENT_CLASS)
        .doc(classId)
        .get()
        .subscribe((element) => {
          if (element.exists) {
            resolve(true);
          } else resolve(false);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * return promise Array<LiveClass>
   */

  getLiveClassSet(teacherId: string, classId: string) {
    return new Promise((resolve, reject) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_TEACHER_LIVE_CLASS)
        .get()
        .subscribe((elements) => {
          var liveClassList = <LiveClass[]>(
            elements.docs.map((doc) => doc.data())
          );
          resolve(liveClassList);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param liveId
   */
  getLiveClass(teacherId: string, classId: string, liveId: string) {
    return new Promise((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_TEACHER_LIVE_CLASS)
        .doc(liveId)
        .get()
        .subscribe((element) => {
          resolve(<LiveClass>element.data());
        });
    });
  }

  /**
   *
   * @param liveClass
   * @param teacherId
   * @param classId
   */
  addLiveClass(liveClass: LiveClass, teacherId: string, classId: string) {
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(classId)
      .collection(DatabaseManager.DBPATH_TEACHER_LIVE_CLASS)
      .add(liveClass);
  }

  /***********StudentClasses **********/

  studentClassRef: AngularFireList<Class> = null;

  /**
   *
   * @param key
   * @param myclass
   */
  createStudentClassWithKey(key: string, myclass: Class) {
    this.studentClassRef.set(key, myclass);
  }

  /**
   *
   * @param key
   * @param value
   * @returns
   */
  updateStudentClass(key: string, value: any): Promise<void> {
    return this.studentClassRef.update(key, value); //update some data
  }

  deleteStudentClass(key: string): Promise<void> {
    return this.studentClassRef.remove(key); //delete some data
  }

  getStudentClasses(
    studentId: string,
    resultLimit: number,
    lastKey: any,
    searchParm: SerachParm
  ) {
    return new Promise((resolve) => {
      var collectionRef;
      switch (searchParm.field) {
        case '':
          collectionRef = this.studentRef
            .doc(studentId)
            .collection(DatabaseManager.DBPATH_STUDENT_CLASS, (results) =>
              results
                .where('isDeleted', '==', false)
                .where('classStatus', '==', 1)
                .orderBy('classId')
                .startAfter(lastKey)
                .limit(resultLimit)
            );
          break;
        case ClassSearchParm.SubjectName:
          if (lastKey == '') lastKey = ['', ''];
          collectionRef = this.studentRef
            .doc(studentId)
            .collection(DatabaseManager.DBPATH_STUDENT_CLASS, (results) =>
              results
                .where(ClassSearchParm.SubjectName, '>=', searchParm.keyword)
                .where(
                  ClassSearchParm.SubjectName,
                  '<=',
                  searchParm.keyword + '~'
                )
                .where('isDeleted', '==', false)
                .where('classStatus', '==', 1)
                .orderBy(ClassSearchParm.SubjectName, SearchOrder.Ascending)
                .orderBy('classId', SearchOrder.Ascending)
                .startAfter(lastKey[0], lastKey[1])
                .limit(resultLimit)
            );
          break;
        case ClassSearchParm.TeacherName:
          if (lastKey == '') lastKey = ['', ''];
          collectionRef = this.studentRef
            .doc(studentId)
            .collection(DatabaseManager.DBPATH_STUDENT_CLASS, (results) =>
              results
                .where(ClassSearchParm.TeacherName, '>=', searchParm.keyword)
                .where(
                  ClassSearchParm.TeacherName,
                  '<=',
                  searchParm.keyword + '~'
                )
                .where('isDeleted', '==', false)
                .where('classStatus', '==', 1)
                .orderBy(ClassSearchParm.TeacherName, 'asc')
                .orderBy('classId', 'asc')
                .startAfter(lastKey[0], lastKey[1])
                .limit(resultLimit)
            );
          break;
        default:
          throw new Error('Wrong search parm');
      }
      collectionRef.get().subscribe((elements) => {
        let classes: Class[] = <Class[]>elements.docs.map((doc) => doc.data());
        resolve(classes);
      });
    });
  }

  /**
   * Return class object
   * @param classId
   * @returns class
   */
  getSingleClass(studentId: string, classId: string) {
    return new Promise((resolve) => {
      this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_STUDENT_CLASS)
        .doc(classId)
        .get()
        .subscribe((element) => {
          resolve(<Class>element.data());
        });
    });
  }

  /***********StudentClasses **********/

  createTeacherNoteWithKey(key: string, note: Note) {
    this.teacherRef
      .doc(note.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(note.classId)
      .collection(DatabaseManager.DBPATH_TEACHER_NOTES)
      .add(note);
  }

  updateTeacherNote(key: string, value: Note): Promise<void> {
    return this.teacherRef
      .doc(value.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(value.classId)
      .collection(DatabaseManager.DBPATH_TEACHER_NOTES)
      .doc(key)
      .update(value);
  }

  deleteTeacherNote(key: string): Promise<void> {
    return this.teacherRef.doc(key).delete();
  }

  getTeacherNotes(
    teacherId: string,
    classId: string,
    lastKey: any,
    searchParm: SerachParm,
    resultLimit: number
  ) {
    return new Promise((resolve, reject) => {
      if (lastKey == '') lastKey = ['', ''];

      var collectionRef;
      switch (searchParm.field) {
        case '':
          if (lastKey[0] == '') {
            collectionRef = this.teacherRef
              .doc(teacherId)
              .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
              .doc(classId)
              .collection(DatabaseManager.DBPATH_TEACHER_NOTES, (results) =>
                results
                  .orderBy(NoteSearchParm.Timestamp, SearchOrder.Ascending)
                  .limit(resultLimit)
              );
          } else {
            collectionRef = this.teacherRef
              .doc(teacherId)
              .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
              .doc(classId)
              .collection(DatabaseManager.DBPATH_TEACHER_NOTES, (results) =>
                results
                  .orderBy(NoteSearchParm.Timestamp, SearchOrder.Ascending)
                  .startAfter(lastKey[0])
                  .limit(resultLimit)
              );
          }
          break;
        case NoteSearchParm.NoteTitle:
          collectionRef = this.teacherRef
            .doc(teacherId)
            .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
            .doc(classId)
            .collection(DatabaseManager.DBPATH_TEACHER_NOTES, (results) =>
              results
                .where(NoteSearchParm.NoteTitle, '>=', searchParm.keyword)
                .where(NoteSearchParm.NoteTitle, '<=', searchParm.keyword + '~')
                .orderBy(NoteSearchParm.NoteTitle, SearchOrder.Ascending)
                .orderBy(NoteSearchParm.Timestamp, SearchOrder.Ascending)
                .startAfter(lastKey[0], lastKey[1])
                .limit(resultLimit)
            );
          break;
        default:
          throw new Error('Wrong Search Param');
      }
      collectionRef.get().subscribe((elements) => {
        let notes: Note[] = <Note[]>elements.docs.map((doc) => doc.data());
        resolve(notes);
      });
    });
  }

  /*** student */

  /**
   * Add new student to the system
   * @param student
   */
  addNewStudent(student: Student) {
    this.studentRef.doc(student.studentId).set(student);
  }

  /**
   * update student
   * @param key
   * @param value
   */
  updateStudent(key: string, value: any): Promise<void> {
    return this.studentRef.doc(key).update(value);
  }

  /**
   * check whether student is exists
   * @param studentId
   * @returns
   */
  isStudentAvailable(studentId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.studentRef
        .doc(studentId)
        .get()
        .subscribe((querySnapshot) => {
          if (querySnapshot.exists) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  }

  /**
   * get student with key
   * @param studentId
   * @returns
   */
  getStudent(studentId: string) {
    return new Promise<Student>((resolve) => {
      if (this.student === undefined) {
        this.studentRef
          .doc(studentId)
          .get()
          .subscribe((querySnapshot) => {
            this.student = <Student>querySnapshot.data();
            resolve(this.student);
          });
      } else resolve(this.student);
    });
  }

  /*** student notices */

  deleteStudentNotice(key: string, studentId: string) {
    return this.studentRef
      .doc(studentId)
      .collection(DatabaseManager.DBPATH_STUDENT_NOTICES)
      .doc(key)
      .delete();
  }

  getStudentNotices(studentId: string) {
    return new Promise<Array<Notice>>((resolve, reject) => {
      var noticeList: Notice[] = new Array();

      this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_STUDENT_NOTICES, (res) =>
          res.orderBy('timestamp', 'desc')
        )
        .snapshotChanges()
        .pipe(
          map((changes: any[]) =>
            changes.map((c) => ({
              key: c.payload.doc.id,
              ...c.payload.doc.data(),
            }))
          )
        )
        .subscribe((notices) => {
          noticeList = new Array<Notice>();
          notices.forEach((notice) => {
            var n: Notice = {
              noticeId: notice.key,
              gradeName: notice.gradeName,
              isSystemMessage: notice.isSystemMessage,
              messageDescription: notice.messageDescription,
              messageName: notice.messageName,
              publishDate: notice.publishDate,
              publishTime: notice.publishTime,
              subjectName: notice.subjectName,
              teacherId: notice.teacherId,
              teacherName: notice.teacherName,
              teacherPic: notice.teacherPic,
            };
            noticeList.push(n);
          });
          resolve(noticeList);
        });
    });
  }

  /*****  Teacher Classes  ***** */

  createTeacherClassWithKey(key: string, myclass: Class) {
    this.teacherRef
      .doc(myclass.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(key)
      .set(myclass);
  }

  updateTeacherClass(key: string, value: any): Promise<void> {
    return this.teacherRef
      .doc(value.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(key)
      .update(value); //update some data
  }

  deleteTeacherClass(myclass: Class): Promise<void> {
    return this.teacherRef
      .doc(myclass.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(myclass.classId)
      .delete();
  }

  getTeacherClassWithSubject(teacherId: string, subjectId: string) {
    return new Promise((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS, (results) =>
          results
            .where('subjectId', '==', subjectId)
            .where('classStatus', '==', 1)
        )
        .get()
        .subscribe((elements) => {
          let classes = <Class[]>elements.docs.map((doc) => doc.data());
          resolve(classes);
        });
    });
  }

  /***** Student **** */

  /**For paid section**/
  getPriceData() {
    return new Promise<PaymentSet>((resolve) => {
      this.db.database
        .ref(DatabaseManager.DBPATH_PRICE_SET)
        .once('value')
        .then(function (snapshot) {
          resolve(snapshot.val());
        });
    });
  }

  private subscribedTeachers: string[] = [];
  getSubscribedTeachers(studentId: string) {
    return new Promise((resolve) => {
      if (this.subscribedTeachers.length === 0) {
        this.studentRef
          .doc(studentId)
          .collection(DatabaseManager.DBPATH_STUDENT_TEACHER)
          .doc('StudentTeacherDoc')
          .get()
          .subscribe((element) => {
            this.subscribedTeachers = <string[]>element.data().idSet;
            resolve(this.subscribedTeachers);
          });
      } else resolve(this.subscribedTeachers);
    });
  }

  private teacherEnrollments: string[] = [];
  getTeacherEnrollment(teacherId: string) {
    return new Promise((resolve) => {
      if (this.teacherEnrollments.length === 0) {
        this.teacherRef
          .doc(teacherId)
          .collection(DatabaseManager.DBPATH_TEACHER_ENROLLMENT, (results) =>
            results.where('idSet', 'array-contains', '')
          )
          .get()
          .subscribe((element) => {});
      } else resolve(this.teacherEnrollments);
    });
  }

  createDocID(): string {
    return this.firestore.createId();
  }

  payForClass(classPaid: Payment, studentId: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.firestore
        .collection('Student')
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_PAYMENT)
        .doc(classPaid.documentId)
        .set(classPaid)
        .then(
          (res) => {
            resolve(true);
          },
          (err) => reject(err)
        );
    });
  }

  subscribeClass(myClass: Class, studentId: string) {
    this.studentRef
      .doc(studentId)
      .collection(DatabaseManager.DBPATH_STUDENT_CLASS)
      .doc(myClass.classId)
      .set(myClass);
    this.teacherRef
      .doc(myClass.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(myClass.classId)
      .collection(DatabaseManager.DBPATH_CLASS_STUDENT_ENROLL)
      .doc('ClassStudentDoc')
      .update({
        idSet: firebase.firestore.FieldValue.arrayUnion(studentId),
      })
      .catch((err) => {
        let ar = new Array<string>();
        ar.push(studentId);
        var doc = {
          idSet: ar,
        };
        this.teacherRef
          .doc(myClass.teacherId)
          .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
          .doc(myClass.classId)
          .collection(DatabaseManager.DBPATH_CLASS_STUDENT_ENROLL)
          .doc('ClassStudentDoc')
          .set(doc);
      });
  }

  subscribeTeacher(teacherId: string, studentId: string) {
    this.studentRef
      .doc(studentId)
      .collection(DatabaseManager.DBPATH_STUDENT_TEACHER)
      .doc('StudentTeacherDoc')
      .update({
        idSet: firebase.firestore.FieldValue.arrayUnion(teacherId),
      })
      .catch((err) => {
        let ar = new Array<string>();
        ar.push(teacherId);
        var doc = {
          idSet: ar,
        };
        this.studentRef
          .doc(studentId)
          .collection(DatabaseManager.DBPATH_STUDENT_TEACHER)
          .doc('StudentTeacherDoc')
          .set(doc);
      });
  }

  /**
   * Unenroll from the given class
   * @param studentId
   * @param myClass
   */
  unenrollClass(studentId: string, myClass: Class) {
    return new Promise<boolean>((resolve, reject) => {
      this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_STUDENT_CLASS)
        .doc(myClass.classId)
        .delete()
        .then((res) => {
          this.studentRef
            .doc(studentId)
            .collection(DatabaseManager.DBPATH_STUDENT_HISTORY)
            .doc('StudentHistory')
            .update({
              unenrolledClsSet: firebase.firestore.FieldValue.arrayUnion(
                myClass.classId
              ),
            })
            .catch((err) => {
              let ar = new Array<string>();
              ar.push(myClass.classId);
              var doc = {
                unenrolledClsSet: ar,
              };
              this.studentRef
                .doc(studentId)
                .collection(DatabaseManager.DBPATH_STUDENT_HISTORY)
                .doc('StudentHistory')
                .set(doc);
            })
            .finally(() => {
              this.teacherRef
                .doc(myClass.teacherId)
                .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
                .doc(myClass.classId)
                .collection(DatabaseManager.DBPATH_CLASS_STUDENT_ENROLL)
                .doc('ClassStudentDoc')
                .update({
                  idSet: firebase.firestore.FieldValue.arrayRemove(studentId),
                });
              resolve(true);
            });
        });
    });
  }

  getPayments(studentId: string, classId: string, year: number, month: number) {
    return new Promise<Array<Payment>>((resolve, reject) => {
      var paymentList: Array<Payment> = new Array();
      const collectionRef = this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_PAYMENT, (ref) =>
          ref
            .where('classId', '==', classId)
            .where('year', '==', year)
            .where('month', '==', month)
            .where('paymentStatus', '==', 1)
        )
        .get()
        .subscribe((querySnapshot) => {
          paymentList = <Payment[]>querySnapshot.docs.map((doc) => doc.data());
          resolve(paymentList);
        });
    });
  }

  private paymentHistory: Payment[] = [];
  private lastPHisKey: string = '-1';
  paymentHistoryNext(studentId: string, startAfter: string, limitSize: number) {
    return new Promise<Payment[]>((resolve, reject) => {
      if (this.paymentHistory.length === 0 || this.lastPHisKey !== startAfter) {
        const topCollection = this.firestore
          .collection(DatabaseManager.DBPATH_STUDENT)
          .doc(studentId);
        let collectionRef;
        if (startAfter) {
          collectionRef = topCollection.collection(
            DatabaseManager.DBPATH_PAYMENT,
            (ref) =>
              ref
                .orderBy('paymentId', 'desc')
                .startAfter(startAfter)
                .limit(limitSize)
          );
        } else {
          collectionRef = topCollection.collection(
            DatabaseManager.DBPATH_PAYMENT,
            (ref) => ref.orderBy('paymentId', 'desc').limit(limitSize)
          );
        }
        collectionRef.get().subscribe((querySnapshot) => {
          this.lastPHisKey = startAfter;
          this.paymentHistory = <Payment[]>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          if (this.paymentHistory.length !== 0) {
            resolve(this.paymentHistory);
          } else {
            reject();
          }
        });
      } else {
        resolve(this.paymentHistory);
      }
    });
  }

  paymentHistoryPrev(studentId: string, endBefore: string, limitSize: number) {
    return new Promise<Payment[]>((resolve, reject) => {
      const topCollection = this.firestore
        .collection(DatabaseManager.DBPATH_STUDENT)
        .doc(studentId);
      let collectionRef;
      if (endBefore) {
        collectionRef = topCollection.collection(
          DatabaseManager.DBPATH_PAYMENT,
          (ref) =>
            ref
              .orderBy('paymentId', 'desc')
              .endBefore(endBefore)
              .limitToLast(limitSize)
        );
      } else {
        collectionRef = topCollection.collection(
          DatabaseManager.DBPATH_PAYMENT,
          (ref) => ref.orderBy('paymentId', 'desc').limit(limitSize)
        );
      }
      collectionRef.get().subscribe((querySnapshot) => {
        let payment: Payment[] = <Payment[]>(
          querySnapshot.docs.map((doc) => doc.data())
        );
        if (payment.length !== 0 && payment.length === limitSize) {
          resolve(payment);
        } else {
          reject();
        }
      });
    });
  }

  /**** Video *****/
  getVideos(
    myClass: Class,
    lastKey: any,
    searchParm: SerachParm,
    resultLimit: number
  ) {
    return new Promise((resolve, reject) => {
      if (lastKey == '') lastKey = ['', ''];

      var collectionRef;
      switch (searchParm.field) {
        case '':
          if (lastKey[0] == '') {
            collectionRef = this.teacherRef
              .doc(myClass.teacherId)
              .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
              .doc(myClass.classId)
              .collection(DatabaseManager.DBPATH_CONTENT, (results) =>
                results
                  .orderBy(VideoSearchParm.Timestamp, SearchOrder.Ascending)
                  .limit(resultLimit)
              );
          } else {
            collectionRef = this.teacherRef
              .doc(myClass.teacherId)
              .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
              .doc(myClass.classId)
              .collection(DatabaseManager.DBPATH_CONTENT, (results) =>
                results
                  .orderBy(VideoSearchParm.Timestamp, SearchOrder.Ascending)
                  .startAfter(lastKey[0])
                  .limit(resultLimit)
              );
          }
          break;
        case NoteSearchParm.NoteTitle:
          collectionRef = this.teacherRef
            .doc(myClass.teacherId)
            .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
            .doc(myClass.classId)
            .collection(DatabaseManager.DBPATH_CONTENT, (results) =>
              results
                .where(VideoSearchParm.VideoTitle, '>=', searchParm.keyword)
                .where(
                  VideoSearchParm.VideoTitle,
                  '<=',
                  searchParm.keyword + '~'
                )
                .orderBy(VideoSearchParm.VideoTitle, SearchOrder.Ascending)
                .orderBy(VideoSearchParm.Timestamp, SearchOrder.Ascending)
                .startAfter(lastKey[0], lastKey[1])
                .limit(resultLimit)
            );
          break;
        default:
          throw new Error('Wrong Search Param');
      }
      collectionRef.get().subscribe((elements) => {
        let videos: Video[] = <Video[]>elements.docs.map((doc) => doc.data());
        resolve(videos);
      });
    });
  }

  /**
   * Return video object
   * @param myClass
   * @returns
   */
  getVideo(teacherId: string, classId: string, videoId: string) {
    return new Promise<Video>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .get()
        .subscribe((element) => {
          resolve(<Video>element.data());
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param viewCount
   */
  addVideoView(
    teacherId: string,
    classId: string,
    videoId: string,
    viewCount: number
  ) {
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(classId)
      .collection(DatabaseManager.DBPATH_CONTENT)
      .doc(videoId)
      .update({
        views: viewCount,
      });
  }

  /**
   *
   * @param teacherId
   * @param videoId
   * @param viewCount
   */
  addFeatureView(teacherId: string, videoId: string, viewCount: number) {
    this.teacherRef
      .doc(teacherId)
      .collection(DatabaseManager.DBPATH_FEATURED_CONTENT)
      .doc(videoId)
      .update({
        views: viewCount,
      });
  }

  /**
   *
   * @param video
   */
  addVideoLike(video: Video) {
    this.teacherRef
      .doc(video.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(video.classId)
      .collection(DatabaseManager.DBPATH_CONTENT)
      .doc(video.videoId)
      .update({
        likes: firebase.firestore.FieldValue.increment(1),
      });
  }

  /**
   *
   * @param video
   */
  removeVideoLike(video: Video) {
    this.teacherRef
      .doc(video.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(video.classId)
      .collection(DatabaseManager.DBPATH_CONTENT)
      .doc(video.videoId)
      .update({
        likes: firebase.firestore.FieldValue.increment(-1),
      });
  }

  /**
   *
   * @param video
   */
  addVideoUnlike(video: Video) {
    this.teacherRef
      .doc(video.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(video.classId)
      .collection(DatabaseManager.DBPATH_CONTENT)
      .doc(video.videoId)
      .update({
        dislikes: firebase.firestore.FieldValue.increment(1),
      });
  }

  /**
   *
   * @param video
   */
  removeVideoUnlike(video: Video) {
    this.teacherRef
      .doc(video.teacherId)
      .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
      .doc(video.classId)
      .collection(DatabaseManager.DBPATH_CONTENT)
      .doc(video.videoId)
      .update({
        dislikes: firebase.firestore.FieldValue.increment(-1),
      });
  }

  isVideoLiked(studentId: string, videoId: string) {
    return new Promise<boolean>((resolve) => {
      this.studentRef
        .doc(studentId)
        .collection(this.DBPATH_CONTENT_LIKES, (result) =>
          result.where('likedVideos', 'array-contains', videoId)
        )
        .get()
        .subscribe((elements) => {
          let data = elements.docs.map((doc) => doc.data());
          resolve(data.length !== 0);
        });
    });
  }

  addToLikedVideos(studentId: string, videoId: string) {
    this.studentRef
      .doc(studentId)
      .collection(this.DBPATH_CONTENT_LIKES)
      .doc(this.DOC_VIDEO_LIKES)
      .update({
        likedVideos: firebase.firestore.FieldValue.arrayUnion(videoId),
      })
      .catch((err) => {
        let ar = new Array<string>();
        ar.push(videoId);
        var doc = {
          likedVideos: ar,
          dislikedVideos: null,
        };
        this.studentRef
          .doc(studentId)
          .collection(this.DBPATH_CONTENT_LIKES)
          .doc(this.DOC_VIDEO_LIKES)
          .set(doc);
      });
  }

  removeFromLikedVideos(studentId: string, videoId: string) {
    this.studentRef
      .doc(studentId)
      .collection(this.DBPATH_CONTENT_LIKES)
      .doc(this.DOC_VIDEO_LIKES)
      .update({
        likedVideos: firebase.firestore.FieldValue.arrayRemove(videoId),
      })
      .catch((err) => {
        let ar = new Array<string>();
        ar.push(videoId);
        var doc = {
          likedVideos: ar,
          dislikedVideos: null,
        };
        this.studentRef
          .doc(studentId)
          .collection(this.DBPATH_CONTENT_LIKES)
          .doc(this.DOC_VIDEO_LIKES)
          .set(doc);
      });
  }

  /**
   *
   * @param video
   */
  addVideoDislike(video: Video) {
    this.db.database
      .ref(
        DatabaseManager.DBPATH_CONTENT +
          '/' +
          video.teacherId +
          '/' +
          video.subjectId +
          '/' +
          video.classId +
          '/' +
          video.videoId
      )
      .once('value')
      .then((snapshot1) => {
        var obj = <Video>snapshot1.val();
        var count = obj.likes + 1;
        this.db
          .list(
            DatabaseManager.DBPATH_CONTENT +
              '/' +
              video.teacherId +
              '/' +
              video.subjectId +
              '/' +
              video.classId +
              '/' +
              video.videoId
          )
          .set('dislikes', count);
      });
  }

  /**
   *
   * @param video
   */
  removeVideoDislike(video: Video) {
    this.db.database
      .ref(
        DatabaseManager.DBPATH_CONTENT +
          '/' +
          video.teacherId +
          '/' +
          video.subjectId +
          '/' +
          video.classId +
          '/' +
          video.videoId
      )
      .once('value')
      .then((snapshot1) => {
        var obj = <Video>snapshot1.val();
        var count = obj.likes - 1;
        this.db
          .list(
            DatabaseManager.DBPATH_CONTENT +
              '/' +
              video.teacherId +
              '/' +
              video.subjectId +
              '/' +
              video.classId +
              '/' +
              video.videoId
          )
          .set('dislikes', count);
      });
  }

  /**
   *
   * @param studentId
   * @param videoId
   * @returns
   */
  isVideoDisliked(studentId: string, videoId: string) {
    return new Promise<boolean>((resolve) => {
      this.studentRef
        .doc(studentId)
        .collection(this.DBPATH_CONTENT_LIKES, (result) =>
          result.where('dislikedVideos', 'array-contains', videoId)
        )
        .get()
        .subscribe((elements) => {
          let data = elements.docs.map((doc) => doc.data());
          resolve(data.length !== 0);
        });
    });
  }

  addToDislikedVideos(studentId: string, videoId: string) {
    this.studentRef
      .doc(studentId)
      .collection(this.DBPATH_CONTENT_LIKES)
      .doc(this.DOC_VIDEO_LIKES)
      .update({
        dislikedVideos: firebase.firestore.FieldValue.arrayUnion(videoId),
      })
      .catch((err) => {
        let ar = new Array<string>();
        ar.push(videoId);
        var doc = {
          dislikedVideos: ar,
          likedVideos: null,
        };
        this.studentRef
          .doc(studentId)
          .collection(this.DBPATH_CONTENT_LIKES)
          .doc(this.DOC_VIDEO_LIKES)
          .set(doc);
      });
  }

  removeFromDislikedVideos(studentId: string, videoId: string) {
    this.studentRef
      .doc(studentId)
      .collection(this.DBPATH_CONTENT_LIKES)
      .doc(this.DOC_VIDEO_LIKES)
      .update({
        dislikedVideos: firebase.firestore.FieldValue.arrayRemove(videoId),
      })
      .catch((err) => {
        let ar = new Array<string>();
        ar.push(videoId);
        var doc = {
          dislikedVideos: ar,
          likedVideos: null,
        };
        this.studentRef
          .doc(studentId)
          .collection(this.DBPATH_CONTENT_LIKES)
          .doc(this.DOC_VIDEO_LIKES)
          .set(doc);
      });
  }

  addInquiry(inquiry: Inquiry) {
    this.db.database.ref(DatabaseManager.DBPATH_INQUIRY).push(inquiry);
  }

  //featured Videos
  getFeaturedVideos(teacherId: string) {
    return new Promise((resolve) => {
        this.teacherRef
          .doc(teacherId)
          .collection(DatabaseManager.DBPATH_FEATURED_CONTENT)
          .get()
          .subscribe((elements) => {
            var featuredVideos = <Video[]>(
              elements.docs.map((doc) => doc.data())
            );
            resolve(featuredVideos);
          });
    });
  }

  getFeaturedVideo(teacherId: string, videoId: string) {
    return new Promise<Video>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_FEATURED_CONTENT)
        .doc(videoId)
        .get()
        .subscribe((element) => {
          resolve(<Video>element.data());
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param discussion
   * @returns
   */
  addDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    discussion: Discussion
  ) {
    return new Promise<boolean>((resolve, reject) => {
      discussion.messageId = this.firestore.createId();
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(discussion.messageId)
        .set(discussion)
        .then(
          (res) => {
            resolve(true);
          },
          (err) => reject(err)
        );
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @param discussion
   * @returns
   */
  addSubDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string,
    discussion: Discussion
  ) {
    return new Promise<boolean>((resolve, reject) => {
      discussion.messageId = this.firestore.createId();
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .collection(DatabaseManager.DBPATH_SUB_DISCUSSIONS)
        .doc(discussion.messageId)
        .set(discussion)
        .then(
          (res) => {
            //updating the main discussion doc
            this.teacherRef
              .doc(teacherId)
              .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
              .doc(classId)
              .collection(DatabaseManager.DBPATH_CONTENT)
              .doc(videoId)
              .collection(DatabaseManager.DBPATH_DISCUSSIONS)
              .doc(messageId)
              .update({ replyAvailable: true })
              .then((out) => {
                resolve(true);
              });
          },
          (err) => reject(err)
        );
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @returns
   */
  getDiscussion(teacherId: string, classId: string, videoId: string) {
    return new Promise<Array<Discussion>>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .get()
        .subscribe((querySnapshot) => {
          let disc: Array<Discussion> = <Array<Discussion>>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          resolve(disc);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @returns
   */
  removeDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .delete()
        .then((out) => {
          resolve(true);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   */
  noDicussionReply(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .update({ replyAvailable: false });
    });
  }

  /**
   * Edit discussion
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @param message
   * @returns
   */
  editDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string,
    message: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .update({ message: message })
        .then((out) => {
          resolve(true);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param messageId
   * @returns
   */
  getSubDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    messageId: string
  ) {
    return new Promise<Array<Discussion>>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(messageId)
        .collection(DatabaseManager.DBPATH_SUB_DISCUSSIONS)
        .get()
        .subscribe((querySnapshot) => {
          let disc: Array<Discussion> = <Array<Discussion>>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          resolve(disc);
        });
    });
  }

  /**
   *
   * @param teacherId
   * @param classId
   * @param videoId
   * @param commentId
   * @param messageId
   * @returns
   */
  removeSubDiscussion(
    teacherId: string,
    classId: string,
    videoId: string,
    commentId: string,
    messageId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(classId)
        .collection(DatabaseManager.DBPATH_CONTENT)
        .doc(videoId)
        .collection(DatabaseManager.DBPATH_DISCUSSIONS)
        .doc(commentId)
        .collection(DatabaseManager.DBPATH_SUB_DISCUSSIONS)
        .doc(messageId)
        .delete()
        .then((out) => {
          resolve(true);
        });
    });
  }

  /**
   *
   * @param studentId
   * @param videoId
   * @param bookmark
   * @returns
   */
  public addBookmark(studentId: string, videoId: string, bookmark: Bookmark) {
    return new Promise<boolean>((resolve, reject) => {
      bookmark.bookmarkId = this.firestore.createId();
      this.studentRef
        .doc(studentId)
        .collection(this.DBPATH_BOOKMARKS)
        .doc(videoId)
        .collection(this.DBPATH_SAVED_BOOKMARKS)
        .doc(bookmark.bookmarkId)
        .set(bookmark)
        .then(
          (res) => {
            resolve(true);
          },
          (err) => reject(err)
        );
    });
  }

  /**
   *
   * @param studentId
   * @param videoId
   * @returns
   */
  public getBookmarks(studentId: string, videoId: string) {
    return new Promise<Array<Bookmark>>((resolve) => {
      this.studentRef
        .doc(studentId)
        .collection(this.DBPATH_BOOKMARKS)
        .doc(videoId)
        .collection(this.DBPATH_SAVED_BOOKMARKS)
        .get()
        .subscribe((querySnapshot) => {
          let disc: Array<Bookmark> = <Array<Bookmark>>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          resolve(disc);
        });
    });
  }

  /**
   *
   * @param studentId
   * @param videoId
   * @param bookmarkId
   * @returns
   */
  public removeBookmark(
    studentId: string,
    videoId: string,
    bookmarkId: string
  ) {
    return new Promise<boolean>((resolve) => {
      this.studentRef
        .doc(studentId)
        .collection(this.DBPATH_BOOKMARKS)
        .doc(videoId)
        .collection(this.DBPATH_SAVED_BOOKMARKS)
        .doc(bookmarkId)
        .delete()
        .then((res) => {
          resolve(true);
        });
    });
  }

  /**
   *
   * @param myVideo
   * @returns
   */
  getSuggestVideos(myVideo: Video) {
    return new Promise((resolve, reject) => {
      var collectionRef = this.teacherRef
        .doc(myVideo.teacherId)
        .collection(DatabaseManager.DBPATH_TEACHER_CLASS)
        .doc(myVideo.classId)
        .collection(DatabaseManager.DBPATH_CONTENT, (results) =>
          results
            .orderBy(VideoSearchParm.Timestamp, SearchOrder.Descending)
            .startAfter(myVideo.srhTimestamp)
            .limit(10)
        );
      collectionRef.get().subscribe((elements) => {
        let videos: Video[] = <Video[]>elements.docs.map((doc) => doc.data());
        resolve(videos);
      });
    });
  }

  //add postal charge

  addPostalTransaction(postalCharge: PostalCharge) {
    var postalId: string = this.firestore.createId();
    postalCharge.postalChargeId = postalCharge.documentId = postalId;
    this.firestore
      .collection(DatabaseManager.DBPATH_POSTAL_SERVICE)
      .doc(postalCharge.documentId)
      .set(postalCharge);
  }

  getAllConference(studentId: string) {
    let openconference: DigiConfernce[] = [];
    return new Promise((resolve, reject) => {
      this.getSubscribedTeachers(studentId).then((data: Array<any>) => {
        data.map((teacherId) => {
          this.firestore
            .collection(DatabaseManager.DBPATH_TEACHER)
            .doc(teacherId)
            .collection(DatabaseManager.DBPATH_CONFERENCE, (result) =>
              result
                .where('conferenceState', '>', 0)
                .where('conferenceState', '<', 2)
                .orderBy('conferenceState')
                .orderBy('day')
            )
            .get()
            .subscribe((docs) => {
              if (!docs.empty) {
                openconference = <Array<DigiConfernce>>(
                  docs.docs.map((doc) => doc.data())
                );
              }
            });
        });
        resolve(openconference);
      });
    });
  }

  getConferenceByTeacher(teacherId: string) {
    return new Promise<Array<DigiConfernce>>((resolve) => {
      this.firestore
        .collection(DatabaseManager.DBPATH_TEACHER)
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_CONFERENCE, (result) =>
          result
            .where('conferenceState', '>', 0)
            .where('conferenceState', '<', 2)
            .orderBy('conferenceState')
            .orderBy('day')
        )
        .get()
        .subscribe((docs) => {
          if (!docs.empty) {
            let openconference: Array<DigiConfernce> = <Array<DigiConfernce>>(
              docs.docs.map((doc) => doc.data())
            );
            resolve(openconference);
          }
        });
    });
  }
  getConference(teacherId: string, conferenceId: string) {
    return new Promise((resolve, reject) => {
      this.teacherRef
        .doc(teacherId)
        .collection(DatabaseManager.DBPATH_CONFERENCE)
        .doc(conferenceId)
        .get()
        .forEach((data) => resolve(data.data()))
        .catch((err) => reject(err));
    });
  }

  payForConference(paymentDetails: ConferencePayment) {
    return new Promise((resolve, reject) => {
      this.studentRef
        .doc(paymentDetails.studentId)
        .collection(DatabaseManager.DBPATH_CONFERENCE_PAYMENT)
        .doc(paymentDetails.conferenceId)
        .set(paymentDetails);
    });
  }

  isEnrolled(studentId: string, conferenceId) {
    return new Promise<ConferencePayment>((resolve, reject) => {
      this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_CONFERENCE_PAYMENT)
        .doc(conferenceId)
        .get()
        .subscribe((res) => resolve(res.data() as ConferencePayment));
    });
  }

  enrolledConference(studentId: string) {
    return new Promise((resolve, reject) => {
      let arr = [];
      this.studentRef
        .doc(studentId)
        .collection(DatabaseManager.DBPATH_CONFERENCE_PAYMENT)
        .get()
        .subscribe((querySnapshot) => {
          let disc: Array<Discussion> = <Array<Discussion>>(
            querySnapshot.docs.map((doc) => doc.data())
          );
          resolve(disc);
        });
    });
  }

  /**
   * Get payherepayment
   * @param studentId
   * @param payDocID
   * @param mode 1 if it for class, 2 for conference
   * @returns
   */
  getPayherePay(studentId: string, payDocID: string, mode: number) {
    return new Promise<PayhereClsVerification>((resolve) => {
      var path: string = '';
      if (mode === 1) {
        path = DatabaseManager.DBPATH_PAYHERE_CLASS;
      } else {
        path = DatabaseManager.DBPATH_PAYHERE_CONFERENCE;
      }
      this.firestore
        .collection(DatabaseManager.DBPATH_PAYHERE_PAYMENTS)
        .doc(studentId)
        .collection(path)
        .doc(payDocID)
        .get()
        .subscribe((querySnapshot) => {
          resolve(<PayhereClsVerification>querySnapshot.data());
        });
    });
  }
}
