<div class="container-fluid mt-2 p-0">
  <mat-tab-group animationDuration="0ms" #tabRef>
    <mat-tab label="Available">
      <div class="row m-0 mb-4" *ngIf="availableQuizList.length !== 0">
        <div
          class="col-12 col-md-6 px-2 px-sm-3 pt-4"
          *ngFor="let quiz of availableQuizList"
          (click)="openExamDialog(quiz.quizId)"
        >
          <div class="bg-white p-3 border rounded-lg">
            <div class="text-center">
              <h2 class="quiz-title">{{ quiz.quizTitle }}</h2>
            </div>
            <div class="row m-0" style="align-items: center" *ngIf="teacher">
              <img
                src="{{ teacher.profilePic }}"
                class="rounded-circle teacher-pic"
                alt=""
              />
              <span class="text-secondary teacher-name ml-4"
                ><b> {{ teacher.teacherName }} </b></span
              >
            </div>
            <div class="row m-0 text-secondary mt-4">
              <div class="offset-1 col-2 p-0">
                <span class="quiz-details">Subject:</span>
              </div>
              <div class="col-3 p-0">
                <span class="quiz-details">{{ quiz.subjectName }}</span>
              </div>
              <div class="offset-lg-1 col-3 p-0">
                <span class="quiz-details">Starting Time:</span>
              </div>
              <div class="col-3 col-lg-2 p-0 row m-0">
                <div class="quiz-details">{{ quiz.startDate }}</div>
                <div class="quiz-details">
                  &nbsp; {{ timeStr12hrs(quiz.startTime) }}
                </div>
              </div>
              <div class="offset-1 col-2 p-0 mt-lg-2">
                <span class="quiz-details">Quiz Type:</span>
              </div>
              <div class="col-3 p-0 mt-lg-2">
                <span class="quiz-details">{{ quiz.type }}</span>
              </div>
              <div class="offset-lg-1 col-3 p-0 mt-lg-2">
                <span class="quiz-details">Due Time:</span>
              </div>
              <div class="col-3 col-lg-2 p-0 row m-0 mt-lg-2">
                <div class="quiz-details">{{ quiz.endDate }}</div>
                <div class="quiz-details">
                  &nbsp; {{ timeStr12hrs(quiz.endTime) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row m-0 p-0 mt-5 text-center"
        *ngIf="availableQuizList.length === 0"
      >
        <ng-lottie
          class="lottie-container"
          style="margin-left: auto; margin-right: auto"
          [options]="option"
          width="100%"
          height="auto"
        >
        </ng-lottie>
        <div class="col-12 mt-4">
          <h2>Quizes not Available...</h2>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Completed">
      <div class="row m-0 mb-4" *ngIf="completedQuizList.length !== 0">
        <div
          class="p-0 col-12 col-md-6 px-2 px-sm-3 pt-4"
          *ngFor="let quiz of completedQuizList"
        >
          <div class="bg-white border p-3 rounded-lg">
            <div class="text-center">
              <h2 class="quiz-title">{{ quiz.quizTitle }}</h2>
            </div>
            <div class="row m-0" style="align-items: center" *ngIf="teacher">
              <img
                src="{{ teacher.profilePic }}"
                class="rounded-circle teacher-pic"
                alt=""
              />
              <span class="text-secondary teacher-name ml-4"
                ><b> {{ teacher.teacherName }} </b></span
              >
            </div>
            <div class="row m-0 mt-3" style="align-items: center">
              <div
                class="offset-1 rounded-pill py-1 px-3 text-white"
                [class.bg-danger]="!quiz.isEvaluated"
                [class.bg-success]="quiz.isEvaluated"
              >
                <span>{{
                  quiz.isEvaluated ? "Evaluated" : "Not Submitted"
                }}</span>
              </div>
              <div class="ml-4" *ngIf="quiz.marks !== ''">
                <span>Marks: {{ quiz.marks }}</span>
              </div>
            </div>
            <div class="row m-0 text-secondary mt-4">
              <div class="offset-1 col-2 p-0">
                <span class="quiz-details">Subject:</span>
              </div>
              <div class="col-3 p-0">
                <span class="quiz-details">{{ quiz.subjectName }}</span>
              </div>
              <div class="offset-lg-1 col-3 p-0">
                <span class="quiz-details">Starting Date:</span>
              </div>
              <div class="col-3 col-lg-2 p-0">
                <span class="quiz-details">{{ quiz.startDate }}</span>
              </div>
              <div class="offset-1 col-2 p-0 mt-lg-2">
                <span class="quiz-details">Quiz Type:</span>
              </div>
              <div class="col-3 p-0 mt-lg-2">
                <span class="quiz-details">{{ quiz.type }}</span>
              </div>
              <div class="offset-lg-1 col-3 p-0 mt-lg-2">
                <span class="quiz-details">Due Date:</span>
              </div>
              <div class="col-3 col-lg-2 p-0 mt-lg-2">
                <span class="quiz-details">{{ quiz.endDate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="center container-fluid mt-4" *ngIf="false">
          <div class="row m-0" style="align-items: center">
            <mat-button
              class="px-3 py-2 rounded pointer"
              (click)="prevCompleteQuizes()"
              [disabled]="!previousButton"
              [ngClass]="previousButton ? 'btn-purple' : 'btn-purple-disabled'"
            >
              Previous</mat-button
            >
            <button
              class="py-2 rounded ml-4 pointer text-center"
              style="padding: 2% 22.33px"
              [disabled]="!nextButton"
              (click)="nextCompleteQuizes()"
              [ngClass]="nextButton ? 'btn-purple' : 'btn-purple-disabled'"
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div
        class="row m-0 p-0 mt-5 text-center"
        *ngIf="completedQuizList.length === 0"
      >
        <ng-lottie
          class="lottie-container"
          style="margin-left: auto; margin-right: auto"
          [options]="option"
          width="100%"
          height="auto"
        >
        </ng-lottie>
        <div class="col-12 mt-4">
          <h2>Quizes not Completed...</h2>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
