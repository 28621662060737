<div class="m-0 p-0" style="height: 100%;width: 100vw; overflow-y: auto;" id="subjectPane" #subjectPane>
  <header class="col-12 row m-0 p-0 py-0 py-lg-2" style="align-items: center;">
    <div class="col-1 m-0 p-0 tablet-menu-icon">
      <div class="container-fluid text-center">
        <span class="material-icons pointer" (click)="hideMenu()">menu</span>
      </div>
    </div>
    <div class="col-md-2 col-2 m-0 p-0 tablet-menu-icon" style="align-items: center;">
      <img class="ml-3 smallLogo pointer" src="../assets/images/Logo.png" alt="digi panthiya" routerLink="/">
      <img class="ml-3 largeLogo pointer" src="../assets/images/tempLOGO.jpg" alt="digi panthiya" routerLink="/">
    </div>
    <div class="col-lg-3 m-0 p-0 desktop-menu-icon pointer" routerLink="/">
      <div class="row m-0 p-0" style="align-items: center;">
        <span class="ml-4 material-icons">menu</span>
        <img class="ml-3 largeLogo" src="../assets/images/tempLOGO.jpg" alt="digi panthiya">
      </div>
    </div>
    <div class="p-0 offset-lg-1 col-md-7 col-lg-6 col-6">
      <div class="row m-0" style="align-items: center;" id="searchContainer">
        <input type="text" class="col-sm-9 col-9 m-0 p-0 px-2 search-bar ml-2 ml-sm-0" placeholder="Search"
          (keydown.enter)="setSearchKeyword()" [(ngModel)]="keyword">
        <span class="material-icons px-0 px-sm-3 px-md-4 search-btn pointer text-center"
          (click)="setSearchKeyword()">search</span>
      </div>
    </div>
    <div class="col-lg-2 col-md-2 col-3 p-0 pr-sm-2 pr-md-0">
      <ng-container *ngIf="authservice.loginState !== 0 && authservice.userData as user">
        <div class="row m-0 p-0" style="align-items: center;">
          <span class="material-icons  pointer" style="margin-left: auto;margin-right: 5%;font-size: 1.6em;"
            [style.color]="bellIconCol" [matMenuTriggerFor]="notificationMsg" (menuOpened)="playAnimation()"
            (menuClosed)="pauseAnimation()">notifications</span>
          <div class="dropleft pr-2">
            <img class="pointer" [src]="profileValidator.getUserProfilePic()" style="width: 30px; border-radius: 50px;"
              id="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <div class="dropdown-menu" style="width: 250px;" aria-labelledby="dropdown">
              <div class="container-fluid p-0 pointer">
                <div (click)="changeNav()" [class.light-clicked]="navitem == 2" routerLink="/my-account"
                  class="row m-0 pl-1 pb-2 border-bottom" style="align-items: center;">
                  <img class="pointer" [src]="profileValidator.getUserProfilePic()"
                    style="width: 50px; border-radius: 100px; margin-right: 5px;">
                  <span *ngIf="user.displayName!==null">Hi {{getFirstName(user.displayName)}}!</span>
                  <span *ngIf="user.displayName===null">{{user.email}}</span>
                </div>
                <!-- <div class="row py-2" [ngClass]="isDark()" style="align-items: center;">
                  <span class="material-icons mr-3">brightness_medium</span>
                  <span style="font-size: 0.8em;">Dark theme: {{oncheck()}}</span>
                  <mat-slide-toggle [(ngModel)]="isChecked" style="margin-left: auto;margin-right: 0%;">
                  </mat-slide-toggle>
                </div>
                <div class="row py-2 border-bottom" style="align-items: center;">
                  <span class="material-icons mr-3">translate</span>
                  <span style="font-size: 0.8em;">Language: English</span>
                  <span class="material-icons" style="margin-left: auto;margin-right: 0%;">navigate_next</span>
                </div> -->
                <div class="row m-0 pt-3 pb-1" style="align-items: center;justify-content: center;"
                  (click)="authservice.SignOut()" routerLink="/home">
                  <span class="material-icons">exit_to_app</span>
                  <span class="ml-2" style="font-size: 0.8em;">Sign out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="authservice.loginState === 0">
        <div class="row m-0 p-0" style="align-items: center;">
          <!-- <div class="sub-menu" style="margin-left: auto;margin-right: 0%;">
            <span class="material-icons text-secondary pointer" id="dropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">more_vert</span>
            <div class="dropdown-menu dropdown-menu-right col-12 p-1 p-md-3" style="width: 250px;"
              aria-labelledby="dropdownMenuButton">
              <div class="container-fluid">
                <div class="row" [ngClass]="isDark()" style="align-items: center;">
                  <span class="material-icons mr-1 mr-md-3">brightness_medium</span>
                  <span style="font-size: 0.8em;">Dark theme: {{oncheck()}}</span>
                  <mat-slide-toggle [(ngModel)]="isChecked" style="margin-left: auto;margin-right: 0%;">
                  </mat-slide-toggle>
                </div>
                <div class="row mt-3" style="align-items: center;">
                  <span class="material-icons mr-1 mr-md-3">translate</span>
                  <span style="font-size: 0.8em;">Language: English</span>
                  <span class="material-icons" style="margin-left: auto;margin-right: 0%;">navigate_next</span>
                </div>
              </div>
            </div>
          </div> -->

          <span class="btn-purple text-white px-2 py-1 px-sm-3 rounded" (click)="openTutor()"
            style="cursor: pointer; font-size: .7em;font-weight: 500;margin-left: auto;margin-right: 0%;">TUTOR</span>
          <!-- <div class="user-main p-0 rounded text-center pointer px-2 py-1" style="margin-left: auto;margin-right: 5%;"
            (click)="openDialog()">
            <span class="material-icons" style="font-size: 2em;">account_circle</span>
            <span class="ml-2" style="font-weight: 500;">SIGN IN</span>
          </div> -->
          <span class="material-icons user-main-mobile mr-sm-3 ml-sm-3 ml-1" style="cursor: pointer;"
            (click)="openDialog()">account_circle</span>
        </div>
      </ng-container>
    </div>
  </header>
  <div class="row m-0 p-0">
    <div class="col-lg-2 p-0 desktop-menu">
      <ul class="border-bottom pb-3">
        <li (click)="changeNav()" [class.light-clicked]="navitem == 1" routerLink="/">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons">home</span>
            <span class="ml-3" style="font-weight: 500;">Home</span>
          </div>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()" [class.light-clicked]="navitem == 2"
          routerLink="/my-account">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons">person</span>
            <span class="ml-3" style="font-weight: 500;">My Account</span>
          </div>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()" [class.light-clicked]="navitem == 3"
          routerLink="/live-classes">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">live_tv</span>
            <span class="ml-3" style="font-weight: 500;">Live Classes</span>
          </div>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()" [class.light-clicked]="navitem == 4"
          routerLink="/my-classes">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">school</span>
            <span class="ml-3" style="font-weight: 500;">My Classes</span>
          </div>
        </li>
      </ul>
      <ul *ngIf="authservice.loginState === 2" class="border-bottom pb-3">
        <li (click)="changeNav()" [class.light-clicked]="navitem == 5" routerLink="/my-notes">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">edit_note</span>
            <span class="ml-3" style="font-weight: 500;">Your Notes</span>
          </div>
        </li>
        <li (click)="changeNav()" [class.light-clicked]="navitem == 6" routerLink="/exam">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">fact_check</span>
            <span class="ml-3" style="font-weight: 500;">Mock Exam</span>
          </div>
        </li>
        <li (click)="changeNav()" [class.light-clicked]="navitem == 8" routerLink="/notices">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">campaign</span>
            <span class="ml-3" style="font-weight: 500;">Notices</span>
          </div>
        </li>
        <li (click)="changeNav()" [class.light-clicked]="navitem == 7" routerLink="/digi-conference">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">videocam</span>
            <span class="ml-3" style="font-weight: 500;">Digi Conference</span>
          </div>
        </li>
      </ul>
      <ul class="border-bottom pb-3">
        <li (click)="changeNav()" [class.light-clicked]="navitem == 10" routerLink="/contact">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">support_agent</span>
            <span class="ml-3" style="font-weight: 500;">Contact Us</span>
          </div>
        </li>
        <li (click)="changeNav()" [class.light-clicked]="navitem == 11" routerLink="/faq">
          <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
            <span class="material-icons ">contact_support</span>
            <span class="ml-3" style="font-weight: 500;">FAQ</span>
          </div>
        </li>
      </ul>
      <ng-container *ngIf="authservice.loginState !== 2">
        <div class="pl-4 pb-3 border-bottom">
          <p style="font-size: 1em;font-weight: 500;color: #525252;">
            Sign in to like videos,<br>
            comment, and subscribe
          </p>
          <div class="user-main p-0 rounded text-center pointer px-2 py-1" (click)="openDialog()">
            <span class="material-icons" style="font-size: 2em;">account_circle</span>
            <span class="ml-2" style="font-weight: 500;">SIGN IN</span>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-1 p-0 tablet-menu">
      <ul class="container-fluid m-0 p-0 pb-3">
        <li (click)="changeNav()" class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 1" routerLink="/">
          <span class="material-icons">home</span>
          <span class="tab-nav-item mt-1">Home</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 2" routerLink="/my-account">
          <span class="material-icons">person</span>
          <span class="tab-nav-item mt-1">My Account</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 3" routerLink="/live-classes">
          <span class="material-icons">live_tv</span>
          <span class="tab-nav-item mt-1">Live Classes</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 4" routerLink="/my-classes">
          <span class="material-icons">school</span>
          <span class="tab-nav-item mt-1">My Classes</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 5" routerLink="/my-notes">
          <span class="material-icons">edit_note</span>
          <span class="tab-nav-item mt-1">Your Notes</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 6" routerLink="/exam">
          <span class="material-icons">fact_check</span>
          <span class="tab-nav-item mt-1">Mock Exams</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 8" routerLink="/notices">
          <span class="material-icons">campaign</span>
          <span class="tab-nav-item mt-1">Notices</span>
        </li>
        <li *ngIf="authservice.loginState === 2" (click)="changeNav()"
          class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 7" routerLink="/digi-conference">
          <span class="material-icons">videocam</span>
          <span class="tab-nav-item mt-1">Digi Conference</span>
        </li>
        <li (click)="changeNav()" class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 10" routerLink="/contact">
          <span class="material-icons">support_agent</span>
          <span class="tab-nav-item mt-1">Contact Us</span>
        </li>
        <li (click)="changeNav()" class="m-0 p-0 py-lg-3 py-sm-2 text-center pointer" style="display: grid;"
          [class.light-clicked]="navitem == 11" routerLink="/faq">
          <span class="material-icons">contact_support</span>
          <span class="tab-nav-item mt-1">FAQ</span>
        </li>
      </ul>
    </div>
    <div class="col-xl-10 col-sm-11 col-12 bg-light" style="min-height: 92vh;">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="slide-menu p-0" [style.display]="isMenuHidden ? 'inherit':'none'">
    <div class="row m-0 p-0 py-2" style="align-items: center;">
      <span class="ml-3 material-icons pointer" (click)="hideMenu()">menu</span>
      <img class="ml-3" src="../assets/images/tempLOGO.jpg" alt="digi panthiya" style="width: 130px;padding: 3px 0;">
    </div>
    <ul class="border-bottom pb-3">
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 1" routerLink="/">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons">home</span>
          <span class="ml-3" style="font-weight: 500;">Home</span>
        </div>
      </li>
      <li *ngIf="authservice.loginState === 2" (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 2"
        routerLink="/my-account">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons">person</span>
          <span class="ml-3" style="font-weight: 500;">My Account</span>
        </div>
      </li>
      <li *ngIf="authservice.loginState === 2" (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 3"
        routerLink="/live-classes">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">live_tv</span>
          <span class="ml-3" style="font-weight: 500;">Live Classes</span>
        </div>
      </li>
      <li *ngIf="authservice.loginState === 2" (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 4"
        routerLink="/my-classes">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">school</span>
          <span class="ml-3" style="font-weight: 500;">My Classes</span>
        </div>
      </li>
    </ul>
    <ul *ngIf="authservice.loginState === 2" class="border-bottom pb-3">
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 5" routerLink="/my-notes">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">edit_note</span>
          <span class="ml-3" style="font-weight: 500;">Your Notes</span>
        </div>
      </li>
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 6" routerLink="/exam">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">fact_check</span>
          <span class="ml-3" style="font-weight: 500;">Mock Exam</span>
        </div>
      </li>
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 8" routerLink="/notices">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">campaign</span>
          <span class="ml-3" style="font-weight: 500;">Notices</span>
        </div>
      </li>
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 7"  routerLink="/digi-conference">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">videocam</span>
          <span class="ml-3" style="font-weight: 500;">Digi Conference</span>
        </div>
      </li>
    </ul>
    <ul class="border-bottom pb-3">
      <li hidden (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 9" routerLink="/open-discussion">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">groups</span>
          <span class="ml-3" style="font-weight: 500;">Open Discussion Group</span>
        </div>
      </li>
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 10" routerLink="/contact">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">support_agent</span>
          <span class="ml-3" style="font-weight: 500;">Contact Us</span>
        </div>
      </li>
      <li (click)="changeNav();hideMenu()" [class.light-clicked]="navitem == 11" routerLink="/faq">
        <div class="row m-0 p-0 py-1 pointer" style="align-items: center;">
          <span class="material-icons ">contact_support</span>
          <span class="ml-3" style="font-weight: 500;">FAQ</span>
        </div>
      </li>
    </ul>
    <ng-container *ngIf="authservice.loginState !== 2">
      <div class="pl-4 pb-3 border-bottom">
        <p style="font-size: 1em;font-weight: 500;color: #525252;">
          Sign in to like videos,<br>
          comment, and subscribe
        </p>
        <div class="user-main rounded pointer" (click)="openDialog()">
          <span class="material-icons">account_circle</span>
          <span>SIGN IN</span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!--menu-->
<mat-menu #notificationMsg="matMenu">
  <div style="width: 250px; min-height: 50px">
    <p class="center" style="padding: 8px; font-size: 14px;" *ngIf="message!=='';else nomsg">{{message}}</p>
    <ng-template #nomsg>
      <ng-lottie class="center" (animationCreated)="animationCreated($event)" (loopComplete)="pauseAnimation()"
        style="margin-left: auto;margin-right: auto;color: red;" [options]="animOption" width="150px" height="auto">
      </ng-lottie>
      <span class="center" style="font-size: 14px; color: gray;">You don't have any notification!</span>
    </ng-template>
  </div>
</mat-menu>
<!--menu-->