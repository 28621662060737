import { Injectable, NgZone } from '@angular/core';
import { User, LoginState } from '../services/user';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseManager } from '../database/database-manager';
import { Student } from '../models/student';
import { AlertService } from '../services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //constants
  private readonly WRONG_PASSWORD = 'auth/wrong-password';
  private readonly INVALID_EMAIL = 'auth/invalid-email';
  private readonly NOT_REGISTERD = 'auth/user-not-found';

  userData: any = null; // Save logged in user data
  processSucceed: boolean = true;
  authMessage: string;

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    //public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    public dialog: MatDialog, //mat dialog to display extra messages
    private dataservice: DatabaseManager, //database manager
    private alertService: AlertService //alert service
  ) {
    this.initilizeUser();
    this.checkParms();
  }

  //initialize user
  initilizeUser() {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  private checkParms() {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the action to complete.
    var mode = urlParams.get('mode');
    // Get the one-time code from the query parameter.
    var actionCode = urlParams.get('oobCode');
    // (Optional) Get the continue URL from the query parameter if available.
    var continueUrl = urlParams.get('continueUrl');
    // (Optional) Get the language code if available.
    var lang = urlParams.get('lang') || 'en';

    // Handle the user management action.
    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        this.handleResetPassword(actionCode, continueUrl, lang);
        break;
      case 'recoverEmail':
        // Display email recovery handler and UI.
        this.handleRecoverEmail(actionCode, lang);
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.handleVerifyEmail(actionCode, continueUrl, lang);
        break;
      default:
      // Error: invalid mode.
    }
  }

  private handleVerifyEmail(actionCode, continueUrl, lang) {
    // Try to apply the email verification code.
    this.afAuth.auth
      .applyActionCode(actionCode)
      .then((resp) => {
        // Email address has been verified.
        this.alertService.showAlert(
          "Thank you for verifying your email address. Please visit 'My Account' and complete your profile.",
          MessageButtons.ok,
          MessageType.accountCreated
        );
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        if (this.loginState === LoginState.notVerified) {
          this.alertService
            .showAlert(
              'It seems like your verification link has been expired/invalid.',
              MessageButtons.resend_verification_email,
              MessageType.accessBlocked
            )
            .afterClosed()
            .subscribe((result: DialogResult) => {
              switch (result) {
                case DialogResult.resend_verification_email:
                  this.SendVerificationMail();
                  break;
              }
            });
        }
      });
  }

  private handleRecoverEmail(actionCode, lang) {
    this.afAuth.auth
      .applyActionCode(actionCode)
      .then((resp) => {
        this.alertService.showAlert(
          'A recovery email has been successfully sent to your email.',
          MessageButtons.ok,
          MessageType.wow
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  private handleResetPassword(actionCode, continueUrl, lang) {}

  //return user availability
  isUserAvailable() {
    return new Promise<boolean>((resolve) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          this.userData = user;
          resolve(true);
        } else {
        }
      });
    });
  }

  // Sign in with email/password
  SignIn(email, password) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          //alert("logged In");
        });
      })
      .catch((error) => {
        this.generateError(error.code);
        this.processSucceed = false;
      });
  }

  // Sign up with email/password
  SignUp(email, password) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign 
        up and returns promise */
        this.SendVerificationMail();

        //add new student to the system
        var newUser: Student = {
          studentId: result.user.uid,
          email: result.user.email,
          firstName: '',
          lastName: '',
          profilePic: 'assets/images/noprofile.png',
          nic: '',
          studentTp1: '',
          studentTp2: '',
          studentAddress: '',
          city: '',
          province: '--Select--',
          postalCode: '',
          country: '',
        };
        this.dataservice.addNewStudent(newUser);
      })
      .catch((error) => {
        // window.alert(error.message)
        this.authMessage = error.message;
        this.processSucceed = false;
      });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then((res) => {
      // console.log(res);
      //this.router.navigate(['verify-email-address']);
    });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then((res) => {
        // console.log(res);

        this.processSucceed = true;
        this.authMessage = 'Password reset email sent, check your inbox.';
      })
      .catch((error) => {
        this.generateError(error.code);
        this.processSucceed = false;
      });
  }

  resetPassword(code: string, password: string) {
    this.clearAuthMessage();
    return this.afAuth.auth
      .confirmPasswordReset(code, password)
      .then((res) => {
        this.alertService.showAlert(
          'Successfully Changed Your Password',
          MessageButtons.ok,
          MessageType.wow
        );
      })
      .catch((error) => {
        console.log(error.code);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null &&
      (user.emailVerified !== false ||
        user.providerData[0].providerId === 'facebook.com')
      ? true
      : false;
  }

  // Return login state by checking user data with verification
  get loginState(): any {
    const user = JSON.parse(localStorage.getItem('user'));
    if (
      user !== null &&
      (user.emailVerified !== false ||
        user.providerData[0].providerId === 'facebook.com')
    )
      return LoginState.VerfiedLogin;
    else if (user !== null) return LoginState.notVerified;
    else return LoginState.notLoggedIn;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Sign in with Facebook
  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          // this.router.navigate(['dashboard']);
        });

        //check whether this user is a new member
        this.dataservice
          .isStudentAvailable(result.user.uid)
          .then((output: boolean) => {
            if (!output) {
              //add new student to the system
              var newUser: Student = {
                studentId: result.user.uid,
                email: result.user.email,
                firstName: '',
                lastName: '',
                profilePic: 'assets/images/noprofile.png',
                nic: '',
                studentTp1: '',
                studentTp2: '',
                studentAddress: '',
                city: '',
                province: '--Select--',
                postalCode: '',
                country: '',
              };
              this.dataservice.addNewStudent(newUser);
            }
          });
      })
      .catch((error) => {
        window.alert(error);
        console.log(error);
      });
  }

  updateDisplayName(name: string) {
    this.afAuth.auth.currentUser
      .updateProfile({
        displayName: name,
      })
      .then(() => {
        //console.log('DisplayName updated')
      });
  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      //this.router.navigate(['sign-in']);
      // alert("go");
      this.userData = null;
    });
  }

  //generate Error message from the service
  private generateError(code: string) {
    if (code == this.WRONG_PASSWORD) {
      this.authMessage = 'Incorrect password. Please try again!';
    } else if (code == this.INVALID_EMAIL) {
      this.authMessage = 'The email you entered is incorrect.';
    } else if (code == this.NOT_REGISTERD) {
      this.authMessage = 'You are not registered. Please Sign Up now!';
    }
  }

  /**
   * Clerar authMessage
   */
  public clearAuthMessage() {
    this.authMessage = '';
    this.processSucceed = false;
  }
}
