import { Injectable } from '@angular/core';
import { DatabaseManager } from '../database/database-manager';
import { Payment } from '../models/payment';

export interface AccessToken {
  classId: string;
  year: number;
  month: number;
  week1: number;
  week2: number;
  week3: number;
  week4: number;
  postalFee: number;
  postalFeePaid: boolean;
  lastDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class PaymentValidator {
  //private vr
  private tokens: Array<AccessToken> = new Array<AccessToken>();

  constructor(private databaseManager: DatabaseManager) {}

  public whichWeek(day: Date): number {
    var myDay = day.getDate();
    if (myDay >= 1 && myDay <= 7) return 0;
    if (myDay >= 8 && myDay <= 14) return 1;
    if (myDay >= 15 && myDay <= 21) return 2;
    if (myDay >= 22 && myDay <= 31) return 3;
  }

  // private checkAccess(studentId: string, classId: string, date: Date): void {
  //     //console.log('call');
  //     this.databaseManager.getPayments(studentId, classId, date.getFullYear(), date.getMonth() + 1).then(payments => {
  //         console.log(payments);
  //         var myToken: AccessToken = {
  //             classId: classId,
  //             year: date.getFullYear(),
  //             month: date.getMonth() + 1,
  //             week1: 0,
  //             week2: 0,
  //             week3: 0,
  //             week4: 0,
  //             postalFee: 0,
  //             lastDate: '',
  //         }
  //         payments.forEach(element => {
  //             if (element.week4 === 1)
  //                 myToken.week4 = 1;
  //             if (element.week3 === 1)
  //                 myToken.week3 = 1;
  //             if (element.week2 === 1)
  //                 myToken.week2 = 1;
  //             if (element.week1 === 1)
  //                 myToken.week1 = 1;
  //             myToken.postalFee = element.postalFee;
  //             myToken.lastDate = element.purchaseDate;
  //         });
  //         this.tokens.push(myToken);
  //     });
  // }

  // public validateAccess(studentId: string, classId: string, date: Date): boolean {
  //     var month = date.getMonth() + 1;
  //     var year = date.getFullYear();

  //     var week = this.whichWeek(date);
  //     var token: Array<AccessToken> = new Array<AccessToken>();
  //     this.tokens.forEach(element => {
  //         if (element !== null)
  //             if ((element.classId === classId) && (element.year === year) && (element.month === month)) {
  //                 token.push(element);
  //             }
  //     });
  //     if ((token !== undefined) && (token.length !== 0))
  //         switch (week) {
  //             case 1:
  //                 if (token[0].week1 === 1)
  //                     return true;
  //                 else if (token[0].week1 === 0)
  //                     return false;
  //                 else
  //                     this.checkAccess(studentId, classId, date);
  //                 break;
  //             case 2:
  //                 if (token[0].week2 === 1)
  //                     return true;
  //                 else if (token[0].week2 === 0)
  //                     return false;
  //                 else
  //                     this.checkAccess(studentId, classId, date);
  //                 break;
  //             case 3:
  //                 if (token[0].week3 === 1)
  //                     return true;
  //                 else if (token[0].week3 === 0)
  //                     return false;
  //                 else
  //                     this.checkAccess(studentId, classId, date);
  //                 break;
  //             case 4:
  //                 if (token[0].week4 === 1)
  //                     return true;
  //                 else if (token[0].week4 === 0)
  //                     return false;
  //                 else
  //                     this.checkAccess(studentId, classId, date);
  //                 break;
  //         }
  //     else
  //         this.checkAccess(studentId, classId, date);
  //     return false;
  // }

  /**
   * Send false if video is not valid
   * @param studentId
   * @param classId
   * @param date
   * @returns
   */
  public validateAccess(studentId: string, classId: string, date: Date) {
    return new Promise<boolean>(async (resolve) => {
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var week = this.whichWeek(date);
      var token: Array<AccessToken> = new Array<AccessToken>();

      this.tokens.forEach((element) => {
        if (element !== null)
          if (
            element.classId === classId &&
            element.year === year &&
            element.month === month
          ) {
            token.push(element);
          }
      });
      if (token.length === 0)
        await this.checkAccess(studentId, classId, date).then((myToken) => {
          switch (week) {
            case 1:
              resolve(myToken.week1 === 1);
              break;
            case 2:
              resolve(myToken.week2 === 1);
              break;
            case 3:
              resolve(myToken.week3 === 1);
              break;
            case 4:
              resolve(myToken.week4 === 1);
              break;
          }
        });
      else
        switch (week) {
          case 1:
            resolve(token[0].week1 === 1);
            break;
          case 2:
            resolve(token[0].week2 === 1);
            break;
          case 3:
            resolve(token[0].week3 === 1);
            break;
          case 4:
            resolve(token[0].week4 === 1);
            break;
        }
    });
  }

  public updatePaymentInfo(payment: Payment) {
    this.tokens.forEach((token) => {
      if (
        token.classId === payment.classId &&
        token.year === payment.year &&
        token.month === payment.month
      ) {
        if (payment.week4 === 1) token.week4 = 1;
        if (payment.week3 === 1) token.week3 = 1;
        if (payment.week2 === 1) token.week2 = 1;
        if (payment.week1 === 1) token.week1 = 1;
      }
    });
  }

  public getPaidWeeksServices(studentId: string, classId: string, date: Date) {
    return new Promise<AccessToken>((resolve) => {
      this.databaseManager
        .getPayments(
          studentId,
          classId,
          date.getFullYear(),
          date.getMonth() + 1
        )
        .then((payments) => {
          var myToken: AccessToken = {
            classId: classId,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            week1: 0,
            week2: 0,
            week3: 0,
            week4: 0,
            postalFee: 0.0,
            postalFeePaid: false,
            lastDate: '',
          };
          payments.forEach((element) => {
            if (element.week4 === 1) myToken.week4 = 1;
            if (element.week3 === 1) myToken.week3 = 1;
            if (element.week2 === 1) myToken.week2 = 1;
            if (element.week1 === 1) myToken.week1 = 1;
            if (element.postalService) myToken.postalFeePaid = true;
            myToken.postalFee = element.postalFee;
            myToken.lastDate = element.purchaseDate;
          });
          resolve(myToken);
        });
    });
  }

  // public getToken(studentId: string, classId: string, date: Date): AccessToken {
  //     var tkn: AccessToken = null;
  //     var month = date.getMonth() + 1;
  //     var year = date.getFullYear();
  //     var week = this.whichWeek(date);
  //     // var token: Array<AccessToken> = new Array<AccessToken>();
  //     this.tokens.forEach(element => {
  //         if (element !== null) {
  //             //console.log(element);
  //             // console.log(classId + " " + year + " " + month);
  //             if ((element.classId === classId) && (element.year === year) && (element.month === month)) {
  //                 tkn = element;
  //                 return tkn;
  //             }
  //         }
  //         else
  //             this.checkAccess(studentId, classId, date);
  //     });
  //     if (tkn === null)
  //         this.checkAccess(studentId, classId, date);
  //     return tkn;
  // }

  public getToken(studentId: string, classId: string, date: Date) {
    return new Promise<AccessToken>(async (resolve, reject) => {
      var tkn: AccessToken = null;
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      this.tokens.forEach((element) => {
        if (element !== null) {
          if (
            element.classId === classId &&
            element.year === year &&
            element.month === month
          ) {
            tkn = element;
            resolve(tkn);
          }
        }
      });
      if (tkn === null)
        await this.checkAccess(studentId, classId, date).then((res) => {
          resolve(res);
        });
      return tkn;
    });
  }

  private checkAccess(studentId: string, classId: string, date: Date) {
    return new Promise<AccessToken>(async (resolve, reject) => {
      this.databaseManager
        .getPayments(
          studentId,
          classId,
          date.getFullYear(),
          date.getMonth() + 1
        )
        .then((payments) => {
          var myToken: AccessToken = {
            classId: classId,
            year: date.getFullYear(),
            month: date.getMonth() + 1,
            week1: 0,
            week2: 0,
            week3: 0,
            week4: 0,
            postalFee: 0,
            postalFeePaid: false,
            lastDate: '',
          };
          payments.forEach((element) => {
            if (element.week4 === 1) myToken.week4 = 1;
            if (element.week3 === 1) myToken.week3 = 1;
            if (element.week2 === 1) myToken.week2 = 1;
            if (element.week1 === 1) myToken.week1 = 1;
            if (element.postalService) myToken.postalFeePaid = true;
            myToken.postalFee = element.postalFee;
            myToken.lastDate = element.purchaseDate;
          });
          this.tokens.push(myToken);
          resolve(myToken);
        });
    });
  }
}
