<div class="text-center main-container px-3 py-2 bg-white">
    <div class="row">
        <span mat-dialog-close class="material-icons"
            style="margin-left: auto;margin-right: 2%;font-size: 1.2em;">close</span>
    </div>
    <div [ngSwitch]="registerType">
        <div *ngSwitchCase="'signup'">
            <div *ngIf="authService.loginState === 0">
                <img src="../../assets/images/Logo.png" alt="" width="100">
                <h1><b>Sign up</b></h1>
                <div class="rounded" style="border: solid 0.5px dodgerblue;text-align: left;">
                    <label for="email" class="text-primary bg-white"
                        style="position: absolute;;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">Email</label>
                    <input #emailRegister type="email" class="form-control" id="email"
                        style="border: none;outline: none;" (keydown.enter)="passwordRegister.focus()">
                </div>
                <div class="rounded mt-4" style="border: solid 0.5px dodgerblue;text-align: left;">
                    <label for="password" class="text-primary bg-white"
                        style="position: absolute;;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">Password</label>
                    <input #passwordRegister type="password" class="form-control" id="password"
                        style="border: none;outline: none;"
                        (keydown.enter)="authService.SignUp(emailRegister.value, passwordRegister.value)">
                </div>
                <div style="text-align: left;">
                    <div class="row p-0 m-0">
                        <span class="mt-2" *ngIf="!authService.processSucceed"
                            style="color: red;font-size: .8em;font-weight: 500;margin-left: auto;margin-right: 0;margin-top: 10px;">{{authService.authMessage}}</span>
                    </div>
                    <div class="row p-0 m-0">
                        <button class="btn btn-primary" style="margin-left: auto;margin-right: 0;"
                            (click)="authService.SignUp(emailRegister.value, passwordRegister.value)">Sign up</button>
                    </div>
                </div>
                <h2 class="text-primary mt-4" style="font-weight: 400;">Or continue with</h2>
                <button class="btn btn-dark mb-5 text-white" (click)="loginWithGoogle()"><img
                        src="../../assets/images/google.svg" alt="" width="30">&nbsp;&nbsp; Google</button>
                <div class="text-center">
                    <span class="pointer" (click)="changeRegType('signin')">Already a member? <u>Sign in now!</u></span>
                </div>
            </div>

            <div *ngIf="authService.loginState === 1 && authService.userData as user">
                <br><br>
                <h1>Confirm Your Email</h1>
                <p class="mx-4" style="font-size: 1.2em;">We have sent a confirmation email to
                    <strong>{{user.email}}</strong>.
                </p>
                <p class="mx-4">Please check your email and click on the link to verfiy your email address.</p>

                <!-- Calling SendVerificationMail() method using authService Api -->
                <div class="row p-0 m-0">
                    <button class="btn btn-primary" style="margin-left: auto;margin-right: auto;"
                        (click)="authService.SendVerificationMail()">Resend Verification Email</button>
                </div>
            </div>
        </div>
        <div *ngSwitchCase="'fp'">
            <img src="../../assets/images/Logo.png" alt="" width="100">
            <h1><b>Forgot Password</b></h1>
            <div class="my-4">
                <span>Enter your account email address to get a varification email</span>
            </div>
            <div class="rounded" style="border: solid 0.5px dodgerblue;text-align: left;">
                <label for="email" class="text-primary bg-white"
                    style="position: absolute;;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">Email</label>
                <input #emailFP type="email" class="form-control" style="border: none;outline: none;">
            </div>
            <div class="mt-3" style="text-align: left;">
                <div class="row p-0 m-0" *ngIf="authService.authMessage">
                    <span
                        style="color: red;font-size: .8em;font-weight: 500;margin-left: auto;margin-right: 0;margin-top: 5px;">{{authService.authMessage}}</span>
                </div>
                <div class="row p-0 m-0">
                    <button class="btn btn-primary" style="margin-left: auto;margin-right: 0;"
                        (click)="authService.ForgotPassword(emailFP.value)">Get Email</button>
                </div>
            </div>
            <div class="text-center mt-4">
                <span class="pointer" (click)="changeRegType('signin')">Already a member? <u>Sign in now!</u></span>
            </div>
        </div>
        <div *ngSwitchCase="'resetPassword'">
            <img src="../../assets/images/Logo.png" alt="" width="100">
            <h1><b>Reset Password</b></h1>
            <div class="rounded" style="border: solid 0.5px dodgerblue;text-align: left;">
                <label for="email" class="text-primary bg-white"
                    style="position: absolute;;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">New Password</label>
                <input #passwordReset type="password" class="form-control" id="password"
                    style="border: none;outline: none;">
            </div>
            <div class="rounded mt-3" style="border: solid 0.5px dodgerblue;text-align: left;">
                <label for="password" class="text-primary bg-white"
                    style="position: absolute;;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">Confirm Password</label>
                <input #confirmReset type="password" class="form-control" id="password"
                    style="border: none;outline: none;" (keyup)="checkPasswords(passwordReset.value,confirmReset.value)">
            </div>
            <div class="mt-4" style="text-align: left;">
                <div class="row p-0 m-0" *ngIf="passwordError">
                    <span
                        style="color: red;font-size: .8em;font-weight: 500;margin-left: auto;margin-right: 0;margin-top: 5px;">{{passwordError}}</span>
                </div>
                <div class="row p-0 m-0 mb-2">
                    <button class="btn btn-primary" style="margin-left: auto;margin-right: 0;"
                        (click)="resetPassword(passwordReset.value)">Reset Password</button>
                </div>
            </div>
        </div>
        <div *ngSwitchDefault>
            <img src="../../assets/images/Logo.png" alt="logo" width="100">
            <h1><b>Sign in</b></h1>
            <div class="rounded" style="border: solid 0.5px dodgerblue;text-align: left;">
                <label for="email" class="text-primary bg-white"
                    style="position: absolute;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">Email</label>
                <input #emailLogin type="email" class="form-control" id="email" style="border: none;outline: none;"
                    (keydown.enter)="passwordLogin.focus()">
            </div>
            <div class="rounded mt-4" style="border: solid 0.5px dodgerblue;text-align: left;">
                <label for="password" class="text-primary bg-white"
                    style="position: absolute;font-weight: 400;margin-top: -10px;margin-left: 15px;padding-left: 5px;padding-right: 10px;">Password</label>
                <input #passwordLogin type="password" class="form-control" id="password"
                    style="border: none;outline: none;"
                    (keydown.enter)="authService.SignIn(emailLogin.value,passwordLogin.value)">
            </div>
            <div style="text-align: left;">
                <div class="row p-0 m-0">
                    <span class="text-primary pointer" style="font-weight: 400;font-size: 1.2em;margin: 15px 0;"
                        (click)="changeRegType('fp')">Forgot
                        password?</span>
                    <span *ngIf="!authService.processSucceed"
                        style="color: red;font-size: .8em;font-weight: 500;margin-left: auto;margin-right: 0;margin-top: 8px;margin-bottom: 2px;">{{authService.authMessage}}</span>
                </div>
                <div class="row p-0 m-0">
                    <button class="btn btn-primary" style="margin-left: auto;margin-right: 0;"
                        (click)="authService.SignIn(emailLogin.value,passwordLogin.value)">Sign in</button>
                </div>
            </div>
            <h2 class="text-primary mt-4" style="font-weight: 400;">Or continue with</h2>
            <button class="btn btn-dark mb-5 text-white" (click)="loginWithGoogle()"><img
                    src="../../assets/images/google.svg" alt="" width="30">&nbsp;&nbsp; Google</button>
            <div class="text-center">
                <span class="pointer" (click)="changeRegType('signup')">Want to join? <u>Sign up now!</u></span>
            </div>
        </div>
    </div>
</div>