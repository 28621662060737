import { ClassField } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import { DialogResult, MessageButtons, MessageType } from '../messagebox/messagebox.component';
import { AlertService } from '../services/alert.service';
import { Class } from '../models/class';
import { Teacher } from '../models/teacher';
import { Video } from '../models/video';
import { ClassPayment, PaymentManagerComponent } from '../payment-manager/payment-manager.component';
import { PaymentValidator } from '../payment-manager/payment-validator';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { SearchService } from '../services/search.service';
import { SerachParm, VideoSearchParm } from '../util/search-parms';

@Component({
  selector: 'app-myclasses-profile',
  templateUrl: './myclasses-profile.component.html',
  styleUrls: ['./myclasses-profile.component.scss']
})
export class MyclassesProfileComponent implements OnInit {

  private readonly DEFAULT_SEARCH_FIELD: string = VideoSearchParm.VideoTitle;
  private readonly DEFAULT_CLASS_COUNT: number = 16;
  private static readonly WEBPAGE_OFFSET: number = 1;

  private static savedVideos: Video[] = [];
  private lastKey: any = "";
  private listFlag: number = 0;
  private videoSearch: SerachParm = {
    field: "",
    keyword: ""
  }
  loadingList: boolean = false;
  isFilterHidden = true;
  isSearchResult: boolean = false;

  subjectPane = document.getElementById("subjectPane");
  searchContainer = document.getElementById("searchContainer");

  videoList: Video[] = [];
  videoLock: Array<boolean>;
  tempClass: Class;
  teacher: Teacher;

  option: AnimationOptions = {
    path: '/assets/lti/lti_video.json', loop: false
  };
  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json', loop: true
  };
  public readonly NO_THUMBNAIL = "./../assets/templates/video_thumb.jpg"

  constructor(private sharedService: DataService, private dbManager: DatabaseManager, private auth: AuthService,
    private dialog: MatDialog, private router: Router, public validator: PaymentValidator, private route: ActivatedRoute, private searchService: SearchService, private alertService: AlertService) {
    this.searchContainer.hidden = false;
    if (this.sharedService.sharedClass === undefined) {
      var c = {
        "teacherId": this.route.snapshot.paramMap.get("teacherId"),
        "classId": this.route.snapshot.paramMap.get("classId"),
        "subjectId": this.route.snapshot.paramMap.get("subjectId")
      };
      this.tempClass = JSON.parse(JSON.stringify(c));
    } else {
      this.tempClass = this.sharedService.sharedClass;
    }
    this.loadTeacher();
    this.initSearchResult();

    this.searchService.searchObserver.subscribe(result => {
      this.isSearchResult = true;
      this.clearClassList();
      this.setSearchKeyword(result)
      // set default search type if it is not selected
      if (this.videoSearch.field === "") {
        this.videoSearch.field = this.DEFAULT_SEARCH_FIELD;
      }
      this.initSearchResult();
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', () => this.scrollHandler(), true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  scrollHandler() {
    let triggerHeight = this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (triggerHeight >= this.subjectPane.scrollHeight - MyclassesProfileComponent.WEBPAGE_OFFSET.valueOf()) {
      this.initSearchResult();
    }
  }

  private initSearchResult() {
    if (this.isSearchResult) {
      if ((this.listFlag === 0)) {
        if (this.videoList.length !== 0)
          this.lastKey = [this.videoList[this.videoList.length - 1].srhVideoTitle, this.videoList[this.videoList.length - 1].srhTimestamp];
        this.loadContents();
      }
    }
    else {
      if ((this.listFlag === 0) && (!this.readMemory())) {
        if (this.videoList.length !== 0)
          this.lastKey = [this.videoList[this.videoList.length - 1].srhTimestamp];
        this.loadContents();
      }
    }
  }

  loadContents() {
    this.loadingList = true;
    this.listFlag = 1;

    this.dbManager.getVideos(this.tempClass, this.lastKey, this.videoSearch, this.DEFAULT_CLASS_COUNT).then((result: Video[]) => {
      this.videoLock = new Array<boolean>(result.length).fill(false);
      this.videoList = this.videoList.concat(result);
      this.listFlag = 0;
      this.loadingList = false;
      if (result.length === 0) {
        this.listFlag = -1;
      }
      this.validateVideos();
    });
  }

  private readMemory(): boolean {
    if ((this.videoList.length === 0) && (MyclassesProfileComponent.savedVideos.length !== 0)) {
      this.videoList = MyclassesProfileComponent.savedVideos;
      return true;
    } else {
      return false;
    }
  }

  setSearchKeyword(keyword: string,) {
    this.videoSearch.keyword = keyword;
  }

  setSearchType(parmType: string) {
    this.isFilterHidden = true;
    switch (parmType) {
      case VideoSearchParm.VideoTitle:
        this.videoSearch.field = VideoSearchParm.VideoTitle;
        break;
      default:
        this.videoSearch.field = VideoSearchParm.VideoTitle;
        break;
    }
  }

  clearClassList() {
    this.videoList = [];
    this.lastKey = "";
    this.listFlag = 0;
  }

  hideFilter(): void {
    if (this.isFilterHidden) {
      this.isFilterHidden = false;
    } else {
      this.isFilterHidden = true;
    }
  }

  validateVideos() {
    this.videoList.forEach((video, index) => {
      var dt = new Date(video.uploadedDate);
      this.validator.validateAccess(this.auth.userData.uid, video.classId, dt).then(result => {
        this.videoLock[index] = result;
      });
    });
  }

  getMessage(classId: string, date: string) {
    this.alertService
      .showAlert(
        "You should resolve your payment to unlock this metirial",
        MessageButtons.ok,
        MessageType.accessBlocked
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        if (result === DialogResult.ok) {
          this.payNow(classId, date);
        }
      });
  }

  loadTeacher() {
    this.dbManager.getTeacher(this.tempClass.teacherId).then((element: Teacher) => {
      this.teacher = element;
    });
  }


  payNow(classId: string, uploadedDate: string) {
    this.dbManager.getSingleClass(this.auth.userData.uid, classId).then((thisClass: Class) => {
      var myData: ClassPayment = {
        myClass: thisClass,
        date: new Date(uploadedDate)
      }
      this.dialog.open(PaymentManagerComponent, {
        data: myData,
      });
    });
  }

  playThis(video: Video) {
    this.sharedService.sharedVideo = video;
    this.router.navigate(['/video', video.teacherId, video.subjectId, video.classId, video.videoId]);
  }
}
