import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from "firebase/app";
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private static readonly BANK_SLIPS = "/bnkslips";

  private static readonly CONFERENCE_SLIP = "/ConferenceSlips"

  public uploadTask: firebase.storage.UploadTask = null;
  public uploading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private db: AngularFireDatabase) { }

  public pushConferenceSlip(file:File, progress:{percentage:number}, studentId:string, conferenceId:string, fileId: string){

    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();
      const path = `${UploadService.CONFERENCE_SLIP}/${studentId}/${conferenceId}/${fileId}`
      console.log(path)
      this.uploadTask = storageRef.child(path).put(file);

      this.uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          const snap = snapshot as firebase.storage.UploadTaskSnapshot;

          progress.percentage = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          )
        },
        (error) => {
          this.uploading.next(false);
          alert(error);
        },
        () => {
          // success
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            //console.log("File available at", downloadURL);
            this.uploading.next(false);
            resolve(downloadURL);
            //type 1 for upload  quizez
            // fileUpload.fileUrl = downloadURL;
            // fileUpload.fileName = fileUpload.file.name;
            // this.dbManager.saveQuiz(fileUpload);

            // console.log(fileUpload);
          });
        }
      )
    })
  }


  public pushBankSlip(file: File, progress: { percentage: number }, studentId: string, classId: string, fileId: string) {
    return new Promise<string>(resolve => {
      this.uploading.next(true);
      const storageRef = firebase.storage().ref();
      var storagePath = `${UploadService.BANK_SLIPS}/${studentId}/${classId}/${fileId}`;
      this.uploadTask = storageRef.child(storagePath).put(file);

      this.uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot) => {
          // in progress
          const snap = snapshot as firebase.storage.UploadTaskSnapshot;
          progress.percentage = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          );
        },
        (error) => {
          // fail
          this.uploading.next(false);
          alert(error);
        },
        () => {
          // success
          this.uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            //console.log("File available at", downloadURL);
            this.uploading.next(false);
            resolve(downloadURL);
            //type 1 for upload  quizez
            // fileUpload.fileUrl = downloadURL;
            // fileUpload.fileName = fileUpload.file.name;
            // this.dbManager.saveQuiz(fileUpload);

            // console.log(fileUpload);
          });
        }
      );
    })
  }

}
