import { Injectable } from '@angular/core';
import { DatabaseManager } from '../database/database-manager';
import { StudentManager } from '../profile/StudentManager';
// TimeAgo
import TimeAgo from 'javascript-time-ago';

@Injectable({
  providedIn: 'root',
})
export class ReviewsService {
  // Create formatter (English).
  private timeAgo = new TimeAgo('en-US');

  constructor(
    private dataservice: DatabaseManager,
    private studentManager: StudentManager
  ) {}

  getReviews(
    teacherId: string,
    resultLimit: number,
    lastkey: any,
    reviewset: any[]
  ): Array<any> {
    var reviews: any[] = reviewset;

    this.dataservice
      .getReviews(teacherId, resultLimit, lastkey)
      .then((elements) => {
        elements.forEach((element) => {
          this.studentManager.getStudent(element.studentId).then((student) => {
            var review = {
              reviewId: element.reviewId,
              date: this.timeAgo.format(new Date(element.timestamp)),
              reviewContent: element.reviewContent,
              starRating: element.starRating,
              timestamp: element.timestamp,
              studentName: student.firstName + ' ' + student.lastName,
              studentPic: student.profilePic,
              studentId: element.studentId,
            };

            /**
             * this next section is added because it might get code duplications due to scolling event getting called two times when scrolls slowly.
             */

            var length = 0;
            if (reviews.length != 0) {
              reviews.forEach((element) => {
                if (element.timestamp !== review.timestamp) {
                  length++;
                }
              });
              if (length == reviews.length) {
                reviews.push(review);
              }
            } else {
              reviews.push(review);
            }

            /**
             * sort section
             */

            reviews.sort(function (a, b) {
              if (a.timestamp < b.timestamp) {
                return 1;
              }
              if (a.timestamp > b.timestamp) {
                return -1;
              }
              return 0;
            });
          });
        });
      });
    return reviews;
  }
}
