import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseManager } from '../database/database-manager';
import { ExamDialogComponent } from '../exam-dialog/exam-dialog.component';
import { Quiz } from '../models/quiz';
import { DatePipe } from '@angular/common';
import { AuthService } from '../services/auth.service';
import { auth } from 'firebase';
import { AnimationOptions } from 'ngx-lottie';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { Teacher } from '../models/teacher';
import { DateTime } from '../util/datetime';

@Component({
  selector: 'app-mock-exam',
  templateUrl: './mock-exam.component.html',
  styleUrls: ['./mock-exam.component.scss'],
})
export class MockExamComponent implements OnInit {
  private readonly DEFAULT_COMPLETED_QUIZ_COUNT: number = 8;

  nextButton: boolean = true;
  previousButton: boolean = false;
  private topFirstKey: string = null;
  private lastKey: string = '';

  searchContainer = document.getElementById('searchContainer');

  teacherId: string;
  classId: string;
  sampleQuiz: Quiz;
  teacher: Teacher;
  availableQuizList: Quiz[] = [];
  completedQuizList = [];

  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };

  constructor(
    public dialog: MatDialog,
    private dataservice: DatabaseManager,
    private datepipe: DatePipe,
    public auth: AuthService,
    private sharedService: DataService,
    private route: ActivatedRoute
  ) {
    this.searchContainer.hidden = true;
    if (this.sharedService.sharedSub != undefined) {
      this.classId = JSON.parse(this.sharedService.sharedSub).classId;
      this.teacherId = JSON.parse(this.sharedService.sharedSub).teacherId;
    } else {
      this.teacherId = this.route.snapshot.paramMap.get('teacherId');
      this.classId = this.route.snapshot.paramMap.get('classId');
    }
  }

  ngOnInit(): void {
    console.log('Hell oworld');
    //wait until user connecting with the server
    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.loadQuizes();
        this.loadTeacher();
        this.nextCompleteQuizes();
      }
    });
  }

  openExamDialog(id: string) {
    this.dialog.open(ExamDialogComponent, {
      data: {
        quiz: this.availableQuizList.find((item) => item.quizId === id),
        teacher: this.teacher,
      },
    });
  }

  loadQuizes() {
    this.dataservice
      .getQuizes(this.teacherId, this.classId)
      .then((elements: Quiz[]) => {
        elements.forEach((element) => {
          if (this.isQuizAvailable(element.endDate, element.endTime)) {
            this.availableQuizList.push(element);
          }
        });
      });
  }

  nextCompleteQuizes() {
    if (
      this.completedQuizList[this.completedQuizList.length - 1] !== undefined
    ) {
      this.lastKey =
        this.completedQuizList[this.completedQuizList.length - 1].quizId;
    }
    this.dataservice
      .CompletedQuizesNext(
        this.teacherId,
        this.classId,
        this.lastKey,
        this.DEFAULT_COMPLETED_QUIZ_COUNT,
        this.auth.userData.uid
      )
      .then((result) => {
        this.completedQuizList = result;
        if (this.topFirstKey === null) this.topFirstKey = result[0].quizId;
        else this.previousButton = true;
        if (result.length !== this.DEFAULT_COMPLETED_QUIZ_COUNT) {
          this.nextButton = false;
        }
      })
      .catch((result) => {
        if (result) this.completedQuizList = result;
        else this.nextButton = false;
      });
  }

  prevCompleteQuizes() {
    if (this.completedQuizList[0] !== undefined) {
      this.lastKey = this.completedQuizList[0].quizId;
    }
    this.dataservice
      .CompletedQuizesPrev(
        this.teacherId,
        this.classId,
        this.lastKey,
        this.DEFAULT_COMPLETED_QUIZ_COUNT
      )
      .then((result: Quiz[]) => {
        this.completedQuizList = result;
        this.nextButton = true;
        if (this.completedQuizList[0].quizId === this.topFirstKey) {
          this.previousButton = false;
        }
      })
      .catch((res) => {
        this.previousButton = false;
      });
  }

  loadTeacher() {
    this.dataservice.getTeacher(this.teacherId).then((element: Teacher) => {
      this.teacher = element;
    });
  }

  isQuizAvailable(endDate, endTime): boolean {
    var due = new Date(Date.parse(endDate + ' ' + endTime));
    var today = new Date();

    if (due > today) {
      return true;
    } else {
      return false;
    }
  }

  timeStr12hrs(time: string) {
    return new DateTime().get12HrsTime(time);
  }
}
