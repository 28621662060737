import { Component, OnInit } from '@angular/core';
import { Teacher } from '../models/teacher';
//import { Subject } from '../models/subject';
//import { subjectType } from '../models/subjectType';
import { DatabaseManager } from '../database/database-manager';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject_Enrollment } from '../models/Subject_Enrollmen';
import { Subject } from '../models/subject';
import { AnimationOptions } from 'ngx-lottie';
import { SerachParm, TeacherSearchParm } from '../util/search-parms';
import { SearchService } from '../services/search.service';

@Component({
  selector: 'app-tutors',
  templateUrl: './tutors.component.html',
  styleUrls: ['./tutors.component.scss'],
})
export class TutorsComponent implements OnInit {
  private readonly DEFAULT_SEARCH_FIELD: string = TeacherSearchParm.TeacherName;
  private readonly DEFAULT_CLASS_COUNT: number = 12;
  private static readonly WEBPAGE_OFFSET: number = 1;

  private lastKey: any = '';
  private listFlag: number = 0;
  private teacherSearch: SerachParm = {
    field: '',
    keyword: '',
  };
  loadingList: boolean = false;
  isFilterHidden = true;
  isSearchResult: boolean = false;

  subjectPane = document.getElementById('subjectPane');
  searchContainer = document.getElementById('searchContainer');

  teachers: Teacher[] = [];
  subjectId: string;
  subjectName: string;

  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };
  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json',
    loop: true,
  };
  AdPos = [];
  adgap = 3;

  constructor(
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    public router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {
    this.searchContainer.hidden = false;
    if (this.sharedService.sharedSub !== undefined) {
      this.subjectId = JSON.parse(this.sharedService.sharedSub).subjectId;
      this.subjectName = JSON.parse(this.sharedService.sharedSub).subjectName;
    } else {
      this.subjectId = this.route.snapshot.paramMap.get('subjectId');
      this.dataservice.getSubject(this.subjectId).then((res) => {
        this.subjectName = res.subjectName;
      });
    }
    this.initSearchResult();

    this.searchService.searchObserver.subscribe((result) => {
      this.isSearchResult = true;
      this.clearClassList();
      this.setSearchKeyword(result);
      // set default search type if it is not selected
      if (this.teacherSearch.field === '') {
        this.teacherSearch.field = this.DEFAULT_SEARCH_FIELD;
      }
      this.initSearchResult();
    });
  }

  ngOnInit(): void {
    window.addEventListener('scroll', () => this.scrollHandler(), true);

    if (this.teachers.length > 0) {
      this.AdPos = [];
      for (let index = 0; index < this.teachers.length; index++) {
        if (index % this.adgap === 0) {
          this.AdPos.push(Math.round(Math.random() * this.adgap) + index);
        }
      }
    }
  }

  ngOnDestroy() {
    // remove scroll listener
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  scrollHandler() {
    let triggerHeight =
      this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (
      triggerHeight >=
      this.subjectPane.scrollHeight - TutorsComponent.WEBPAGE_OFFSET.valueOf()
    ) {
      this.initSearchResult();
    }
  }

  private initSearchResult() {
    if (this.isSearchResult) {
      if (this.listFlag === 0) {
        if (this.teachers.length !== 0)
          this.lastKey = [
            this.teachers[this.teachers.length - 1].srhTeacherName,
            this.teachers[this.teachers.length - 1].teacherId,
          ];
        this.loadTeachers();
      }
    } else {
      if (this.listFlag === 0) {
        if (this.teachers.length !== 0)
          this.lastKey = [this.teachers[this.teachers.length - 1].teacherId];
        this.loadTeachers();
      }
    }
  }
  isAd(i: number) {
    return this.AdPos.includes(i);
  }
  private loadTeachers(): void {
    this.loadingList = true;
    this.listFlag = 1;
    this.dataservice
      .getTeachersWithSubject(
        this.subjectId,
        this.lastKey,
        this.teacherSearch,
        this.DEFAULT_CLASS_COUNT
      )
      .then((elements) => {
        this.teachers = this.teachers.concat(elements);

        this.AdPos = [];
        for (let index = 0; index < this.teachers.length; index++) {
          if (index % this.adgap === 0) {
            this.AdPos.push(Math.round(Math.random() * this.adgap) + index);
          }
        }
        this.listFlag = 0;
        this.loadingList = false;
        if (elements.length === 0) {
          this.listFlag = -1;
        }
      });
  }

  setSearchKeyword(keyword: string) {
    this.teacherSearch.keyword = keyword;
  }

  setSearchType(parmType: string) {
    this.isFilterHidden = true;
    switch (parmType) {
      case TeacherSearchParm.TeacherName:
        this.teacherSearch.field = TeacherSearchParm.TeacherName;
        break;
      default:
        this.teacherSearch.field = TeacherSearchParm.TeacherName;
        break;
    }
  }

  clearClassList() {
    this.teachers = [];
    this.lastKey = '';
    this.listFlag = 0;
  }

  hideFilter(): void {
    if (this.isFilterHidden) {
      this.isFilterHidden = false;
    } else {
      this.isFilterHidden = true;
    }
  }

  navigateToProfile(teacherId: string) {
    this.sharedService.sharedSub = JSON.stringify({
      teacherId: teacherId,
      subjectId: this.subjectId,
    });
    this.router.navigate(['profile', teacherId, this.subjectId]);
  }
}
