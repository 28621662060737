import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-your-timetable',
  templateUrl: './your-timetable.component.html',
  styleUrls: ['./your-timetable.component.scss']
})
export class YourTimetableComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private datepipe: DatePipe) { }

  ngOnInit(): void {
  }

  getScheduledDate(day: string, startingTime: string) {
    var dayOfWeek: number = 0;
    var scheduledDate: Date;

    if (day === "Mon") {
      dayOfWeek = 1;
    } else if (day === "Tues") {
      dayOfWeek = 2;
    } else if (day === "Wed") {
      dayOfWeek = 3;
    } else if (day === "Thu") {
      dayOfWeek = 4;
    } else if (day === "Fri") {
      dayOfWeek = 5;
    } else if (day === "Sat") {
      dayOfWeek = 6;
    } else {
      dayOfWeek = 7;
    }
    if ((new Date().getDay() === 0 && dayOfWeek === 7) || new Date().getDay() === dayOfWeek) {
      scheduledDate = new Date(new Date().setHours(Number.parseInt(startingTime.split(":")[0]), Number.parseInt(startingTime.split(":")[1]), 0, 0));
    } else {
      var addAWeek = 0;
      if (dayOfWeek < new Date().getDay()) {
        addAWeek = 7;
      }
      //date that going to comes after current date.
      const r = Math.abs((addAWeek + dayOfWeek) - new Date().getDay());
      scheduledDate = new Date(new Date().setHours(Number.parseInt(startingTime.split(":")[0]), Number.parseInt(startingTime.split(":")[1]), 0, 0) + r * (1000 * 60 * 60 * 24));
    }
    return this.datepipe.transform(scheduledDate, "yyyy.MM.dd");
  }

  checkExtraDate(extraDate: string): boolean {
    var extra = new Date(extraDate);
    var current = new Date();

    var isScheduled: boolean;

    if (extra > current) {
      isScheduled = true;
    } else {
      isScheduled = false;
    }

    return isScheduled;
  }

}
