import { Component, OnInit, ViewChild } from '@angular/core';
import { Teacher } from '../models/teacher';
import { MatDialog } from '@angular/material/dialog';
import { WriteareviewDialogComponent } from '../writeareview-dialog/writeareview-dialog.component';
import { DatabaseManager } from '../database/database-manager';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Class } from '../models/class';
import { DateTime } from '../util/datetime';
import { AnimationOptions } from 'ngx-lottie';
import {
  ClassPayment,
  PaymentManagerComponent,
} from '../payment-manager/payment-manager.component';
import { AuthService } from '../services/auth.service';
import { SigninComponent } from '../signin/signin.component';
import { Video } from '../models/video';
import { ClassDialogComponent } from '../class-dialog/class-dialog.component';
import { ReviewsService } from '../services/reviews.service';
import { NgxStarsComponent } from 'ngx-stars';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  @ViewChild(NgxStarsComponent)
  starsComponent: NgxStarsComponent;

  private readonly DEFAULT_REVIEW_COUNT: number = 5;
  private static readonly WEBPAGE_OFFSET: number = 1;
  public readonly NO_THUMBNAIL = './../assets/templates/video_thumb.jpg';

  searchContainer = document.getElementById('searchContainer');
  teacherClasses: Class[] = [];
  featuredVideos: Video[] = [];
  teacher: Teacher;
  subjectId: string;
  teacherId: string;
  classPrice: string;
  isHidden: boolean = true;
  private selectedClass: Class;
  rating: number;

  clid: string = '-1';
  hasReviews = true;
  teacherLastKey: any = 0;

  noreviewslottie: AnimationOptions = {
    path: 'assets/lti/noreviews.json',
    loop: true,
  };
  reviews: any[] = new Array();
  subjectPane = document.getElementById('subjectPane');
  studentId: string;

  constructor(
    public dialog: MatDialog,
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    private datetime: DateTime,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private reviewservice: ReviewsService
  ) {
    this.searchContainer.hidden = true;
    if (sharedService.sharedSub !== undefined) {
      this.subjectId = JSON.parse(sharedService.sharedSub).subjectId;
      this.teacherId = JSON.parse(sharedService.sharedSub).teacherId;
    } else {
      this.subjectId = route.snapshot.paramMap.get('subjectId');
      this.teacherId = route.snapshot.paramMap.get('teacherId');
    }
    this.loadTeacher();
    this.loadTeacherClasses();
    this.loadFeaturedContent();
    this.initReviews();

    // console.log(this.starsComponent);
  }

  option1: AnimationOptions = {
    path: '/assets/lti/lti_video.json',
    loop: false,
  };
  option2: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };

  loadTeacher(): void {
    this.dataservice.getTeacher(this.teacherId).then((element) => {
      this.teacher = element;
      this.rating = this.setRating(this.teacher.ratingSet);
      // this.starsComponent.setRating(1);
    });
  }

  loadTeacherClasses(): void {
    this.dataservice
      .getTeacherClassWithSubject(this.teacherId, this.subjectId)
      .then((elements: Class[]) => {
        this.teacherClasses = elements;
      });
  }

  loadFeaturedContent(): void {
    this.dataservice
      .getFeaturedVideos(this.teacherId)
      .then((elements: Video[]) => {
        this.featuredVideos = elements;
      });
  }

  openClassDialog(clas: Class) {
    var status: number;
    if (this.auth.userData) {
      this.dataservice
        .validStudentClass(this.auth.userData.uid, clas.classId)
        .then((val) => {
          if (val) {
            status = 1;
            this.dialog.open(ClassDialogComponent, {
              data: { class: clas, classStatus: status },
            });
          } else {
            status = 2;
            this.dialog.open(ClassDialogComponent, {
              data: { class: clas, classStatus: status },
            });
          }
        });
    } else {
      status = 2;
      this.dialog.open(ClassDialogComponent, {
        data: { class: clas, classStatus: status },
      });
    }
  }

  ngOnInit(): void {
    // set scroll event
    window.addEventListener('scroll', () => this.scrollHandler(), true); //third parameter

    this.featuredVideos = <Array<Video>>[];

    this.dataservice
      .getFeaturedVideos(this.teacherId)
      .then((elements: Video[]) => {
        this.featuredVideos = elements;
      });
    this.auth.isUserAvailable().then(() => {
      this.studentId = this.auth.userData.uid;
    });
  }

  ngOnDestroy() {
    // remove scroll listener
    console.log('remove des');
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  deleteReview(i: number) {
    let review = this.reviews[i];
    this.dataservice.deleteReview(this.teacherId, review.reviewId);
    this.dataservice.reduceTeacherRating(this.teacherId, review.starRating);
    this.reviews.splice(review, 1);
  }
  scrollHandler() {
    let triggerHeight =
      this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (
      triggerHeight >=
      this.subjectPane.scrollHeight - ProfileComponent.WEBPAGE_OFFSET.valueOf()
    ) {
      this.initReviews();
    }
  }

  changeReviews(): void {
    if (this.hasReviews) {
      this.hasReviews = false;
    } else {
      this.hasReviews = true;
    }
  }

  openWriteReview() {
    this.dialog
      .open(WriteareviewDialogComponent, { data: this.teacherId })
      .afterClosed()
      .subscribe((res) => {
        if (res == WriteareviewDialogComponent.ADDED_REVIEW) {
          var revs = [];
          this.reviews = this.reviewservice.getReviews(
            this.teacherId,
            this.DEFAULT_REVIEW_COUNT,
            0,
            revs
          );
          setTimeout(() => {
            this.loadTeacher();
          }, 1000);
        }
      });
  }

  getTime(time: string): string {
    return this.datetime.get12HrsTime(time);
  }

  showSelectedPrice(myClass: Class) {
    if (this.isHidden) {
      this.isHidden = false;
    }
    this.clid = myClass.classId;
    this.classPrice = myClass.classFee.toString();
    this.selectedClass = myClass;
  }

  payNow() {
    var myData: ClassPayment = {
      myClass: this.selectedClass,
      date: new Date(),
    };
    if (this.auth.isLoggedIn) {
      this.dialog.open(PaymentManagerComponent, {
        data: myData,
      });
    } else {
      this.dialog.open(SigninComponent);
    }
  }

  playVideo(video: Video) {
    // this.sharedService.sharedVideo = video;
    this.router.navigate(['video', this.teacherId, video.videoId]);
  }

  initReviews() {
    if (this.reviews.length !== 0) {
      this.teacherLastKey = this.reviews[this.reviews.length - 1].timestamp;
    }
    this.reviewservice.getReviews(
      this.teacherId,
      this.DEFAULT_REVIEW_COUNT,
      this.teacherLastKey,
      this.reviews
    );
  }

  setRating(ratingSet: Array<number>): number {
    if (ratingSet.every((arrVal) => arrVal === 0)) {
      return 0;
    } else {
      var rate: number =
        (ratingSet[0] * 1 +
          ratingSet[1] * 2 +
          ratingSet[2] * 3 +
          ratingSet[3] * 4 +
          ratingSet[4] * 5) /
        (ratingSet[0] +
          ratingSet[1] +
          ratingSet[2] +
          ratingSet[3] +
          ratingSet[4]);
      return Math.round(rate * 10) / 10;
    }
  }
}
