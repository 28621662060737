import { Component, ViewChild, NgZone, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { VideoComponent } from './video/video.component';
import { MatDialog } from '@angular/material/dialog';
import { SigninComponent } from './signin/signin.component';
import { AuthService } from './services/auth.service';
import { SearchService } from './services/search.service';
import { ProfileValidatorService } from './services/profile-validator.service';
import { NotificationService } from './services/notification.service';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LoginState } from './services/user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild(VideoComponent, { static: true, read: VideoComponent })
  videoComponent: VideoComponent;
  bellIconCol: string = '#2e3131';
  message: string = '';
  keyword: string;
  isDataLoaded: boolean = false;
  private animationItem: AnimationItem;
  navitem: number = 1;
  routeQueryParams$: Subscription;
  paraName: string;

  constructor(
    private notifyService: NotificationService,
    private ngZone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public authservice: AuthService,
    public profileValidator: ProfileValidatorService,
    public searchService: SearchService
  ) {
    notifyService.notificationObserver.subscribe((data) => {
      this.bellIconCol = data.bellColor;
      this.message = data.message;
    });
    this.routeQueryParams$ = route.queryParams.subscribe((params) => {
      if (params['mode']) {
        this.paraName = params['mode'];
        if (this.paraName != 'verifyEmail') {
          this.openDialog(this.paraName);
        }
      }
    });
    if (authservice.loginState === LoginState.notVerified) {
      this.openDialog('signup');
    }
    //wait until user connecting with the server
  }

  ngOnInit() {
    this.changeNav();
  }

  ngOnDestroy() {
    this.routeQueryParams$.unsubscribe();
  }

  x: number = 1;
  menuStatus: number = 1;
  isSignedIn = false;
  isChecked = false;
  isMenuHidden = false;
  chaosMode = false;

  //starting lottie config
  animOption: AnimationOptions;
  option1: AnimationOptions = {
    path: '/assets/lti/lti_nonotification.json',
    loop: false,
  };
  bird: AnimationOptions = {
    path: '/assets/lti/lti_wait.json',
    loop: true,
  };

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
    this.animationItem.play();
  }

  playAnimation(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.play();
    });
    this.animOption = this.option1;
  }

  pauseAnimation(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.stop();
    });
  }

  hideMenu(): void {
    if (this.isMenuHidden) {
      this.isMenuHidden = false;
    } else {
      this.isMenuHidden = true;
    }
  }

  setSearchKeyword() {
    this.searchService.sendSearchKey(this.keyword);
    this.keyword = '';
  }

  changeNav(): void {
    setTimeout(() => {
      const location = window.location.href;
      if (location.includes('my-account')) {
        this.navitem = 2;
      } else if (
        location.includes('live-classes') ||
        location.includes('liveclass-profile') ||
        location.includes('online-class')
      ) {
        this.navitem = 3;
      } else if (
        location.includes('my-classes') ||
        location.includes('myclass-profile') ||
        location.includes('video')
      ) {
        this.navitem = 4;
      } else if (location.includes('my-notes') || location.includes('notes')) {
        this.navitem = 5;
      } else if (
        location.includes('exam') ||
        location.includes('quizanswersheet')
      ) {
        this.navitem = 6;
      } else if (location.includes('digi-conference')) {
        this.navitem = 7;
      } else if (location.includes('notices')) {
        this.navitem = 8;
      } else if (location.includes('contact')) {
        this.navitem = 10;
      } else if (location.includes('faq')) {
        this.navitem = 11;
      } else {
        this.navitem = 1;
      }
    }, 50);
  }

  signedIn(): void {
    if (this.isSignedIn) {
      this.isSignedIn = false;
    } else {
      this.isSignedIn = true;
    }
  }

  oncheck(): string {
    if (this.isChecked) {
      return 'on';
    } else {
      return 'off';
    }
  }

  openDialog(type: string): void {
    this.dialog
      .open(SigninComponent, { data: type })
      .afterClosed()
      .subscribe((res) => {
        if (this.paraName) {
          this.router.navigate(['.'], { relativeTo: this.route });
        }
      });
  }

  isDark() {
    let daark = {
      light_default: !this.isChecked,
      dark_default: this.isChecked,
    };
    return daark;
  }

  getFirstName(fullName: string): string {
    var firstName = fullName.split(' ', 1);
    return firstName[0];
  }

  openTutor() {
    window.open('https://tutor.digipanthiya.com', '_blank');
  }
}
