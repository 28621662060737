<div class="bg-white main-container p-2">
    <div class="row m-0 p-0">
        <span style="margin-left: auto;margin-right: 0%;" class="material-icons" mat-dialog-close>close</span>
    </div>
    <div class="container-fluid row p-0 ml-lg-1 my-3 desktop-table">
        <div class="day-col">
            <span class="day-title">Mon</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Mon') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime + ' - ' + class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Mon') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime + ' - ' + class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-col">
            <span class="day-title">Tues</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Tues') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime + ' - ' + class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Tues') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime + ' - ' + class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-col">
            <span class="day-title">Wed</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Wed') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Wed') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-col">
            <span class="day-title">Thu</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Thu') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Thu') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-col">
            <span class="day-title">Fri</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Fri') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Fri') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-col">
            <span class="day-title">Sat</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Sat') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Sat') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="day-col" style="border-right: solid 1px #636363;">
            <span class="day-title">Sun</span>
            <div style="display: grid;margin-top: 5%;">
                <div *ngFor="let class of data">
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Sun') && !class.extraClass">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="!class.extraClass"><b>{{getScheduledDate(class.day,class.startingTime)}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                    <div class="rounded mx-1 mb-1 p-2" style="text-align: left;border: solid 2px #1B1464;"
                        *ngIf="class.day.startsWith('Sun') && class.extraClass && checkExtraDate(class.extraClassDate)">
                        <div style="display: grid;">
                            <span class="class-title"><b>{{class.classTitle}}</b></span>
                            <span class="class-details mt-md-2"
                                *ngIf="class.extraClass"><b>{{class.extraClassDate}}</b></span>
                            <span class="class-details"><b>{{class.startingTime+ ' - '
                                    +class.endingTime}}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>