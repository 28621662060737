import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DatabaseManager } from '../database/database-manager';
import { DialogResult, MessageButtons, MessageType } from '../messagebox/messagebox.component';
import { Class } from '../models/class';
import { Quiz } from '../models/quiz';
import { ClassPayment, PaymentManagerComponent } from '../payment-manager/payment-manager.component';
import { PaymentValidator } from '../payment-manager/payment-validator';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { DateTime } from '../util/datetime';

@Component({
  selector: 'app-exam-dialog',
  templateUrl: './exam-dialog.component.html',
  styleUrls: ['./exam-dialog.component.scss']
})
export class ExamDialogComponent {

  quiz: Quiz;
  val: number = 0;
  quizLock: boolean = false;;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ExamDialogComponent>, private sharedService: DataService,
    public router: Router, private dataService: DatabaseManager, private auth: AuthService, private alertService: AlertService, private dialog: MatDialog, public validator: PaymentValidator) {
    this.quiz = this.data.quiz;
    this.isQuizAnswered();
    this.validateQuiz();
  }

  isQuizAnswered() {
    this.dataService.hasStudentAnswered(this.quiz.teacherId, this.quiz.classId, this.quiz.quizId, this.auth.userData.uid).then((element: boolean) => {
      if (element) {
        this.val = 1;
      }
      else
        this.val = 2;
    })
  }

  navgateToQuizSheet() {
    this.dialogRef.close();
    var currentDate = new Date();
    var startingDate = new Date(this.quiz.startDate + " " + this.quiz.startTime);
    if (currentDate > startingDate) {
      this.sharedService.sharedSub = JSON.stringify(this.quiz);
      this.router.navigate(['quizanswersheet', this.quiz.teacherId, this.quiz.classId, this.quiz.quizId]);
    } else {
      this.alertService
        .showAlert(
          "This quiz will starts in: " + this.quiz.startDate + " " + this.timeStr12hrs(this.quiz.startTime),
          MessageButtons.ok,
          MessageType.accessBlocked
        )
    }
  }

  timeStr12hrs(time: string) {
    return new DateTime().get12HrsTime(time);
  }

  validateQuiz() {
    var dt = new Date(this.quiz.startDate);
    this.validator.validateAccess(this.auth.userData.uid, this.quiz.classId, dt).then(result => {
      this.quizLock = result;
    });
  }

  getMessage(classId: string, date: string) {
    this.dialogRef.close();
    this.alertService
      .showAlert(
        "You should resolve your payment to unlock this metirial",
        MessageButtons.ok,
        MessageType.accessBlocked
      )
      .afterClosed()
      .subscribe((result: DialogResult) => {
        if (result === DialogResult.ok) {
          this.payNow(classId, date);
        }
      });
  }

  payNow(classId: string, uploadedDate: string) {
    this.dataService.getSingleClass(this.auth.userData.uid, classId).then((thisClass: Class) => {
      var myData: ClassPayment = {
        myClass: thisClass,
        date: new Date(uploadedDate)
      }
      this.dialog.open(PaymentManagerComponent, {
        data: myData,
      });
    });
  }

}
