import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import { ImageUploadComponent } from '../image-upload/image-upload.component';
import {
  DialogResult,
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import { Class } from '../models/class';
import { Payment } from '../models/payment';
import { Student } from '../models/student';
import {
  ClassPayment,
  PaymentManagerComponent,
} from '../payment-manager/payment-manager.component';
import { PaymentValidator } from '../payment-manager/payment-validator';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { ProfileValidatorService } from '../services/profile-validator.service';
import { DateTime } from '../util/datetime';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent {
  //const
  private readonly DEFAULT_HISTORY_COUNT: number = 4;
  readonly MONTH_NAMES = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  //store provinces
  readonly provinces: string[] = [
    '--Select--',
    'Northern',
    'North Western',
    'Western',
    'North Central',
    'Central',
    'Sabaragamuwa',
    'Eastern',
    'Uva',
    'Southern',
  ];

  searchContainer = document.getElementById('searchContainer');
  nopayment: AnimationOptions = {
    path: 'assets/lti/lf20_tmsiddoc.json',
    loop: true,
  };
  nosubscription: AnimationOptions = {
    path: 'assets/lti/lf20_tmsiddoc.json',
    loop: true,
  };
  //element controls
  nextButton: boolean = true;
  previousButton: boolean = false;
  private topFirstKey: string = null;
  private lastKey: string = '';

  studentClasses: Array<Class>;
  panelOpenState: boolean = false;
  historyList: Array<Payment> = new Array<Payment>();
  overImage: boolean = true;
  studentData: any;
  studentForm: FormGroup;
  isSaved: boolean;
  paidLock: Array<boolean> = new Array<boolean>();
  paidDates: Array<string> = new Array<string>();

  constructor(
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    public auth: AuthService,
    private alertService: AlertService,
    private dialog: MatDialog,
    public dateTime: DateTime,
    private paymentValidator: PaymentValidator,
    private formBuilder: FormBuilder,
    public profileValidator: ProfileValidatorService,
    private title: Title
  ) {
    this.searchContainer.hidden = true;
    //wait until user connecting with the server
    this.buildForm();
    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.studentForm.patchValue({
          studentId: this.auth.userData.uid,
        });
        this.loadStudent();
        this.nextPHistory();
        this.sharedService.subsClassObserver.subscribe((val) => {
          this.studentClasses = val;
          this.paidLock.push(true);
          this.paidDates.push('--');
          this.paymentCheck();
        });
      }
    });
  }
  ngOnInit() {
    this.title.setTitle('My Account | Digi Panthiya');
  }
  getImgUrl(url: string) {
    if (url === '' || !url) {
      return 'assets/images/noprofile.png';
    } else {
      return url;
    }
  }
  buildForm() {
    this.studentForm = this.formBuilder.group({
      studentId: new FormControl('', Validators.compose([Validators.required])),
      email: new FormControl(
        { disabled: true },
        Validators.compose([
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
        ])
      ),

      firstName: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
          Validators.pattern('^[a-zA-Z]+$'),
        ])
      ),
      lastName: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(15),
          Validators.pattern('^[a-zA-Z]+$'),
        ])
      ),
      nic: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(12),
        ])
      ),
      studentTp1: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(11),
          Validators.minLength(10),
        ])
      ),
      studentTp2: new FormControl(
        '',
        Validators.compose([
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(11),
          Validators.minLength(10),
        ])
      ),
      studentAddress: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.minLength(5)])
      ),
      city: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.pattern('^[a-zA-Z]+$'),
        ])
      ),
      postalCode: new FormControl(
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]+$'),
          Validators.maxLength(5),
          Validators.minLength(3),
        ])
      ),
      province: new FormControl(
        '--Select--',
        Validators.compose([
          Validators.required,
          Validators.pattern('^(?!--Select--).*$'),
        ])
      ),
      country: new FormControl('', Validators.compose([Validators.required])),
    });
  }

  loadStudent() {
    this.dataservice
      .getStudent(this.auth.userData.uid)
      .then((element: Student) => {
        this.studentData = element;
        let {
          city,
          country,
          email,
          firstName,
          lastName,
          nic,
          postalCode,
          province,
          studentAddress,
          studentId,
          studentTp1,
          studentTp2,
        } = this.studentData;

        this.studentForm.patchValue({
          city,
          country,
          email,
          firstName,
          lastName,
          nic,
          postalCode,
          province,
          studentAddress,
          studentId,
          studentTp1,
          studentTp2,
        });
      });
  }

  loadMySubs(event: any) {
    if (event.index === 1) {
      this.studentClasses = this.sharedService.getSubClassArray();
      this.paidLock = new Array<boolean>(this.studentClasses.length).fill(true);
      this.paymentCheck();
    }
  }

  saveStudent() {
    const {
      city,
      country,
      email,
      firstName,
      lastName,
      nic,
      postalCode,
      province,
      studentAddress,
      studentId,
      studentTp1,
      studentTp2,
    } = this.studentForm.value;
    let userData = {
      city,
      country,
      email,
      firstName,
      lastName,
      nic,
      postalCode,
      province,
      studentAddress,
      studentId,
      studentTp1,
      studentTp2,
    };
    this.studentForm.patchValue({
      country: 'Sri Lanka',
    });
    this.dataservice
      .updateStudent(this.studentForm.value.studentId, userData)
      .then((value) => {
        // console.log(value);
        this.alertService.showAlert(
          'Profile updated successfully!',
          MessageButtons.ok,
          MessageType.success
        );
      });
    this.isSaved = true;
    this.studentData = userData;
  }

  openProfileUploader() {
    let dialogref = this.dialog.open(ImageUploadComponent, {
      data: { studentId: this.studentForm.value.studentId },
    });
    dialogref.afterClosed().subscribe((result) => {
      // this.loadStudent();
    });
  }

  clearForm() {
    if (!this.isSaved) {
      this.studentForm.reset();
      let {
        city,
        country,
        email,
        firstName,
        lastName,
        nic,
        postalCode,
        province,
        studentAddress,
        studentId,
        studentTp1,
        studentTp2,
      } = this.studentData;

      this.studentForm.patchValue({
        city,
        country,
        email,
        firstName,
        lastName,
        nic,
        postalCode,
        province,
        studentAddress,
        studentId,
        studentTp1,
        studentTp2,
      });
    }
  }

  nextPHistory() {
    if (this.historyList[this.historyList.length - 1] !== undefined) {
      this.lastKey = this.historyList[this.historyList.length - 1].paymentId;
    }
    this.dataservice
      .paymentHistoryNext(
        this.auth.userData.uid,
        this.lastKey,
        this.DEFAULT_HISTORY_COUNT
      )
      .then((result: Payment[]) => {
        this.historyList = result;
        if (this.topFirstKey === null) this.topFirstKey = result[0].paymentId;
        else this.previousButton = true;
        if (result.length !== this.DEFAULT_HISTORY_COUNT) {
          this.nextButton = false;
        }
      })
      .catch((result: Payment[]) => {
        if (result) this.historyList = result;
        else this.nextButton = false;
      });
  }

  prevPHistory() {
    if (this.historyList[0] !== undefined) {
      this.lastKey = this.historyList[0].paymentId;
    }
    this.dataservice
      .paymentHistoryPrev(
        this.auth.userData.uid,
        this.lastKey,
        this.DEFAULT_HISTORY_COUNT
      )
      .then((result: Payment[]) => {
        this.historyList = result;
        this.nextButton = true;
        if (this.historyList[0].paymentId === this.topFirstKey) {
          this.previousButton = false;
        }
      })
      .catch((res) => {
        this.previousButton = false;
      });
  }

  getCurrencyStr(value: number): string {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'LKR',
    });
    if (value !== null && value !== undefined) return formatter.format(value);
    else return formatter.format(0);
  }

  getTime(time: string): string {
    return this.dateTime.get12HrsTime(time);
  }

  paymentCheck() {
    this.studentClasses.forEach((element, index) => {
      this.paymentValidator
        .getToken(this.auth.userData.uid, element.classId, new Date())
        .then((res) => {
          var token = res;
          if (token !== null)
            if (
              token.week1 === 1 &&
              token.week2 === 1 &&
              token.week3 === 1 &&
              token.week4 === 1
            ) {
              this.paidLock[index] = false;
              this.paidDates[index] = token.lastDate;
            } else {
              this.paidLock[index] = true;
            }
          // else {
          //   this.paidLock[index] = true
          //   console.log('null val');
          // }
        });
    });
  }

  payNow(curClass: Class) {
    var myData: ClassPayment = {
      myClass: curClass,
      date: new Date(),
    };
    this.dialog.open(PaymentManagerComponent, {
      data: myData,
    });
  }

  /**
   *
   * @param myClass
   * @param index
   */
  unEnroll(myClass: Class, index: number) {
    // this.paymentValidator();
    if (this.paidLock[index]) {
      this.alertService
        .showAlert(
          "Are you sure you want to unenroll from this class? Once you are unenrolled you can't access any video or study materials.",
          MessageButtons.YesNo,
          MessageType.warning
        )
        .afterClosed()
        .subscribe((result: DialogResult) => {
          if (result === DialogResult.yes) {
            this.dataservice
              .unenrollClass(this.auth.userData.uid, myClass)
              .then((output) => {
                if (output)
                  this.alertService.showAlert(
                    'Successfully unenrolled from the class.',
                    MessageButtons.ok,
                    MessageType.success
                  );
              });
          }
        });
    } else {
      this.alertService.showAlert(
        'You cannot unenroll from this class since you paid for the entire month. You will be able to unenroll from this class in a new month.',
        MessageButtons.ok,
        MessageType.warning
      );
    }
  }
}
