import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  //search observable
  searchObserver: Subject<string> = new Subject<string>();

  constructor() { }

  private setSearchKey(keyword: string) {
    this.searchObserver.next(keyword);
  }

  public sendSearchKey(keyword: string) {
    this.setSearchKey(keyword);
  }
}
