<div class="p-2">
  <div class="row m-0 p-0" *ngIf="studentNotices.length !== 0">
    <!-- *ngIf="studentNotices.length !== 0" -->
    <div class="row m-0 mt-2">
      <h1 class="mx-2">Notices</h1>
      <span
        class="m-2 rounded-circle btn-purple text-center text-white"
        style="font-size: 12px; width: 18px; height: 18px"
        ><b>{{ studentNotices.length }}</b></span
      >
    </div>
    <div
      class="p-2 mx-sm-3 mb-4 container-fluid p-0 border bg-white"
      style="border-radius: 15px"
      *ngFor="let notice of studentNotices; let i = index"
      id="i"
      (mouseover)="showDEL(i)"
      (mouseout)="removeDEL()"
    >
      <div class="container-fluid p-0 px-md-2 px-lg-3">
        <ng-template [ngIf]="!notice.isSystemMessage" [ngIfElse]="systemMsg">
          <div class="row mt-1 mt-md-2 mx-1" style="align-items: center">
            <div>
              <span class="notice-heading"
                >{{ notice.gradeName }} - {{ notice.subjectName }}</span
              >
              <!-- <br> -->
              <!-- <span class="notice-day">{{notice.day}}
                               {{getTime(notice.class.startingTime)}} - {{getTime(notice.class.endingTime)}} 
                            </span> -->
            </div>
            <div style="margin-left: auto; margin-right: 0%">
              <div class="row m-0 p-0">
                <span
                  class="text-secondary notice-date"
                  style="margin-left: auto; margin-right: 2%"
                  >{{ getTime(notice.publishTime) }}</span
                >
                <br />
                <span
                  class="text-secondary notice-date"
                  style="margin-left: auto; margin-right: 2%"
                  >{{ notice.publishDate }}</span
                >
              </div>
            </div>
          </div>
          <div class="row m-0 p-0 mt-3" style="align-items: center">
            <img
              [src]="notice.teacherPic"
              alt=""
              class="ml-3 rounded-circle notice-teacher-pic"
            />
            <span class="ml-2 text-secondary notice-teacher-name">{{
              notice.teacherName
            }}</span>
          </div>
        </ng-template>
        <ng-template #systemMsg>
          <div class="row mt-1 mt-md-2 mx-1" style="align-items: center">
            <img
              src="../../assets/images/Logo.png"
              class="rounded-circle notice-teacher-pic"
            />
            <span class="ml-2 text-secondary">Digi Panthiya</span>
            <div style="margin-left: auto; margin-right: 0%">
              <div class="row m-0 p-0">
                <span
                  class="text-secondary notice-date"
                  style="margin-left: auto; margin-right: 2%"
                  >{{ getTime(notice.publishTime) }}</span
                >
                <br />
                <span
                  class="text-secondary notice-date"
                  style="margin-left: auto; margin-right: 2%; font-weight: 600"
                  >{{ notice.publishDate }}</span
                >
              </div>
            </div>
          </div>
        </ng-template>
        <div class="m-0 p-0 ml-4 mt-3 col-10">
          <span class="notice-txt"
            ><b> {{ notice.messageName }}</b></span
          >
          <br />
          <ang-readmore
            [text]="notice.messageDescription"
            [length]="360"
            linkColor="#1b1464"
          >
          </ang-readmore>
        </div>
        <div class="row m-0 ml-4 pb-2" style="align-items: center">
          <!-- <span class="pointer notice-txt highlight" style="height: 2em" (click)="expandNotice(i)">
                        <b>{{id===i?'Read Less':'Read More'}}</b>
                    </span> -->
          <span
            class="material-icons text-danger pointer"
            style="margin-left: auto; margin-right: 0"
            (click)="deleteNotice(notice.noticeId)"
            [hidden]="hideDEL !== i"
            >delete</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0 p-0 mt-5 text-center" *ngIf="studentNotices.length === 0">
    <ng-lottie
      class="lottie-container"
      style="margin-left: auto; margin-right: auto"
      [options]="option"
      width="100%"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-4">
      <h2>No Notices Available...</h2>
    </div>
  </div>
</div>
