<div *ngIf="teacher">
  <div class="row">
    <div
      class="col-12 p-0"
      style="
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
      "
      [ngStyle]="{
        'background-image':
          this.teacher.coverPic !== ''
            ? 'url(' + this.teacher.coverPic + ')'
            : 'url(./assets/images/coverpic.png)'
      }"
    ></div>
    <div class="container-fluid">
      <div class="row" style="align-items: center">
        <div
          [ngStyle]="{
            'background-image': teacher.profilePic
              ? 'url(' + teacher.profilePic + ')'
              : 'url(./assets/images/noprofile.png)'
          }"
          class="rounded-circle my-3 ml-3"
          style="width: 70px; height: 70px; background-position: center"
        ></div>
        <div>
          <span class="pl-2 pl-sm-4" style="font-size: 1.3em; font-weight: 500">
            {{ teacher.teacherName }}
          </span>
          <div
            hidden
            class="row btn-purple p-2 text-white pointer"
            style="margin-left: auto; margin-right: 200px; align-items: center"
          >
            <i class="material-icons text-white">favorite</i>
            <span class="ml-2">favorites</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-tab-group animationDuration="0ms" #tabRef>
    <mat-tab label="Classes">
      <div class="row m-0 p-0 mt-4" *ngIf="teacherClasses.length !== 0">
        <div
          *ngFor="let class of teacherClasses"
          class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4"
        >
          <div class="pointer" (click)="openClassDialog(class)">
            <div
              class="col-12 m-0 p-0 mb-1 item-image-size mydiv"
              [style.box-shadow]="
                class.classId === clid
                  ? '0 1px 3px 3px rgba(30, 144, 255, .8)'
                  : 'none'
              "
              style="background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , 
              url('{{ class.subjectPic }}')"
            >
              <div class="row m-0 p-0 text-center">
                <div class="container-fluid p-0">
                  <span class="item-title-fs"
                    >{{ class.day }} {{ class.startingTime }} :
                    {{ class.endingTime }}</span
                  >
                </div>
                <div class="container-fluid mt-2">
                  <span
                    class="py-1 px-3 px-md-2 px-lg-3 rounded-pill text-white"
                    style="font-size: 0.8em"
                    [style.background-color]="
                      class.classType === 'paper'
                        ? '#EE5A24'
                        : class.classType === 'group'
                        ? '#B53471'
                        : '#1B1464'
                    "
                  >
                    {{ class.classType }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row m-0 p-0 mt-4 text-center"
        *ngIf="teacherClasses.length === 0"
      >
        <ng-lottie
          class="lottie-container"
          style="margin-left: auto; margin-right: auto"
          [options]="option2"
          width="100%"
          height="auto"
        >
        </ng-lottie>
        <div class="col-12 mt-4">
          <h2>No Classes Available...</h2>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Featured Videos">
      <div class="row m-0 p-0 mt-4" *ngIf="featuredVideos.length !== 0">
        <div
          class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4"
          *ngFor="let video of featuredVideos"
        >
          <div class="pointer" (click)="playVideo(video)">
            <img
              [src]="
                video.videoThumbnail === undefined ||
                video.videoThumbnail === ''
                  ? this.NO_THUMBNAIL
                  : video.videoThumbnail
              "
              class="col-12 m-0 p-0 mb-1 item-image-size"
            />
            <span class="item-title-fs">{{ video.videoTitle }}</span>
          </div>
        </div>
      </div>
      <div
        class="row m-0 p-0 mt-4 text-center"
        *ngIf="featuredVideos.length === 0"
      >
        <ng-lottie
          class="lottie-container"
          style="margin-left: auto; margin-right: auto"
          [options]="option1"
          width="100%"
          height="auto"
        >
        </ng-lottie>
        <div class="col-12 mt-4">
          <h2>No Videos Available...</h2>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="About">
      <div class="px-3 py-3">
        <h3><b>Description</b></h3>
        <div class="col-xl-3 p-2 text-center">
          <span> {{ teacher.teacherInfo }} </span>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Student Feedbacks">
      <div class="px-3 py-3">
        <h3><b>Student Feedbacks</b></h3>
        <div class="row m-0 mt-4" style="align-items: center">
          <span style="font-size: 2.5em"
            ><b>{{ rating }}</b></span
          >
          <ngx-stars
            class="mt-1 ml-4"
            [readonly]="true"
            [size]="2"
            [initialStars]="rating"
            [color]="'#FFC600'"
            id="ibba"
          >
          </ngx-stars>
        </div>
        <div
          *ngIf="auth.loginState == 2"
          class="row m-0 bg-white pointer border rounded-pill py-2 px-3 mt-3 mb-4"
          style="align-items: center; width: max-content"
          (click)="openWriteReview()"
        >
          <i class="material-icons" style="font-size: 1.3em">rate_review</i>
          <span class="ml-3" style="font-size: 0.9em">Write a Review</span>
        </div>
        <div class="col-12 p-0">
          <span style="font-size: 1.2em"><b>Reviews</b></span>
          <ng-template [ngIf]="reviews.length > 0" [ngIfElse]="noreviews">
            <div
              class="container-fluid review-block mt-3 m-0 p-0"
              style="max-width: 300px"
              *ngFor="let review of reviews; let i = index"
            >
              <div class="pt-3 row m-0" style="align-items: center">
                <img
                  src="{{ review.studentPic }}"
                  width="50"
                  height="50"
                  class="rounded-circle"
                  alt=""
                />
                <div class="ml-4">
                  <span style="font-size: 1.1em"
                    ><b>{{ review.studentName }}</b></span
                  >
                  <br />
                  <div class="container-fluid">
                    <div class="row" style="align-items: center">
                      <ngx-stars
                        class="mt-1"
                        [readonly]="true"
                        [size]="1"
                        [initialStars]="review.starRating"
                        [color]="'#FFC600'"
                      >
                      </ngx-stars>
                      <span
                        class="text-secondary ml-3"
                        style="font-size: 0.8em"
                        >{{ review.date }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="pt-1 px-4 ml-4">
                <p class="mx-4" style="font-size: 0.9em">
                  {{ review.reviewContent }}
                </p>
              </div>

              <div class="delete" *ngIf="review.studentId === studentId">
                <i
                  class="material-icons text-danger"
                  style="cursor: pointer"
                  (click)="deleteReview(i)"
                  >delete</i
                >
              </div>
            </div>
          </ng-template>
          <ng-template #noreviews>
            <div class="m-3">
              <ng-lottie
                [options]="noreviewslottie"
                width="100px"
                height="100px"
              ></ng-lottie>
              <h6 class="mx-4">No Reviews</h6>
            </div>
          </ng-template>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
