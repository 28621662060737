<div class="py-2 row m-0">
  <div class="col-lg-10 col-12 p-0 border-bottom" style="align-items: center">
    <div class="row m-0 pointer mt-1" (click)="hideFilter()">
      <i class="material-icons" style="font-size: 1.5em">filter_alt</i>
      <span style="font-size: 1.2em">FILTER</span>
    </div>
    <div class="row m-0 my-3" [class.hiddenFilter]="isFilterHidden == true">
      <div class="mr-5 filterDivs">
        <h4 class="mx-2 py-2 border-bottom text-secondary"><b>Field</b></h4>
        <div>
          <span class="px-2 py-1" (click)="setSearchType('teacherName')"
            >Teacher Name</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 m-0 p-0 mt-2">
    <h1>All Subjects / {{ subjectName }}</h1>
    <div class="row m-0 p-0 mt-3" *ngIf="teachers.length !== 0">
      <ng-container *ngFor="let teacher of teachers; let i = index">
        <ng-template [ngIf]="!isAd(i)" [ngIfElse]="advertisement">
          <div class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4">
            <div
              class="row m-0 p-0 pointer"
              (click)="navigateToProfile(teacher.teacherId)"
            >
              <!-- <img src="{{teacher.profilePic}}" alt="{{teacher.profilePic}}"
                      class="col-12 m-0 p-0 mb-1 item-image-size"> -->
              <div
                class="col-12 m-0 p-0 mb-1 item-image-size"
                style="
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-color: aliceblue;
                "
                [ngStyle]="{
                  'background-image': teacher.profilePic
                    ? 'url(' + teacher.profilePic + ')'
                    : 'url(./assets/images/noprofile.png)'
                }"
              ></div>
              <span class="col-12 item-title-fs text-center">{{
                teacher.teacherName
              }}</span>
            </div>
          </div>
        </ng-template>
        <ng-template #advertisement>
          <div class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4">
            <app-advertisement-display></app-advertisement-display>
          </div>

          <div class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4">
            <div
              class="row m-0 p-0 pointer"
              (click)="navigateToProfile(teacher.teacherId)"
            >
              <!-- <img src="{{teacher.profilePic}}" alt="{{teacher.profilePic}}"
                          class="col-12 m-0 p-0 mb-1 item-image-size"> -->
              <div
                class="col-12 m-0 p-0 mb-1 item-image-size"
                style="
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-color: aliceblue;
                "
                [ngStyle]="{
                  'background-image': teacher.profilePic
                    ? 'url(' + teacher.profilePic + ')'
                    : 'url(./assets/images/noprofile.png)'
                }"
              ></div>
              <span class="col-12 item-title-fs text-center">{{
                teacher.teacherName
              }}</span>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="center" *ngIf="loadingList && teachers.length !== 0">
      <ng-lottie
        style="
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 100px;
          padding-bottom: 50px;
        "
        [options]="loadingDot"
        width="80px"
        height="auto"
      >
      </ng-lottie>
    </div>
    <div class="row m-0 p-0 mt-5 text-center" *ngIf="teachers.length === 0">
      <ng-lottie
        class="lottie-container"
        style="margin-left: auto; margin-right: auto"
        [options]="option"
        width="100%"
        height="auto"
      >
      </ng-lottie>
      <div class="col-12 mt-4">
        <h2>No Teachers Available...</h2>
      </div>
    </div>
  </div>
</div>
