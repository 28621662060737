import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import { DigiConferenceDetailsComponent } from '../digi-conference-details/digi-conference-details.component';
import {
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import { ConferencePayment } from '../models/conference-payment';
import { DigiConfernce } from '../models/digi-conference';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-open-discussion-group',
  templateUrl: './open-discussion-group.component.html',
  styleUrls: ['./open-discussion-group.component.scss'],
})
export class OpenDiscussionGroupComponent implements OnInit {
  constructor(
    private db: DatabaseManager,
    private auth: AuthService,
    private route: Router,
    private dialog: MatDialog,
    private alertService: AlertService,
    private title: Title
  ) {}
  option: AnimationOptions = {
    path: 'assets/lti/lti_not-available-feature.json',
    loop: false,
  };
  nopayment: AnimationOptions = {
    path: 'assets/lti/lf20_tmsiddoc.json',
    loop: true,
  };
  searchContainer = document.getElementById('searchContainer');

  conferences: DigiConfernce[] = Array<DigiConfernce>();
  payedConferences: ConferencePayment[] = [];
  activeconf = 0;

  ngOnInit(): void {
    this.title.setTitle('Conference | Digi Panthiya');
    this.searchContainer.hidden = true;
    this.auth.isUserAvailable().then(() => {
      this.db
        .getSubscribedTeachers(this.auth.userData.uid)
        .then((data: Array<any>) => {
          data.map((teacherId) => {
            this.db.getConferenceByTeacher(teacherId).then((data) => {
              this.conferences = this.conferences.concat(data);

              data.forEach((res) => {
                this.activeconf += this.isExpired(res.day) ? 0 : 1;
              });
            });
          });
        });

      this.db
        .enrolledConference(this.auth.userData.uid)
        .then((res: ConferencePayment[]) => {
          this.payedConferences = res;
        });
    });
  }

  openZoomMeating(index: string, canjoin = false) {
    let thisconference = this.conferences[index];

    this.db
      .isEnrolled(this.auth.userData.uid, thisconference.conferenceId)

      .then((res: ConferencePayment) => {
        if (res) {
          if (res.paymentState === 1 || thisconference.conferencePrice === 0) {
            if (canjoin) {
              let thisconference = this.conferences[index];
              this.route.navigate([
                'live-conference',
                thisconference.teacherId,
                thisconference.conferenceId,
              ]);
            } else {
              return;
            }
          } else if (res.paymentState !== 1) {
            if (res.paymentState === -1) {
              this.alertService.showAlert(
                'Your Bank has been cancelled',
                MessageButtons.ok,
                MessageType.accessBlocked
              );
            } else {
              this.alertService.showAlert(
                'Your Bank payment is on pending Approval',
                MessageButtons.ok,
                MessageType.warning
              );
            }
          }
        } else {
          this.dialog.open(DigiConferenceDetailsComponent, {
            data: this.conferences[index],
          }); //PayDigiConferenceComponent
        }
      });
  }

  openPayedZoomMeating(index: string, canjoin = false) {
    let thisconference = this.payedConferences[index];
    canjoin = thisconference.paymentState === 1;

    if (canjoin) {
      this.route.navigate([
        'live-conference',
        thisconference.teacherId,
        thisconference.conferenceId,
      ]);
    } else {
      return;
    }
  }

  getRemainingTime(startdate: string, starttime: string, getcanjoin = false) {
    let remarr = startdate.split('-');
    let today = new Date().toLocaleDateString();
    let todayarr = today.split('/');

    if (
      parseInt(todayarr[0]) === parseInt(remarr[1]) &&
      parseInt(todayarr[1]) === parseInt(remarr[2]) &&
      parseInt(todayarr[2]) === parseInt(remarr[0])
    ) {
      let timarr = new Date().toTimeString().split(':');
      let starttimearr = starttime.split(':');

      let hourremaining = parseInt(starttimearr[0]) - parseInt(timarr[0]);
      let minremaining = parseInt(starttimearr[1]) - parseInt(timarr[1]);

      if (getcanjoin) {
        if (hourremaining <= 1) {
          if (hourremaining === 1) {
            let minremaining =
              60 - parseInt(timarr[1]) + parseInt(starttimearr[1]);

            return minremaining <= 30 || minremaining < 0;
          } else {
            return true;
          }
        } else {
          return false;
        }
      } else {
        if (hourremaining <= 1) {
          if (hourremaining === 1) {
            return `${
              60 - parseInt(timarr[1]) + parseInt(starttimearr[1])
            } minutes remaining`;
          } else {
            return `${minremaining} minutes remaining`;
          }
        } else {
          return `${hourremaining} hours remaining`;
        }
      }
    } else if (
      parseInt(remarr[1]) === parseInt(todayarr[0]) &&
      parseInt(todayarr[2]) === parseInt(remarr[0])
    ) {
      if (getcanjoin) {
        return false;
      } else {
        let sum = parseInt(remarr[2]) - parseInt(todayarr[1]);
        return `${sum < 0 ? 'Session Expired' : sum + 'days remaining'} `;
      }
    } else {
      if (getcanjoin) {
        return false;
      } else {
        return '1+ year remaining';
      }
    }
  }

  isExpired(startDate) {
    let today = new Date().toISOString().slice(0, 10);
    if (today > startDate) {
      return true;
    } else {
      return false;
    }
  }
}
