import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxStarsComponent } from 'ngx-stars';
import { DatabaseManager } from '../database/database-manager';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-writeareview-dialog',
  templateUrl: './writeareview-dialog.component.html',
  styleUrls: ['./writeareview-dialog.component.scss']
})
export class WriteareviewDialogComponent implements OnInit {

  @ViewChild(NgxStarsComponent)
  starsComponent: NgxStarsComponent;

  public static readonly ADDED_REVIEW = "Review Added";

  reviewForm: FormGroup;
  ratings: number = 5;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, private dataservice: DatabaseManager, private dialogRef: MatDialogRef<WriteareviewDialogComponent>, private auth: AuthService, private datePipe: DatePipe) {
    this.buildForm();
    auth.isUserAvailable().then(res => {
      if (res) {
        this.reviewForm.patchValue({
          studentId: auth.userData.uid
        })
      }
    })
  }

  ngOnInit(): void {
  }

  buildForm() {
    this.reviewForm = this.formBuilder.group({
      starRating: new FormControl("", Validators.compose([Validators.required])),
      reviewContent: new FormControl("", Validators.compose([Validators.required])),
      studentId: new FormControl("", Validators.compose([Validators.required])),
      reviewId: new FormControl("", Validators.compose([Validators.required])),
      date: new FormControl("", Validators.compose([Validators.required])),
      timestamp: new FormControl("", Validators.compose([Validators.required])),
    })
  }

  saveReview() {
    this.reviewForm.patchValue({
      starRating: this.ratings,
      date: this.datePipe.transform(new Date(), "yyyy-MM-dd"),
      timestamp: new Date().getTime()
    });
    const {
      starRating,
      reviewContent,
      studentId,
      reviewId,
      date,
      timestamp
    } = this.reviewForm.value;
    let reviewData = {
      starRating,
      reviewContent,
      studentId,
      reviewId,
      date,
      timestamp
    }
    if (reviewContent) {
      this.dataservice.createReview(this.data, reviewData);
    }
    this.dataservice.updateTeacherRating(this.data, this.ratings);
    this.dialogRef.close(WriteareviewDialogComponent.ADDED_REVIEW);
  }

  onRatingSet(rating: number): void {
    if (rating < 1) {
      this.starsComponent.setRating(1);
      rating = 1;
    }
    this.ratings = rating;
  }

}
