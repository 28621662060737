import { Injectable } from '@angular/core';
import { DatabaseManager } from '../database/database-manager';
import { Student } from '../models/student';

@Injectable({
  providedIn: 'root',
})
export class StudentManager {
  public static StudentPool: Map<string, Student> = new Map<string, Student>();

  constructor(private dataservice: DatabaseManager) {}

  public getStudent(studentId: string) {
    return new Promise<Student>((resolve, reject) => {
      var student: Student = StudentManager.StudentPool.get(studentId);
      if (student == undefined) {
        this.dataservice.getStudent(studentId).then((element: Student) => {
          student = element;
          StudentManager.StudentPool.set(student.studentId, student);
          resolve(student);
        });
      } else {
        resolve(student);
      }
    });
  }
}
