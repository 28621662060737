import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import { Class } from '../models/class';
import { LiveClass } from '../models/liveClass';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { SearchService } from '../services/search.service';
import { DateTime } from '../util/datetime';
import { ClassSearchParm, SerachParm } from '../util/search-parms';
import { YourTimetableComponent } from '../your-timetable/your-timetable.component';

@Component({
  selector: 'app-live-classes',
  templateUrl: './live-classes.component.html',
  styleUrls: ['./live-classes.component.scss'],
})
export class LiveClassesComponent implements OnInit {
  private readonly DEFAULT_SEARCH_FIELD: string = 'subjectName';
  private readonly DEFAULT_CLASS_COUNT: number = 16;
  private static readonly WEBPAGE_OFFSET: number = 1;

  private static savedClasses: Class[] = [];
  private lastKey: any = '';
  private listFlag: number = 0;
  private classSearch: SerachParm = {
    field: '',
    keyword: '',
  };
  loadingList: boolean = false;
  isFilterHidden = true;
  isSearchResult: boolean = false;

  subjectPane = document.getElementById('subjectPane');
  searchContainer = document.getElementById('searchContainer');

  studentClasses: Class[] = [];
  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };
  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json',
    loop: true,
  };

  constructor(
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    public router: Router,
    private auth: AuthService,
    private dialog: MatDialog,
    private datetime: DateTime,
    private searchService: SearchService,
    private title: Title
  ) {
    this.searchContainer.hidden = false;
    //wait until user connecting with the server
    auth.isUserAvailable().then((res) => {
      if (res) {
        this.initSearchResult();
      }
    });

    // trigger when something searched
    this.searchService.searchObserver.subscribe((result) => {
      this.isSearchResult = true;
      this.clearClassList();
      this.setSearchKeyword(result);
      // set default search type if it is not selected
      if (this.classSearch.field === '') {
        this.classSearch.field = this.DEFAULT_SEARCH_FIELD;
      }
      this.initSearchResult();
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Live Class | Digi Panthiya');
    window.addEventListener('scroll', () => this.scrollHandler(), true);
  }

  ngOnDestroy() {
    // remove scroll listener
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  scrollHandler() {
    let triggerHeight =
      this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (
      triggerHeight >=
      this.subjectPane.scrollHeight -
        LiveClassesComponent.WEBPAGE_OFFSET.valueOf()
    ) {
      this.initSearchResult();
    }
  }

  private initSearchResult() {
    if (this.isSearchResult) {
      if (this.listFlag === 0) {
        if (this.studentClasses.length !== 0) {
          switch (this.classSearch.field) {
            case ClassSearchParm.SubjectName:
              this.lastKey = [
                this.studentClasses[this.studentClasses.length - 1].subjectName,
                this.studentClasses[this.studentClasses.length - 1].classId,
              ];
              break;
            case ClassSearchParm.TeacherName:
              this.lastKey = [
                this.studentClasses[this.studentClasses.length - 1].teacherName,
                this.studentClasses[this.studentClasses.length - 1].classId,
              ];
              break;
          }
        }
        this.loadStudentClass();
      }
    } else {
      if (this.listFlag === 0 && !this.readMemory()) {
        if (this.studentClasses.length !== 0)
          this.lastKey =
            this.studentClasses[this.studentClasses.length - 1].classId;
        this.loadStudentClass();
      }
    }
  }

  loadStudentClass() {
    this.loadingList = true;
    this.listFlag = 1;
    this.dataservice
      .getStudentClasses(
        this.auth.userData.uid,
        this.DEFAULT_CLASS_COUNT,
        this.lastKey,
        this.classSearch
      )
      .then((elements: Class[]) => {
        this.studentClasses = this.studentClasses.concat(elements);
        LiveClassesComponent.savedClasses =
          LiveClassesComponent.savedClasses.concat(elements);
        this.listFlag = 0;
        this.loadingList = false;
        if (elements.length === 0) this.listFlag = -1;
      });
  }

  private readMemory(): boolean {
    if (
      this.studentClasses.length === 0 &&
      LiveClassesComponent.savedClasses.length !== 0
    ) {
      this.studentClasses = LiveClassesComponent.savedClasses;
      return true;
    } else {
      return false;
    }
  }

  setSearchKeyword(keyword: string) {
    this.classSearch.keyword = keyword;
  }

  setSearchType(parmType: string) {
    this.isFilterHidden = true;
    switch (parmType) {
      case ClassSearchParm.SubjectName:
        this.classSearch.field = 'subjectName';
        break;
      case ClassSearchParm.TeacherName:
        this.classSearch.field = 'teacherName';
        break;
      default:
        this.classSearch.field = 'subjectName';
        break;
    }
  }

  clearClassList() {
    this.studentClasses = [];
    this.lastKey = '';
    this.listFlag = 0;
  }

  hideFilter(): void {
    if (this.isFilterHidden) {
      this.isFilterHidden = false;
    } else {
      this.isFilterHidden = true;
    }
  }

  navigateToLiveClassProfile(classId: string, teacherId: string) {
    this.sharedService.sharedSub = JSON.stringify({
      classId: classId,
      teacherId: teacherId,
    });
    this.router.navigate(['liveclass-profile', teacherId, classId]);
  }

  openTimeTableDialog() {
    var timetableData = new Array();

    this.studentClasses.forEach((clas) => {
      this.dataservice
        .getLiveClassSet(clas.teacherId, clas.classId)
        .then((elements: LiveClass[]) => {
          if (elements.length > 0) {
            elements.forEach((liveclass) => {
              var v = {
                day: liveclass.day,
                classTitle: liveclass.classTitle,
                extraClass: liveclass.extraClass,
                extraClassDate: liveclass.extraClassDate,
                startingTime: liveclass.startingTime,
                endingTime: liveclass.endingTime,
              };

              timetableData.push(v);
            });
          }
        });
    });
    this.dialog.open(YourTimetableComponent, { data: timetableData });
  }

  getTime(time: string): string {
    return this.datetime.get12HrsTime(time);
  }
}
