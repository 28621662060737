import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import { Subject } from '../models/subject';
import { Subject_Enrollment } from '../models/Subject_Enrollmen';
import { Teacher } from '../models/teacher';
import { AdvertisementService } from '../services/advertisement.service';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { SearchService } from '../services/search.service';
import { SigninComponent } from '../signin/signin.component';
import { CustomParm, ParmType } from '../util/custom-parms';
import { SerachParm, SubjectSearchParm } from '../util/search-parms';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html',
  styleUrls: ['./subjects.component.scss'],
})
export class SubjectsComponent implements OnInit {
  //constants
  private readonly DEFAULT_SEARCH_FIELD: string = 'subjectName';
  private readonly DEFAULT_SUBJECT_COUNT: number = 16;
  private static readonly WEBPAGE_OFFSET: number = 1;

  // result-list controls
  private lastKey: any = 0;
  private listFlag: number = 0;
  private subjectSearch: SerachParm = {
    field: '',
    keyword: '',
  };
  loadingList: boolean = false;
  isFilterHidden = true;
  isSearchResult: boolean = false;
  // elelments
  subjectPane = document.getElementById('subjectPane');
  searchContainer = document.getElementById('searchContainer');

  subjects: Subject[] = [];
  subjectEn: Subject_Enrollment;
  teacher: Teacher;
  option: AnimationOptions = {
    path: '/assets/lti/lti_notes.json',
    loop: false,
  };
  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json',
    loop: true,
  };
  gradeId: string;

  adgap = 3;
  AdPos: Array<number> = [];
  adsAvailable = false;

  constructor(
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    private searchService: SearchService,
    public router: Router,
    private authService: AuthService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private adService: AdvertisementService
  ) {
    this.gradeId = this.route.snapshot.paramMap.get('gradeId');
    this.initSearchResult();
    // trigger when something searched
    this.searchContainer.hidden = false;
    this.searchService.searchObserver.subscribe((result) => {
      this.isSearchResult = true;
      this.clearSubjectList();
      this.setSearchKeyword(result);
      // set default search type if it is not selected
      if (this.subjectSearch.field === '')
        this.subjectSearch.field = this.DEFAULT_SEARCH_FIELD;
      this.initSearchResult();
    });
  }

  ngOnInit(): void {
    //checking parms when loading
    this.checkingParms(window.history.state.parm);
    // set scroll event
    window.addEventListener('scroll', () => this.scrollHandler(), true); //third parameter

    if (this.subjects.length > 0) {
      this.AdPos = [];
      for (let index = 0; index < this.subjects.length; index++) {
        if (index % this.adgap === 0) {
          this.AdPos.push(Math.round(Math.random() * this.adgap) + index);
        }
      }
    }
  }

  isAd(i: number) {
    return this.AdPos.includes(i);
  }

  checkingParms(parm: CustomParm) {
    if (parm !== undefined)
      switch (parm.type) {
        case ParmType.login:
          this.dialog.open(SigninComponent);
          break;
      }
  }

  isAdsAvailable() {
    if (this.adsAvailable) {
      return true;
    } else {
      this.adsAvailable = this.adService.isAdsAvailable();
      return this.adsAvailable;
    }
  }
  ngOnDestroy() {
    // remove scroll listener
    window.removeEventListener('scroll', () => this.scrollHandler(), true);
  }

  scrollHandler() {
    let triggerHeight =
      this.subjectPane.scrollTop + this.subjectPane.offsetHeight;
    if (
      triggerHeight >=
      this.subjectPane.scrollHeight - SubjectsComponent.WEBPAGE_OFFSET.valueOf()
    ) {
      this.initSearchResult();
    }
  }

  private initSearchResult() {
    if (this.isSearchResult) {
      if (this.listFlag === 0) {
        if (this.subjects.length !== 0)
          this.lastKey = this.subjects[this.subjects.length - 1].subjectName;
        this.loadSubjects();
      }
    } else {
      if (this.listFlag === 0) {
        if (this.subjects.length !== 0)
          this.lastKey = this.subjects[this.subjects.length - 1].subjectId;
        this.loadSubjects();
      }
    }
  }

  private loadSubjects(): void {
    this.loadingList = true;
    this.listFlag = 1;
    this.dataservice
      .getSubjects(
        this.gradeId,
        this.DEFAULT_SUBJECT_COUNT,
        this.lastKey,
        this.subjectSearch
      )
      .then((element: Subject[]) => {
        for (let index = 0; index < element.length; index++) {
          if (index % this.adgap === 0) {
            this.AdPos.push(Math.round(Math.random() * this.adgap) + index);
          }
        }

        this.subjects = this.subjects.concat(element);
        this.listFlag = 0;
        this.loadingList = false;
        if (element.length === 0) this.listFlag = -1;
      });
  }

  /**
   * Set search keyword
   * @param keyword searching keyword
   */
  setSearchKeyword(keyword: string) {
    this.subjectSearch.keyword = keyword;
  }

  /**
   * Set search type
   * @param parmType set null to use default field
   */
  setSearchType(parmType: string) {
    this.isFilterHidden = true;
    switch (parmType) {
      case SubjectSearchParm.SubjectName:
        this.subjectSearch.field = 'subjectName';
        break;
      default:
        this.subjectSearch.field = 'subjectName';
        break;
    }
  }

  /**
   * Clear subject list with the last key
   */
  clearSubjectList() {
    this.subjects = [];
    this.lastKey = '';
    this.listFlag = 0;
  }

  public navigateTutors(subjectId: string, subjectName: String) {
    const tutorsData = { subjectId: subjectId, subjectName: subjectName };
    this.sharedService.sharedSub = JSON.stringify(tutorsData); //shared Service walata udana kiyana value eka yawanawa
    this.router.navigate(['tutors', subjectId]); // navigate karanwa app routing module .ts walata
  }

  hideFilter(): void {
    if (this.isFilterHidden) {
      this.isFilterHidden = false;
    } else {
      this.isFilterHidden = true;
    }
  }

  get data(): string {
    return this.sharedService.sharedSub;
  }
}
