import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { YourNotesComponent } from './your-notes/your-notes.component';
import { MockExamComponent } from './mock-exam/mock-exam.component';
import { NoticesComponent } from './notices/notices.component';
import { OpenDiscussionGroupComponent } from './open-discussion-group/open-discussion-group.component';
import { EbraryComponent } from './ebrary/ebrary.component';
import { TutorsComponent } from './tutors/tutors.component';
import { ProfileComponent } from './profile/profile.component';
import { VideoComponent } from './video/video.component';
import { PaymentValidator } from './payment-manager/payment-validator';

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireDatabaseModule } from "@angular/fire/database";

import { NotesComponent } from './notes/notes.component';
import { SigninComponent } from './signin/signin.component';
import { ExamDialogComponent } from './exam-dialog/exam-dialog.component';

import { QuizanswersheetComponent } from './quizanswersheet/quizanswersheet.component';
import { MyClassesComponent } from './my-classes/my-classes.component';
import { WriteareviewDialogComponent } from './writeareview-dialog/writeareview-dialog.component';
import { MyclassesProfileComponent } from './myclasses-profile/myclasses-profile.component';


//external models
import { CountdownModule } from 'ngx-countdown'
import { ImageCropperModule } from 'ngx-image-cropper';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
export function playerFactory() {
  return player;
}
import { NgxStarsModule } from 'ngx-stars';

//Services
import { ProfileValidatorService } from "./services/profile-validator.service";
import { NotificationService } from './services/notification.service';
// Auth service
import { AuthService } from "./services/auth.service";

//guards
import { AuthGuard } from "./guards/auth.guard";

import { TestComponent } from './test/test.component';
import { ZoomViewerComponent } from './zoom-viewer/zoom-viewer.component';
import { MessageboxComponent } from './messagebox/messagebox.component';
import { DatePipe } from '@angular/common';
import { LiveClassesComponent } from './live-classes/live-classes.component';
import { LiveclassProfileComponent } from './liveclass-profile/liveclass-profile.component';
import { YourTimetableComponent } from './your-timetable/your-timetable.component';
import { PaymentManagerComponent } from './payment-manager/payment-manager.component';
import { DateTime } from './util/datetime';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ContactusComponent } from './contactus/contactus.component';
import { FaqComponent } from './faq/faq.component';
import { Er404Component } from './er404/er404.component';
import { ExamComponent } from './exam/exam.component';
import { ClassDialogComponent } from './class-dialog/class-dialog.component';
import { SubjectsComponent } from './subjects/subjects.component';
import { NgxImageCompressService } from "ngx-image-compress";// image compresser
import { MatTableModule } from '@angular/material/table'  
// show more 
import { AngReadmoreModule } from 'ang-readmore'

import TimeAgo from 'javascript-time-ago'
// TimeAgo English.
import en from 'javascript-time-ago/locale/en';
import { PayDigiConferenceComponent } from './pay-digi-conference/pay-digi-conference.component';
import { DigiConferenceDetailsComponent } from './digi-conference-details/digi-conference-details.component';
import { AdvertisementDisplayComponent } from './advertisement-display/advertisement-display.component';
//Config TimeAgo
TimeAgo.addDefaultLocale(en)


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MyAccountComponent,
    YourNotesComponent,
    MockExamComponent,
    NoticesComponent,
    OpenDiscussionGroupComponent,
    EbraryComponent,
    TutorsComponent,
    ProfileComponent,
    VideoComponent,
    NotesComponent,
    SigninComponent,
    ExamDialogComponent,
    QuizanswersheetComponent,
    MyClassesComponent,
    WriteareviewDialogComponent,
    MyclassesProfileComponent,
    TestComponent,
    ZoomViewerComponent,
    MessageboxComponent,
    LiveClassesComponent,
    LiveclassProfileComponent,
    YourTimetableComponent,
    PaymentManagerComponent,
    ImageUploadComponent,
    ContactusComponent,
    FaqComponent,
    Er404Component,
    ExamComponent,
    ClassDialogComponent,
    SubjectsComponent,
    PayDigiConferenceComponent,
    DigiConferenceDetailsComponent,
    AdvertisementDisplayComponent,
  
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,
    MatStepperModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatTooltipModule,
    MatInputModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatProgressBarModule,
    //angularfire modules for auth
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    //external models
    CountdownModule,
    ImageCropperModule,
    NgxStarsModule,
    //lottie
    LottieModule.forRoot({ player: playerFactory }),
    AngReadmoreModule,
    MatTableModule
  ],
  entryComponents: [
    PaymentManagerComponent,
    PaymentValidator
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [],
  providers: [AuthService, ProfileValidatorService, NotificationService, DatePipe, DateTime, AuthGuard, NgxImageCompressService],
  bootstrap: [AppComponent]
})
export class AppModule { }
