export class SerachParm {
    field: any;
    keyword: string;
    // order: SearchOrder;
}

export enum SearchOrder {
    Ascending = "asc", Descending = "desc"
}

export enum GradeSearchParm {
    GradeName = "gradeName", GradeId = "gradeId"
}

export enum SubjectSearchParm {
    SubjectName = "subjectName", GradeName = "gradeName"
}

export enum ClassSearchParm {
    SubjectName = "subjectName", GradeName = "gradeName", TeacherName = "teacherName"
}

export enum NoteSearchParm {
    NoteId = "noteId", NoteTitle = "srhNoteTitle", SubjectName = "srhSubjectName", TeacherName = "srhTeacherName", Timestamp = "srhTimestamp"
}

export enum VideoSearchParm {
    VideoId = "videoId", VideoTitle = "srhVideoTitle", SubjectName = "srhSubjectName", TeacherName = "srhTeacherName", Timestamp = "srhTimestamp"
}

export enum TeacherSearchParm {
    TeacherId = "teacherId", TeacherName = "srhTeacherName"
}
