<div class="bg-white rounded container">
    <div class="row px-3 py-3 rounded-top border-bottom" style="align-items: center">
        <span style="font-size: 1.2em;"><b>Write a Review</b></span>
        <span mat-dialog-close style="margin-left: auto; margin-right: 0%" class="material-icons">close</span>
    </div>
    <div class="row my-3 px-3" [formGroup]="reviewForm">
        <div style="margin-left: auto;margin-right: auto;">
            <ngx-stars [customPadding]="'1rem'" [size]="2" [initialStars]="5" [color]="'#FFC600'" [wholeStars]="true"
                (ratingOutput)="onRatingSet($event)"></ngx-stars>
        </div>
        <textarea class="form-control my-4" rows="5" style="resize: none;font-size: .9em;" id="reviewContent"
            formControlName="reviewContent" placeholder="How is Your Teacher?"></textarea>
        <div
            *ngIf="reviewForm.controls['reviewContent'].invalid && (reviewForm.controls['reviewContent'].dirty || reviewForm.controls['reviewContent'].touched)">
            <div *ngIf="reviewForm.controls['reviewContent'].errors.minLength">
                review should at least be 3 characters long.
            </div>
        </div>
        <span class="btn btn-purple text-white" style="margin-left: auto;margin-right: 0%;font-size: .8em;"
            (click)="saveReview()">Add
            Review</span>
    </div>
</div>