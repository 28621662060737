import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZoomMtg } from '@zoomus/websdk';
import { DatabaseManager } from '../database/database-manager';
import { LiveClass } from '../models/liveClass';
import { DataService } from '../services/data.service';
import { MatDialog } from '@angular/material/dialog';
import {
  MessageboxComponent,
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import { AuthService } from '../services/auth.service';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute } from '@angular/router';
import { DigiConfernce } from '../models/digi-conference';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.3.5/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

@Component({
  selector: 'app-zoom-viewer',
  templateUrl: './zoom-viewer.component.html',
  styleUrls: ['./zoom-viewer.component.scss'],
})
export class ZoomViewerComponent implements OnInit {
  //main data for setup zoom meeting
  private readonly signatureEndpoint =
    'https://us-central1-digipanthiya-bc66f.cloudfunctions.net/generateSignature';
  private readonly apiKey = '6dTmuV5kTTGsUYIUzevBLA';
  private readonly leaveUrl = 'https://digipanthiya.com/live-classes';

  public isLoading: boolean = true;
  public isError: boolean = false;
  searchContainer = document.getElementById('searchContainer');

  option: AnimationOptions = {
    path: '/assets/lti/lti_zoom.json',
    loop: false,
  };
  dot_loading: AnimationOptions = {
    path: '/assets/lti/lti_dot_loading.json',
    loop: true,
  };
  error: AnimationOptions = {
    path: '/assets/lti/lti_error.json',
    loop: false,
  };

  //live class details
  private myName = 'Student';
  private myClass: LiveClass;
  private classId: string;
  private liveClassId: string;
  private teacherId: string;
  private conferenceid: string = null;
  private conference: DigiConfernce;
  private isConference = false;

  constructor(
    public httpClient: HttpClient,
    private dataservice: DatabaseManager,
    private sharedService: DataService,
    private dialog: MatDialog,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {
    this.searchContainer.hidden = true;
  }

  ngOnInit() {
    this.auth.isUserAvailable().then((res) => {
      this.route.url.forEach((routepath) => {
        if (routepath[0].path === 'live-conference') {
          this.isConference = true;
          this.teacherId = this.route.snapshot.paramMap.get('teacherId');
          this.conferenceid = this.route.snapshot.paramMap.get('conferenceId');

          this.dataservice
            .getConference(this.teacherId, this.conferenceid)
            .then((data: DigiConfernce) => {
              this.conference = data;
              this.conference.meetingNumber = this.conference.meetingNumber
                .split(' ')
                .join('');
            })
            .catch((err) => (this.isError = true));
        } else {
          if (res) {
            if (this.sharedService.sharedSub !== undefined) {
              this.classId = JSON.parse(this.sharedService.sharedSub).classId;
              this.liveClassId = JSON.parse(
                this.sharedService.sharedSub
              ).liveClassId;
              this.teacherId = JSON.parse(
                this.sharedService.sharedSub
              ).teacherId;
            } else {
              this.classId = this.route.snapshot.paramMap.get('classId');
              this.liveClassId =
                this.route.snapshot.paramMap.get('liveClassId');
              this.teacherId = this.route.snapshot.paramMap.get('teacherId');
            }
          }
          // * * * * *
          this.setupMeeting(
            this.auth.userData.uid,
            this.classId,
            this.teacherId,
            this.liveClassId
          );
        }
        this.sharedService.getMyInfo(this.auth.userData.uid).then((result) => {
          this.myName = result.firstName + ' ' + result.lastName;
          this.isLoading = false;
        });
      });
    });
  }

  setupConference() {}

  setupMeeting(
    studentId: string,
    classId: string,
    teacherId: string,
    liveClassId: string
  ) {
    this.dataservice
      .validStudentClass(studentId, classId)
      .then((result: boolean) => {
        if (result) {
          this.dataservice
            .getLiveClass(teacherId, classId, liveClassId)
            .then((liveClass: LiveClass) => {
              this.myClass = liveClass;
              this.myClass.meetingNumber = this.myClass.meetingNumber
                .split(' ')
                .join('');
            });
        } else {
          this.dialog.open(MessageboxComponent, {
            width: '500px',
            maxWidth: '95%',
            data: {
              message: 'Authentication error',
              messageButtons: MessageButtons.ok,
              messageType: MessageType.accessBlocked,
            },
          });
        }
      });
  }

  getSignature() {
    this.httpClient
      .post(this.signatureEndpoint, {
        meetingNumber: this.isConference
          ? this.conference.meetingNumber
          : this.myClass.meetingNumber,
        role: this.isConference ? this.conference.role : this.myClass.role,
      })
      .toPromise()
      .then((data: any) => {
        if (data.signature) {
          this.startMeeting(data.signature);
        }
      })
      .catch((error) => {
        this.isError = true;
        //console.log(error);
      });
  }

  startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block';
    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      meetingInfo: ['topic', 'host'],
      success: (success) => {
        ZoomMtg.join({
          apiKey: this.apiKey,
          signature: signature,
          meetingNumber: this.isConference
            ? this.conference.meetingNumber
            : this.myClass.meetingNumber,
          userName: this.myName,
          userEmail: this.auth.userData.email,
          passWord: this.isConference
            ? this.conference.password
            : this.myClass.password,
          success: (success) => {
            //hide invite function
            ZoomMtg.showInviteFunction({
              show: false,
            });
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }
}
