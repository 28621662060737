import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';
import { Class } from '../models/class';
import { Video } from '../models/video';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { Student } from '../models/student';
import { DatabaseManager } from '../database/database-manager';
import { Teacher } from '../models/teacher';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private static readonly DBPATH_STUDENT = "Student";
  private static readonly DBPATH_STUDENT_TEACHER = "StudentTeacher";
  private static readonly DBPATH_STUDENT_CLASS = "StudentClass";

  //stored data
  private static myInfo: Student = null;
  private teacherPic: Map<string, Teacher> = new Map<string, Teacher>();
  private subscribedTeachers: Array<string>;
  private subscribedClass: Array<Class>;
  subsClassObserver: Subject<Array<Class>> = new Subject<Array<Class>>();
  sharedSub: string;
  sharedClass: Class;
  sharedVideo: Video;

  constructor(private fireStore: AngularFirestore, private authService: AuthService, private db: DatabaseManager) {
    //load required data for the system
    //wait until user connecting with the server
    authService.isUserAvailable().then(res => {
      if (res) {
        this.loadRequiredData();
        this.getMyInfo(this.authService.userData.uid);
      }
    });
  }

  public loadRequiredData() {
    if (this.authService.isLoggedIn) {
      //teachers
      this.fireStore
        .collection(DataService.DBPATH_STUDENT)
        .doc(this.authService.userData.uid)
        .collection(DataService.DBPATH_STUDENT_TEACHER).snapshotChanges()
        .pipe(
          map((changes: any[]) =>
            changes.map(c =>
              ({ key: c.payload.doc.id, ...c.payload.doc.data() })
            )
          )
        ).subscribe((elements) => {
          if (elements[0] != undefined) {
            this.subscribedTeachers = elements[0].idSet;
          } else {
            this.subscribedTeachers = new Array<string>();
          }
        });

      //classes
      this.fireStore.collection(DataService.DBPATH_STUDENT)
        .doc(this.authService.userData.uid)
        .collection(DataService.DBPATH_STUDENT_CLASS).snapshotChanges()
        .pipe(
          map((changes: any[]) =>
            changes.map(c =>
              ({ key: c.payload.doc.id, ...c.payload.doc.data() })
            )
          )
        ).subscribe((elements) => {
          if (elements[0] != undefined) {
            this.subscribedClass = elements;
          } else {
            this.subscribedClass = new Array<Class>();
          }
        });
    }
  }

  getSubscribeClasses(list: any) {
    this.subsClassObserver.next(list);
  }

  public getSubClassArray(): Array<Class> {
    return this.subscribedClass;
  }

  public getSubscribeTeachers(): Array<string> {
    return this.subscribedTeachers;
  }

  public isTeacherSubscribed(teacherId: string): boolean {
    if (this.subscribedTeachers.find(x => x == teacherId) === undefined)
      return false;
    else
      return true;
  }

  public isClassSubscribed(classId: string): boolean {
    if (this.subscribedClass.find(x => x.classId === classId) === undefined)
      return false;
    else
      return true;
  }

  public getTeacherInfo(teacherId: string) {
    return new Promise<Teacher>(resolve => {
      var myTeacher = this.teacherPic.get(teacherId);
      if (myTeacher === undefined) {
        this.db.getTeacher(teacherId).then(result => {
          this.teacherPic.set(teacherId, result);
          resolve(result);
        })
      } else {
        resolve(myTeacher);
      }
    });
  }

  public getMyInfo(uid: string) {
    return new Promise<Student>(resolve => {
      if (DataService.myInfo === null)
        this.db.getStudent(uid).then(res => {
          DataService.myInfo = res;
          resolve(res);
        })
      else
        resolve(DataService.myInfo);
    });
  }
}