<div class="py-2">
  <div class="row m-0">
    <div
      class="col-lg-10 col-12 m-0 p-0 border-bottom"
      style="align-items: center"
    >
      <div class="row m-0 p-0 pointer mt-1" (click)="hideFilter()">
        <i class="material-icons" style="font-size: 1.5em">filter_alt</i>
        <span style="font-size: 1.2em">FILTER</span>
      </div>
      <div
        class="row m-0 p-0 my-3"
        [class.hiddenFilter]="isFilterHidden == true"
      >
        <!-- <div class="mr-5 filterDivs">
                    <h4 class="mx-2 py-2 border-bottom text-secondary"><b>UPLOADED DATE</b></h4>
                    <div style="display: grid;">
                        <span class="px-2 py-1">Last Hour</span>
                        <span class="px-2 py-1">Today</span>
                        <span class="px-2 py-1">This Week</span>
                        <span class="px-2 py-1">This Month</span>
                        <span class="px-2 py-1">This Year</span>
                    </div>
                </div> -->
        <div class="mr-5 filterDivs">
          <h4 class="mx-2 py-2 border-bottom text-secondary"><b>Field</b></h4>
          <div>
            <span
              class="px-2 py-1"
              (click)="setSearchType('srhNoteTitle')"
              *ngIf="selectedTab === 0"
              >Note Title</span
            >
            <span
              class="px-2 py-1"
              (click)="setSearchType('srhNoteTitle')"
              *ngIf="selectedTab === 1"
              >Web Link</span
            >
          </div>
        </div>
        <!-- <div class="mr-5 filterDivs">
                    <h4 class="mx-2 py-2 border-bottom text-secondary"><b>GRADE</b></h4>
                    <div style="display: grid;">
                        <span class="px-2 py-1">Ordinary Level</span>
                        <span class="px-2 py-1">Advanced Level</span>
                        <span class="px-2 py-1">Other</span>
                    </div>
                </div> -->
      </div>
    </div>
    <div class="row col-12 p-0" style="align-items: center" *ngIf="teacher">
      <img
        src="{{ teacher.profilePic }}"
        alt=""
        width="70px"
        height="70px"
        class="rounded-circle ml-3 my-3"
      />
      <span class="pl-2 pl-sm-4" style="font-size: 1.3em; font-weight: 500">
        {{ teacher.teacherName }}
      </span>
    </div>
  </div>
  <div>
    <mat-tab-group
      animationDuration="0ms"
      #tabRef
      (selectedTabChange)="tabChanged($event)"
    >
      <mat-tab label="Papers/Books">
        <div class="row m-0 p-0 mt-4">
          <ng-template [ngIf]="notes.length !== 0">
            <ng-template
              [ngIf]="!note.webLink"
              *ngFor="let note of notes; index as i"
            >
              <ng-template [ngIf]="noteLock[i]" [ngIfElse]="noteLocked">
                <div
                  class="col-xl-3 col-md-4 col-sm-6 col-12 m-0 p-0 mb-4 px-3 pointer"
                >
                  <a
                    href="{{ note.noteUrl }}"
                    target="_blank"
                    style="text-decoration: none; color: black"
                  >
                    <div class="row m-0 p-0">
                      <div
                        class="mydiv p-0 col-12 text-center item-image-size"
                        (mouseover)="darkenDiv(note.noteId)"
                        (mouseout)="normalDiv()"
                      >
                        <p [hidden]="darken !== note.noteId">
                          {{ note.noteDescription }}
                        </p>
                      </div>
                      <div class="p-0 col-12">
                        <span class="mt-2" style="font-size: 1em">{{
                          note.noteTitle
                        }}</span>
                        <br />
                        <span class="mt-2" style="font-size: 0.9em"
                          >Uploaded on: {{ note.publishDate }}</span
                        >
                      </div>
                    </div>
                  </a>
                </div>
              </ng-template>
              <ng-template #noteLocked>
                <div
                  class="col-xl-3 col-md-4 col-sm-6 col-12 m-0 p-0 mb-4 px-3 pointer"
                  (click)="getMessage(note.classId, note.publishDate)"
                >
                  <div
                    class="col-12 row m-0 p-0 item-image-size"
                    style="align-items: center"
                  >
                    <div
                      class="col-12 p-0 pointer item-image-size"
                      style="
                        position: absolute;
                        opacity: 0.2;
                        background-image: url('../../assets/templates/note_thumb.jpg');
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-position: center;
                      "
                    ></div>
                    <div class="col-12 text-center">
                      <i class="material-icons pointer" style="font-size: 3em"
                        >lock</i
                      ><br />
                      <span class="pointer" style="font-size: 1.2em"
                        >Unlock This Note</span
                      ><br />
                    </div>
                  </div>
                  <span class="mt-2" style="font-size: 1em">{{
                    note.noteTitle
                  }}</span>
                </div>
              </ng-template>
            </ng-template>
          </ng-template>
        </div>
      </mat-tab>
      <mat-tab label="Web Links">
        <ng-template [ngIf]="notes.length !== 0">
          <div class="row m-0 p-0 mt-4 mx-md-3">
            <div *ngFor="let webLink of notes; index as j" class="col-12 p-0">
              <div *ngIf="webLink.webLink">
                <ng-template
                  [ngIf]="activeLink == -1 || activeLink != webLink.noteId"
                >
                  <ng-template [ngIf]="noteLock[j]" [ngIfElse]="weblinkLocked">
                    <div
                      (click)="linkActive(webLink.noteId)"
                      class="row m-0 p-0 col-12 my-3 px-3 px-sm-4 py-3 rounded bg-white border"
                      style="align-items: center"
                    >
                      <div class="weblink-title col-7 col-sm-9 col-lg-10 p-0">
                        <span>WebLink: {{ webLink.webUrl }}</span>
                      </div>
                      <div
                        class="col-5 col-sm-3 col-lg-2 row m-0 p-0"
                        style="align-items: center"
                      >
                        <span
                          class="text-secondary weblink-details"
                          style="margin-left: auto; margin-right: 0%"
                          >{{ webLink.publishDate }}</span
                        >
                        <span
                          class="material-icons text-secondary ml-3 pointer weblink-arrow"
                          >keyboard_arrow_down</span
                        >
                      </div>
                    </div>
                  </ng-template>
                  <ng-template #weblinkLocked>
                    <div
                      (click)="getMessage(webLink.classId, webLink.publishDate)"
                      class="row m-0 p-0 col-12 my-3 px-3 px-sm-4 py-3 rounded bg-light border"
                      style="align-items: center"
                    >
                      <div class="weblink-title col-6 col-sm-8 col-lg-9 p-0">
                        <span>WebLink: {{ webLink.webUrl }}</span>
                      </div>
                      <div
                        class="col-6 col-sm-4 col-lg-3 row m-0 p-0"
                        style="align-items: center"
                      >
                        <span
                          class="text-secondary weblink-details"
                          style="margin-left: auto; margin-right: 0%"
                          >Unlock WebLink</span
                        >
                        <span class="material-icons text-secondary ml-3 pointer"
                          >lock</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-template>
                <ng-template [ngIf]="activeLink == webLink.noteId">
                  <div
                    class="row m-0 p-0 col-12 my-3 px-3 px-sm-4 py-3 rounded bg-white border"
                    style="align-items: center"
                  >
                    <div class="container-fluid">
                      <div
                        class="row pb-2 border-bottom"
                        style="align-items: center"
                        (click)="linkDeactive()"
                      >
                        <div class="weblink-title col-7 col-sm-9 col-lg-10 p-0">
                          <span>WebLink: {{ webLink.webUrl }}</span>
                        </div>
                        <div
                          class="col-5 col-sm-3 col-lg-2 row m-0 p-0"
                          style="align-items: center"
                        >
                          <span
                            class="text-secondary weblink-details"
                            style="margin-left: auto; margin-right: 0%"
                            >{{ webLink.publishDate }}</span
                          >
                          <span
                            class="material-icons text-secondary ml-2 pointer weblink-arrow"
                            >keyboard_arrow_left</span
                          >
                        </div>
                      </div>
                      <div class="mt-3 row" style="align-items: center">
                        <img
                          src="{{ teacher.profilePic }}"
                          width="40"
                          height="40"
                          alt=""
                          class="rounded-circle"
                        />
                        <span class="ml-3 weblink-details"
                          ><b>{{ teacher.teacherName }}</b></span
                        >
                      </div>
                      <div class="mt-3 offset-1 col-10 weblink-details">
                        <p>{{ webLink.noteDescription }}</p>
                      </div>
                    </div>
                    <a
                      class="weblink-details"
                      style="margin-left: auto; margin-right: 0%"
                      href="{{ webLink.webUrl }}"
                      target="_blank"
                      >Go to this Link</a
                    >
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="center" *ngIf="loadingList && notes.length !== 0">
    <ng-lottie
      style="
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
        padding-bottom: 50px;
      "
      [options]="loadingDot"
      width="80px"
      height="auto"
    >
    </ng-lottie>
  </div>
  <div class="row m-0 p-0 mt-5 text-center" *ngIf="notes.length === 0">
    <ng-lottie
      class="lottie-container"
      style="margin-left: auto; margin-right: auto"
      [options]="option"
      width="100%"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-4">
      <h2>No Notes Available...</h2>
    </div>
  </div>
</div>
