<div class="bg-white rounded container p-0">
  <div class="row m-0 p-0 px-3 py-2 border-bottom" style="align-items: center">
    <h2 class="m-0"><b>Payment Manager</b></h2>
    <span
      class="material-icons"
      mat-dialog-close
      style="margin-left: auto; margin-right: 0%"
      >close</span
    >
  </div>
  <mat-horizontal-stepper
    linear
    #stepper
    *ngIf="paymentState && !processDone && !systemError"
  >
    <mat-step label="Confirm Details">
      <h2 class="on-mobile text-center">Confirm Details</h2>
      <div class="px-4 py-sm-3 pb-3 pb-sm-0">
        <h3 class="mt-3" *ngIf="dataLoaded && validProfile">Class Details</h3>
        <div *ngIf="dataLoaded && validProfile">
          <div class="row m-0">
            <span class="optionValue col-6 col-sm-4">Teacher's Name : </span>
            <span class="optionValue col-6 col-sm-8">{{
              myClass.teacherName
            }}</span>
          </div>
          <div class="row m-0">
            <span class="optionValue col-6 col-sm-4">Subject : </span>
            <span class="optionValue col-6 col-sm-8">{{
              myClass.subjectName
            }}</span>
          </div>
          <div class="row m-0">
            <span class="optionValue col-6 col-sm-4">Grade : </span>
            <span class="optionValue col-6 col-sm-8">{{
              myClass.gradeName
            }}</span>
          </div>
          <div class="row m-0">
            <span class="optionValue col-6 col-sm-4">Day & Time :</span>
            <span class="optionValue col-6 col-sm-8">{{ dayAndTime }}</span>
          </div>
          <div class="row m-0">
            <span class="optionValue col-6 col-sm-4">Month :</span>
            <span class="optionValue col-6 col-sm-8">{{ payMonth }}</span>
          </div>
          <div class="row m-0">
            <span class="optionValue col-6 col-sm-4">Year :</span>
            <span class="optionValue col-6 col-sm-8">{{ payYear }}</span>
          </div>
        </div>
        <div class="row m-0 p-0 p-4 text-center" *ngIf="!validProfile">
          <ng-lottie
            style="margin-left: auto; margin-right: auto"
            [options]="option4"
            width="150px"
            height="auto"
          >
          </ng-lottie>
          <div class="col-12 mt-3">
            <p style="font-size: 1.2em; color: gray">
              Oops, Your profile is not complete yet. Complete your profile with
              valid details.
            </p>
          </div>
        </div>
        <div class="mt-4 row m-0 p-0" *ngIf="dataLoaded && validProfile">
          <span
            class="px-3 py-2 rounded btn-purple text-white"
            style="margin-left: auto; margin-right: 0%; font-size: 0.8em"
            (click)="moveStep(2)"
            >Confirm</span
          >
        </div>
      </div>
    </mat-step>
    <mat-step label="Setup Payment">
      <h2 class="on-mobile text-center">Setup Payment</h2>
      <div class="px-4 py-sm-3 pb-3 pb-sm-0">
        <p *ngIf="postalPaid">
          Postal Fee: <b>{{ postalFee }}</b>
          <small><b> (Already Paid)</b></small>
        </p>
        <mat-checkbox
          *ngIf="!postalPaid"
          class="example-margin"
          (change)="onPostalChange($event)"
          [(ngModel)]="postalService"
        >
          Send my study materials via postal
        </mat-checkbox>
        <p>Select Week:</p>
        <div class="row m-0">
          <div
            id="week1"
            class="weekBlock center"
            (click)="setWeek(0)"
            [ngStyle]="{
              'background-color':
                weeks[0] || disabledWeeks[0] ? '#95a5a6' : 'white',
              'pointer-events': disabledWeeks[0] ? 'none' : 'auto',
              opacity: disabledWeeks[0] ? '0.4' : '1',
              border: borderWeeks[0] ? '2px solid #f89406' : '0px none'
            }"
            matTooltip="Relevant Week"
            [matTooltipDisabled]="!borderWeeks[0]"
          >
            <span><b>Week 1</b></span>
            <br />
            <span class="littleTxt">+{{ weekPricesStr[0] }}</span>
          </div>
          <div
            id="week2"
            class="weekBlock center"
            (click)="setWeek(1)"
            [ngStyle]="{
              'background-color':
                weeks[1] || disabledWeeks[1] ? '#95a5a6' : 'white',
              'pointer-events': disabledWeeks[1] ? 'none' : 'auto',
              opacity: disabledWeeks[1] ? '0.4' : '1',
              border: borderWeeks[1] ? '2px solid #f89406' : '0px none'
            }"
            matTooltip="Relevant Week"
            [matTooltipDisabled]="!borderWeeks[1]"
          >
            <span><b>Week 2</b></span>
            <br />
            <span class="littleTxt">+{{ weekPricesStr[1] }}</span>
          </div>
          <div
            id="week3"
            class="weekBlock center"
            (click)="setWeek(2)"
            [ngStyle]="{
              'background-color':
                weeks[2] || disabledWeeks[2] ? '#95a5a6' : 'white',
              'pointer-events': disabledWeeks[2] ? 'none' : 'auto',
              opacity: disabledWeeks[2] ? '0.4' : '1',
              border: borderWeeks[2] ? '2px solid #f89406' : '0px none'
            }"
            matTooltip="Relevant Week"
            [matTooltipDisabled]="!borderWeeks[2]"
          >
            <span><b>Week 3</b></span>
            <br />
            <span class="littleTxt">+{{ weekPricesStr[2] }}</span>
          </div>
          <div
            id="week4"
            class="weekBlock center"
            (click)="setWeek(3)"
            [ngStyle]="{
              'background-color':
                weeks[3] || disabledWeeks[3] ? '#95a5a6' : 'white',
              'pointer-events': disabledWeeks[3] ? 'none' : 'auto',
              opacity: disabledWeeks[3] ? '0.4' : '1',
              border: borderWeeks[3] ? '2px solid #f89406' : '0px none'
            }"
            matTooltip="Relevant Week"
            [matTooltipDisabled]="!borderWeeks[3]"
          >
            <span><b>Week 4</b></span>
            <br />
            <span class="littleTxt">+{{ weekPricesStr[3] }}</span>
          </div>
        </div>
        <hr />
        <span style="font-size: 14px; color: #2e3131" *ngIf="postalService"
          >Postal Delivery &nbsp; <span>{{ getPostalCharge() }}</span></span
        >
        <br *ngIf="postalService" />
        <span style="font-size: 18px; font-weight: bold"
          >TOTAL &nbsp; <span>{{ getTotal() }}</span></span
        >
        <div class="mt-4 row m-0 p-0">
          <span
            class="px-3 py-2 rounded btn-purple"
            style="margin-left: auto; margin-right: 0%; font-size: 0.8em"
            (click)="moveStep(2)"
            >Next</span
          >
        </div>
      </div>
    </mat-step>
    <mat-step label="Payment Method">
      <h2 class="on-mobile text-center">Payment Method</h2>
      <div
        class="px-4 py-sm-3 pb-3 pb-sm-0"
        style="overflow-y: auto; max-height: 70vh; overflow-x: hidden"
      >
        <div class="py-2 border-bottom">
          <span style="font-size: 1.1em; font-weight: bold"
            >Payment Ref: &nbsp; {{ getOrderId() }}
          </span>
        </div>
        <div class="py-2 border-bottom">
          <span style="font-size: 0.9em; color: #2e3131" *ngIf="postalService"
            >Postal Delivery: &nbsp;{{ getPostalCharge() }}</span
          >
          <br *ngIf="postalService" />
          <span style="font-size: 1em; font-weight: bold"
            >TOTAL: &nbsp;{{ getTotal() }}</span
          >
        </div>
        <p class="mt-2" style="font-size: 0.9em0; font-weight: bold">
          Select your payment method:
        </p>
        <mat-radio-group
          aria-label="Select an option"
          [(ngModel)]="paymentMethod"
        >
          <!-- <mat-radio-button value='1'>Payhere</mat-radio-button> -->
          <mat-radio-button
            value="2"
            style="margin-left: 20px; font-size: 0.9em"
            >Bank Deposite
          </mat-radio-button>
        </mat-radio-group>
        <br />
        <!-- **Do not delete this segment -->
        <!-- <div class="mt-3" *ngIf="paymentMethod==='1'" style=" display: inline-block;">
                    <div class="center" style="width: 100%;">
                        <img style="margin-right: auto;margin-left: auto; align-items: center;"
                            src="../../assets/templates/payments.png" alt="PayHere" width="180" />
                    </div>
                    <br>
                    <a class="center" style="cursor: pointer;" (click)="payHere()"><img
                            src="https://www.payhere.lk/downloads/images/pay_with_payhere.png" alt="Pay with PayHere"
                            width="190" />
                    </a>
                </div> -->
        <div class="row m-0 mt-3" *ngIf="paymentMethod === '2'">
          <div class="col-12 p-0">
            <mat-expansion-panel
              (opened)="panelOpenState = true"
              (closed)="panelOpenState = false"
            >
              <mat-expansion-panel-header>
                <mat-panel-title class="mt-3">
                  <h4>Payment Instructions</h4>
                </mat-panel-title>
                <mat-panel-description class="mt-3 littleTxt">
                  Click to {{ panelOpenState ? "hide" : "view" }}
                </mat-panel-description>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <ol>
                  <li>Bank Deposit Details</li>
                  <p
                    class="littleTxt"
                    style="color: #2e3131; padding-left: 5px"
                  >
                    Account Number : 88514407 <br />
                    Account Name : International Digi Services Network (Pvt)Ltd
                    <br />
                    Bank : Bank of Ceylon <br />
                    Branch : Boralesgamuwa
                  </p>
                  <li>Take a clear image or a copy of your payment slip.</li>
                  <li>Upload your file below.</li>
                </ol>
              </ng-template>
            </mat-expansion-panel>
            <!-- <div>
                            Account Number : 70411398
                            Acc. Holder’s name : GPPN Wickramasinghe
                            Bank : Bank of Ceylon
                            Branch : Divlapitiya
                        </div> -->
            <div class="bankInput mt-3">
              <input
                class="center"
                id="bankslip"
                type="file"
                (change)="selectFile($event)"
              />
              <mat-progress-bar
                class="mt-2"
                mode="determinate"
                [value]="progress.percentage"
              >
              </mat-progress-bar>
            </div>
          </div>
          <br />
          <div class="mt-4" style="margin-left: auto; margin-right: 0%">
            <span
              class="px-3 py-2 rounded btn-outline-purple"
              style="font-size: 0.8em"
              (click)="proceedUpload()"
              *ngIf="!fileUploaded"
              >Upload</span
            >
            <span
              class="px-3 py-2 rounded btn-purple ml-3 text-white"
              style="font-size: 0.8em"
              (click)="finishUpload()"
              [ngStyle]="{
                'pointer-events': fileUploaded ? 'auto' : 'none',
                opacity: fileUploaded ? '1' : '0.4'
              }"
              >Finish</span
            >
          </div>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  <div class="row m-0 p-0 p-4 text-center" *ngIf="systemError">
    <ng-lottie
      style="margin-left: auto; margin-right: auto"
      [options]="option3"
      width="150px"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-3">
      <p style="font-size: 1.2em; color: gray">
        Oops, something went wrong.
        <span *ngIf="paymentMethod === '1'"
          >For any inquiry, please contact
          <a href="tel:+9476 939 4630">(+94) 076 939 4630</a>
        </span>
      </p>
    </div>
  </div>
  <div class="row m-0 p-0 px-4 pt-4 pb-2 text-center" *ngIf="!paymentState">
    <div class="col-12">
      <p style="font-size: 1.5em">Validating Payment</p>
    </div>
    <ng-lottie
      class="mt-3"
      style="margin-left: auto; margin-right: auto"
      [options]="option2"
      width="250px"
      height="auto"
    ></ng-lottie>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="row m-0 p-0 p-4 text-center" *ngIf="processDone">
    <ng-lottie
      style="margin-left: auto; margin-right: auto"
      [options]="option1"
      width="250px"
      height="auto"
    >
    </ng-lottie>
    <div class="col-12 mt-3">
      <p style="font-size: 1.5em">Thank you for your payment.</p>
      <p *ngIf="paymentMethod === '2'">
        We will check your payment and approve it within few hours. For any
        inquiry, please contact
        <a href="tel:+9476 939 4630">(+94) 076 939 4630</a>
      </p>
      <span
        (click)="closeMe()"
        class="btn btn-purple text-white mt-3"
        style="width: 75.59px"
        >Exit</span
      >
    </div>
  </div>
</div>
