import { Injectable } from '@angular/core';
import { DatabaseManager } from '../database/database-manager';
import { ClassAd } from '../models/classad';
@Injectable({
  providedIn: 'root',
})
export class AdvertisementService {
  private static advertisements: ClassAd[] = [];
  private static gradeAdds: ClassAd[] = [];

  private static currentAdIndex = -1;
  private static currectGradeAdIndex = -1;

  constructor(private db: DatabaseManager) {
    this.db.getAllAdvertisement(this.AdLimit).then((res) => {
      AdvertisementService.advertisements = res;
    });
  }
  // I know its a mess, Please Dont touch it its working! Thank you . . .
  gradeId: string = '';
  AdLimit: number = 10;
  bufferd: number = 0;

  // getNewAdvertisement() {
  //   return new Promise((resolve) => {
  //     if (AdvertisementService.advertisements.length > 0) {
  //       if (
  //         AdvertisementService.currentAdIndex ===
  //         AdvertisementService.advertisements.length - 1
  //       ) {
  //         // AdvertisementService.currentAdIndex = 0;
  //         this.db
  //           .getReaminingAds(
  //             AdvertisementService.advertisements[
  //               AdvertisementService.currentAdIndex
  //             ].classAdId,
  //             this.AdLimit
  //           )
  //           .then((res) => {
  //             if (res.length == 0) {
  //               AdvertisementService.currentAdIndex = 0;

  //               resolve(
  //                 AdvertisementService.advertisements[
  //                   AdvertisementService.currentAdIndex
  //                 ]
  //               );
  //             } else {
  //               AdvertisementService.advertisements =
  //                 AdvertisementService.advertisements.concat(res);
  //               AdvertisementService.currentAdIndex += 1;

  //               resolve(
  //                 AdvertisementService.advertisements[
  //                   AdvertisementService.currentAdIndex
  //                 ]
  //               );
  //             }
  //           });
  //       } else {
  //         AdvertisementService.currentAdIndex += 1;

  //         resolve(
  //           AdvertisementService.advertisements[
  //             AdvertisementService.currentAdIndex
  //           ]
  //         );
  //       }
  //     } else {
  //       this.db.getAllAdvertisement(this.AdLimit).then((res) => {
  //         AdvertisementService.advertisements = res;
  //         if (
  //           AdvertisementService.currentAdIndex ===
  //           AdvertisementService.advertisements.length - 1
  //         ) {
  //           AdvertisementService.currentAdIndex = 0;
  //         } else {
  //           AdvertisementService.currentAdIndex += 1;
  //         }

  //         resolve(
  //           AdvertisementService.advertisements[
  //             AdvertisementService.currentAdIndex
  //           ]
  //         );
  //       });
  //     }
  //   });
  // }

  isAdsAvailable() {
    return AdvertisementService.advertisements.length > 0;
  }

  getNewAds() {
    return new Promise((resolve) => {
      if (AdvertisementService.advertisements.length > 0) {
        if (
          AdvertisementService.advertisements.length - 1 ===
          AdvertisementService.currentAdIndex
        ) {
          let classAdId =
            AdvertisementService.advertisements[
              AdvertisementService.currentAdIndex
            ].classAdId;
          this.db.getReaminingAds(classAdId, this.AdLimit).then((res) => {
            if (res.length > 0) {
              AdvertisementService.advertisements =
                AdvertisementService.advertisements.concat(res);
              AdvertisementService.currentAdIndex += 1;

              resolve(
                AdvertisementService.advertisements[
                  AdvertisementService.currentAdIndex
                ]
              );
            } else {
              if (
                AdvertisementService.advertisements.length - 1 ===
                AdvertisementService.currentAdIndex
              ) {
                AdvertisementService.currentAdIndex = 0;
              } else {
                AdvertisementService.currentAdIndex += 1;
              }

              resolve(
                AdvertisementService.advertisements[
                  AdvertisementService.currentAdIndex
                ]
              );
            }
          });
        } else {
          AdvertisementService.currentAdIndex += 1;

          resolve(
            AdvertisementService.advertisements[
              AdvertisementService.currentAdIndex
            ]
          );
        }
      } else {
        this.db.getAllAdvertisement(this.AdLimit).then((res) => {
          if (res.length > 0) {
            AdvertisementService.advertisements = res;
            AdvertisementService.currentAdIndex += 1;

            resolve(
              AdvertisementService.advertisements[
                AdvertisementService.currentAdIndex
              ]
            );
          }
        });
      }
    });
  }

  getGradeAdvertisement(gradeId: string) {
    return new Promise((resolve) => {
      if (
        AdvertisementService.gradeAdds.length > 0 &&
        gradeId === this.gradeId
      ) {
        if (
          AdvertisementService.currectGradeAdIndex ===
          AdvertisementService.gradeAdds.length - 1
        ) {
          // AdvertisementService.currentAdIndex = 0;
          this.db
            .getRemainingAdvertisementByGrade(
              gradeId,
              this.AdLimit,
              AdvertisementService.gradeAdds[
                AdvertisementService.currectGradeAdIndex
              ].classAdId
            )
            .then((res) => {
              if (res.length == 0) {
                AdvertisementService.currectGradeAdIndex = 0;
                resolve(
                  AdvertisementService.gradeAdds[
                    AdvertisementService.currectGradeAdIndex
                  ]
                );
              } else {
                AdvertisementService.gradeAdds =
                  AdvertisementService.gradeAdds.concat(res);
                AdvertisementService.currectGradeAdIndex += 1;

                resolve(
                  AdvertisementService.gradeAdds[
                    AdvertisementService.currectGradeAdIndex
                  ]
                );
              }
            });
        } else {
          AdvertisementService.currectGradeAdIndex += 1;
        }

        resolve(
          AdvertisementService.gradeAdds[
            AdvertisementService.currectGradeAdIndex
          ]
        );
      } else {
        this.db.getAdvertisementByGrade(gradeId, this.AdLimit).then((res) => {
          this.gradeId = gradeId;
          AdvertisementService.gradeAdds = res;
          AdvertisementService.currectGradeAdIndex = 0;
          if (AdvertisementService.advertisements.length > 0) {
            AdvertisementService.gradeAdds =
              AdvertisementService.gradeAdds.concat(
                AdvertisementService.advertisements
              );
          }

          if (
            AdvertisementService.currectGradeAdIndex ===
            AdvertisementService.gradeAdds.length - 1
          ) {
            AdvertisementService.currectGradeAdIndex = 0;
          } else {
            AdvertisementService.currectGradeAdIndex += 1;
          }

          resolve(
            AdvertisementService.gradeAdds[
              AdvertisementService.currectGradeAdIndex
            ]
          );
        });
      }
    });
  }
}
