<div class="py-2 m-0">
    <div class="row m-0" style="align-items: center;background-color: rgb(253, 253, 253);">
        <span class="material-icons pointer" routerLink="/live-classes"><b>arrow_back</b></span>
        <span class="ml-3" style="font-size: large;" *ngIf="classForTitle">Live Classes for
                {{classForTitle}}</span>
    </div>
    <div class="mt-4" *ngIf="liveClasses.length!==0">
        <div *ngFor="let class of liveClasses;index as i">
            <ng-template class="row m-0 p-0" [ngIf]="!class.extraClass" [ngIfElse]=extra>
                <div class="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
                    <ng-template [ngIf]="liveLock[i]" [ngIfElse]=liveClass>
                        <div class="pointer"
                            (click)="openZoomMeeting(checkTimeStatus(getScheduledDate(class.day,class.startingTime,class.endingTime)), class.liveId)">
                            <div class="mb-1 text-white text-center">
                                <img src="../../assets/templates/liveclass_thumb.jpg"
                                    class="col-12 m-0 p-0 item-image-size" alt=""
                                    style="-webkit-filter: brightness(60%);">
                                <span style="position: absolute; margin-left:-55%;margin-top: 9vh;">Live
                                    class</span>
                            </div>
                            <div class="container-fluid m-0 p-0">
                                <div class="row m-0 p-0">
                                    <span style="font-size: 1.2em;"><b>{{class.classTitle}}</b></span>
                                    <div style="margin-left: auto;margin-right: 0%;">
                                        <div class="row m-0 p-0" style="align-items: center;">
                                            <div class="rounded-circle"
                                                style="width: 10px;height: 10px; background-color:#1cb31c ;"
                                                *ngIf="checkTimeStatus(getScheduledDate(class.day,class.startingTime,class.endingTime)) === 'Started'">
                                            </div>
                                            <div class="rounded-circle"
                                                style="width: 10px;height: 10px; background-color:#f84707 ;"
                                                *ngIf="checkTimeStatus(getScheduledDate(class.day,class.startingTime,class.endingTime)) === 'Scheduled'">
                                            </div>
                                            <div class="rounded-circle"
                                                style="width: 10px;height: 10px; background-color:red ;"
                                                *ngIf="checkTimeStatus(getScheduledDate(class.day,class.startingTime,class.endingTime)) === 'Ended'">
                                            </div>
                                            <span style="font-size: .8em;"><b>
                                                    {{checkTimeStatus(getScheduledDate(class.day,class.startingTime,class.endingTime))}}
                                                </b></span>
                                        </div>
                                    </div>
                                </div>
                                <span
                                    style="font-size: .7em;">{{timeTo12Hrs(getScheduledDate(class.day,class.startingTime,class.endingTime))}}</span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #liveClass>
                        <div class="pointer"
                            (click)="getMessage(class.classId,getScheduledDate(class.day,class.startingTime,class.endingTime)[0])">
                            <div class="col-12 row m-0 p-0 p-0 item-image-size" style="align-items: center;">
                                <img src="../../assets/templates/liveclass_thumb.jpg" alt=""
                                    class="col-12 m-0 p-0 mb-1 pointer item-image-size"
                                    style="position: absolute;opacity: 0.2;">
                                <div class="col-12 text-center">
                                    <i class="material-icons pointer" style="font-size: 3em;">lock</i><br>
                                    <span class="pointer" style="font-size: 1.2em;">Unlock This Live Class</span><br>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
            <ng-template class="row m-0 p-0" #extra *ngIf="checkExtraDate(class.extraClassDate)">
                <div class="col-xl-3 col-lg-4 col-sm-6 col-12 mb-4">
                    <ng-template [ngIf]="liveLock[i]" [ngIfElse]=extraLiveClass>
                        <div class="pointer" (click)="openZoomMeeting(extraClzTimeStatus(class.extraClassDate,
                        class.startingTime,class.endingTime), class.liveId)">
                            <div class="mb-1 text-white text-center">
                                <img src="../../assets/templates/liveclass_thumb.jpg"
                                    class="col-12 m-0 p-0 item-image-size" alt=""
                                    style="-webkit-filter: brightness(60%);">
                                <span style="position: absolute; margin-left:-55%;margin-top: 9vh;">Extra
                                    class</span>
                            </div>
                            <div class="container-fluid m-0 p-0">
                                <div class="row m-0 p-0">
                                    <span style="font-size: 1.2em;"><b>{{class.classTitle}}</b></span>
                                    <div style="margin-left: auto;margin-right: 0%;">
                                        <div class="row m-0 p-0" style="align-items: center;">
                                            <div class="rounded-circle"
                                                style="width: 10px;height: 10px; background-color:#1cb31c ;" *ngIf="extraClzTimeStatus(class.extraClassDate,
                                        class.startingTime,class.endingTime) === 'Started'">
                                            </div>
                                            <div class="rounded-circle"
                                                style="width: 10px;height: 10px; background-color:#f84707 ;" *ngIf="extraClzTimeStatus(class.extraClassDate,
                                        class.startingTime,class.endingTime) === 'Scheduled'">
                                            </div>
                                            <div class="rounded-circle"
                                                style="width: 10px;height: 10px; background-color:red ;" *ngIf="extraClzTimeStatus(class.extraClassDate,
                                        class.startingTime,class.endingTime) === 'Ended'">
                                            </div>
                                            <span style="font-size: .8em;"><b>{{extraClzTimeStatus(class.extraClassDate,
                                                    class.startingTime,class.endingTime)}}</b></span>
                                        </div>
                                    </div>
                                </div>
                                <span style="font-size: .7em;"> {{class.extraClassDate}}
                                    {{timeStr12hrs(class.startingTime)}} -
                                    {{timeStr12hrs(class.endingTime)}}
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #extraLiveClass>
                        <div class="pointer"
                            (click)="getMessage(class.classId,getScheduledDate(class.day,class.startingTime,class.endingTime)[0])">
                            <div class="col-12 row m-0 p-0 border p-0 item-image-size" style="align-items: center;">
                                <img src="../../assets/templates/liveclass_thumb.jpg" alt=""
                                    class="col-12 m-0 p-0 mb-1 pointer item-image-size"
                                    style="position: absolute;opacity: 0.2;">
                                <div class="col-12 text-center">
                                    <i class="material-icons pointer" style="font-size: 3em;">lock</i><br>
                                    <span class="pointer" style="font-size: 1.2em;">Unlock This Live Class</span><br>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-template>
        </div>
    </div>
    <div class="row m-0 p-0 mt-5 text-center" *ngIf="liveClasses.length===0">
        <ng-lottie class="lottie-container" style="margin-left: auto;margin-right: auto;" [options]="option" width="100%" height="auto">
        </ng-lottie>
        <div class="col-12 mt-4">
            <h2>No Live Classes Available...</h2>
        </div>
    </div>
</div>