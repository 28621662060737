<div class="container-fluid w-100 p-0 h-100" *ngIf="advertisement">
  <ng-template [ngIf]="advertisement.adFileType == 0" [ngIfElse]="video">
    <div
      class="w-100 item-img-ad media"
      [ngStyle]="{ 'background-image': 'url(' + advertisement.adFileUrl + ')' }"
    ></div>
  </ng-template>
  <ng-template #video>
    <div>
      <video
        disablePictureInPicture
        [muted]="true"
        class="w-100 media"
        autoplay
        loop
      >
        <source [src]="advertisement.adFileUrl" />
      </video>
    </div>
  </ng-template>
  <div class="absolute" *ngIf="!load">
    <ng-lottie [options]="loadingDot" width="100%" height="100%"></ng-lottie>
  </div>
</div>
<div *ngIf="!advertisement">
  <ng-lottie [options]="loadingDot" width="100%" height="100%"></ng-lottie>
</div>
