import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseManager } from '../database/database-manager';
import { DigiConfernce } from '../models/digi-conference';
import { ConferencePayment } from '../models/conference-payment';
import { AuthService } from '../services/auth.service';
import { UploadService } from '../services/upload.service';
import { Student } from '../models/student';
import { PayhereClsVerification } from '../models/payhereclass';
import { AlertService } from '../services/alert.service';
import { MessageButtons, MessageType } from '../messagebox/messagebox.component';
import { AnimationOptions } from 'ngx-lottie';

declare var payhere;
@Component({
  selector: 'app-pay-digi-conference',
  templateUrl: './pay-digi-conference.component.html',
  styleUrls: ['./pay-digi-conference.component.scss'],
})
export class PayDigiConferenceComponent implements OnInit {

  //private variables
  //private readonly MERCHANT_ID: string = "1220895";
  //private readonly MERCHANT_ID: string = "1220895";// sandbox key
  private amount: number = 0.0;

  systemError: boolean = false;
  processDone: boolean = false;
  paymentState: boolean = true;
  fileUploaded = false;
  paymentMethod: any = '2';
  panelOpenState = false;
  conferenceDetails: DigiConfernce;
  orderId: string;
  bankSlip: File;
  progress: { percentage: number } = { percentage: 0 };
  slipImagePath: string;
  studentId: string;

  //starting lottie config
  option1: AnimationOptions = {
    path: '/assets/lti/lti_check.json', loop: false
  };
  option2: AnimationOptions = {
    path: '/assets/lti/lti_wait.json', loop: true
  };
  option3: AnimationOptions = {
    path: '/assets/lti/lti_error.json', loop: false
  };
  option4: AnimationOptions = {
    path: '/assets/lti/lti_noprofile.json', loop: true
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public conference,
    private uploadService: UploadService, private alertService: AlertService,
    private auth: AuthService,
    private db: DatabaseManager,
    private matref: MatDialogRef<PayDigiConferenceComponent>
  ) {
    this.amount = this.conference.conferencePrice;
  }

  ngOnInit(): void {
    this.conferenceDetails = this.conference;
    this.generateOrderId();
    this.auth
      .isUserAvailable()
      .then((res) => (this.studentId = this.auth.userData.uid))
      .catch((err) => console.error(err));
  }

  payForClass(studentId: string, paymentStat: number) {
    let paymentDetails: ConferencePayment = {
      conferenceId: this.conferenceDetails.conferenceId,
      teacherId: this.conferenceDetails.teacherId,
      paymentState: paymentStat,
      bnkSlipUrl: this.slipImagePath,
      conferenceTitle: this.conferenceDetails.conferenceTitle,
      conferenceBanner: this.conferenceDetails.conferenceBannerUrl,
      studentId: studentId,
      dateandtime: new Date().toLocaleString(),
      studentName: null,
      price: parseInt(this.conferenceDetails.conferencePrice),
      email: null,
      schedule:
        this.conferenceDetails.day +
        ' | ' +
        this.conferenceDetails.startingTime +
        ' - ' +
        this.conferenceDetails.endingTime,
    };

    this.db.getStudent(this.studentId).then((res) => {
      paymentDetails.studentName = res.firstName + ' ' + res.lastName;
      paymentDetails.email = res.email;
      this.db.payForConference(paymentDetails);
      this.processDone = true;
    });
  }

  selectFile(event: any) {
    this.bankSlip = event.target.files;
  }

  proceedUpload() {
    this.uploadService
      .pushConferenceSlip(
        this.bankSlip[0],
        this.progress,
        this.studentId,
        this.conferenceDetails.conferenceId,
        this.generateFileId()
      )
      .then((res: string) => {
        this.slipImagePath = res;
        this.fileUploaded = true;
      })
      .catch((err) => console.log(err));
  }

  generateFileId() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }

  finishUpload() {
    this.payForClass(this.studentId, 0);
  }

  getOrderId(): string {
    return this.orderId;
  }

  private generateOrderId(): void {
    var date = new Date();
    var number = date.getTime().toString(16).toUpperCase();
    this.orderId = number;
  }

  getCurrencyStr(value: number): string {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'LKR',
    });
    return formatter.format(value);
  }

  getTotal(): string {
    return this.getCurrencyStr(this.amount);
  }

  //**Do not delete this segment
  // async payHere() {
  //   this.db.getStudent(this.auth.userData.uid).then(std => {
  //     this.payViaPayHere(std);
  //   });
  // }

  //**Do not delete this segment
  // payViaPayHere(std: Student) {
  //   var documentID = this.conferenceDetails.conferenceId;
  //   var payment = {
  //     "sandbox": true,
  //     "merchant_id": this.MERCHANT_ID,
  //     "return_url": "https://digipanthiya.com/digi-conference",
  //     "cancel_url": "https://digipanthiya.com",
  //     "notify_url": "https://us-central1-digipanthiya-bc66f.cloudfunctions.net/VerifyConfePayment",
  //     "order_id": this.orderId,
  //     "items": "Conference Payment - For " + this.conference.conferenceTitle,
  //     "amount": this.amount,
  //     "currency": "LKR",
  //     "first_name": std.firstName,
  //     "last_name": std.lastName,
  //     "email": std.email,
  //     "phone": std.studentTp1,
  //     "address": std.studentAddress,
  //     "city": std.city,
  //     "country": "Sri Lanka",
  //     "delivery_address": std.studentAddress,
  //     "delivery_city": std.city,
  //     "delivery_country": "Sri Lanka",
  //     "custom_1": std.studentId,
  //     "custom_2": documentID,
  //   };

  //   // Called when user completed the payment. It can be a successful payment or failure
  //   payhere.onCompleted = () => {
  //     this.paymentState = false;
  //     this.db.getPayherePay(std.studentId, documentID, 2).then((result: PayhereClsVerification) => {
  //       this.paymentState = true;
  //       if (result.verification === 1) {
  //         this.slipImagePath = "-";
  //         this.payForClass(std.studentId, 1);
  //       } else {
  //         this.systemError = true;
  //       }
  //     })
  //   }

  //   // Called when user closes the payment without completing
  //   payhere.onDismissed = () => {
  //     //Note: Prompt user to pay again or show an error page
  //     console.log("Payment dismissed");
  //     this.alertService.showAlert("Payment dismissed", MessageButtons.ok, MessageType.warning);
  //   };

  //   // Called when error happens when initializing payment such as invalid parameters
  //   payhere.onError = (error) => {
  //     // Note: show an error page
  //     console.log("Error:" + error);
  //     this.alertService.showAlert("Payment initializing failed. Please try again.", MessageButtons.ok, MessageType.warning);
  //   };

  //   payhere.startPayment(payment);
  // }

  closeMe() {
    this.matref.close();
  }

}
