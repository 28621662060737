<div class="py-2 row m-0">
  <div class="col-lg-10 col-12 p-0 border-bottom" style="align-items: center">
    <div class="row m-0 pointer mt-1" (click)="hideFilter()">
      <i class="material-icons" style="font-size: 1.5em">filter_alt</i>
      <span style="font-size: 1.2em">FILTER</span>
    </div>
    <div class="row m-0 my-3" [class.hiddenFilter]="isFilterHidden == true">
      <div class="mr-5 filterDivs">
        <h4 class="mx-2 py-2 border-bottom text-secondary"><b>Field</b></h4>
        <div>
          <span class="px-2 py-1" (click)="setSearchType('subjectName')"
            >Grade Name</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 m-0 p-0 mt-2">
    <h1>All Grades</h1>
    <div class="row m-0 p-0" *ngIf="grades.length !== 0">
      <ng-container *ngFor="let grade of grades; let i = index">
        <ng-template [ngIf]="!isAd(i)" [ngIfElse]="ad">
          <div class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4">
            <div class="pointer" (click)="navigateTutors(grade.gradeId)">
              <img
                src="{{ grade.gradeThumbnail }}"
                alt="{{ grade.gradeName }}"
                class="col-12 m-0 p-0 mb-1 pointer item-image-size"
              />
              <div class="col-10">
                <div class="row mt-1" style="align-items: center">
                  <span class="item-title-fs"
                    ><b>{{ grade.gradeName }}</b></span
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template #ad>
          <div
            class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4"
            *ngIf="isAdsAvailable()"
          >
            <div class="item-image-size ov-hidden">
              <app-advertisement-display></app-advertisement-display>
            </div>
          </div>

          <div class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4">
            <div class="pointer" (click)="navigateTutors(grade.gradeId)">
              <img
                src="{{ grade.gradeThumbnail }}"
                alt="{{ grade.gradeName }}"
                class="col-12 m-0 p-0 mb-1 pointer item-image-size"
              />
              <div class="col-10">
                <div class="row mt-1" style="align-items: center">
                  <span class="item-title-fs"
                    ><b>{{ grade.gradeName }}</b></span
                  >
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="center" *ngIf="loadingList && grades.length !== 0">
      <ng-lottie
        style="
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 100px;
          padding-bottom: 50px;
        "
        [options]="loadingDot"
        width="80px"
        height="auto"
      >
      </ng-lottie>
    </div>
    <div class="row m-0 p-0 mt-5 text-center" *ngIf="grades.length === 0">
      <ng-lottie
        class="lottie-container"
        style="margin-left: auto; margin-right: auto"
        [options]="option"
        width="100%"
        height="auto"
      >
      </ng-lottie>
      <div class="col-12 mt-4">
        <h2>No Grades Available...</h2>
      </div>
    </div>
  </div>
</div>
