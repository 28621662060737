/**
 * Profile Validator
 * Version 1.0
 */
import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Student } from '../models/student';
import { AuthService } from './auth.service';
import { Notification, NotificationService } from './notification.service';

export interface ValidateResult {
  isValid: boolean,
  validationMsg: string,
}

@Injectable({
  providedIn: 'root'
})
export class ProfileValidatorService {

  //cost
  private readonly DBPATH_STUDENT = "Student";
  private readonly BELL_COLOR_NORMAL: string = "#2e3131";
  private readonly NO_DIP: string = "assets/templates/noprofile.png";

  private validUser: boolean = false;
  student: Student

  constructor(@Inject(AngularFireDatabase) private firebase: AngularFireDatabase, private authService: AuthService,
    private notifyService: NotificationService, private firestore: AngularFirestore) {

    //wait until user connecting with the server
    authService.isUserAvailable().then(res => {
      if (res) {
        this.checkProfile();
      }
    });

  }

  private checkProfile() {
    if (this.authService.isLoggedIn) {
      this.firestore.collection(this.DBPATH_STUDENT).doc(this.authService.userData.uid).snapshotChanges()
        .pipe(
          map((a) => {
            const data = a.payload.data();
            return data;
          })
        )
        .subscribe((querySnapshot) => {

          this.student = <Student>querySnapshot;

          var validResult = this.validateUser(this.student)

          let message: Notification;
          if (!validResult.isValid) {
            message = {
              bellColor: "#f89406",
              message: validResult.validationMsg,
              priority: 1
            }
            this.validUser = false;
          } else {
            message = {
              bellColor: this.BELL_COLOR_NORMAL,
              message: "",
              priority: -1
            }
            this.validUser = true;
          }
          this.notifyService.sendNewMessage(message);
        });
    }
  }

  private validateUser(user: Student): ValidateResult {
    var validate: ValidateResult = {
      isValid: false,
      validationMsg: "000x1",
    }

    if (user.firstName.length <= 1) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Enter your first name"
      return validate;
    }
    try {
      if (user.lastName.length <= 1) {
        validate.isValid = false;
        validate.validationMsg = "Your profile is not complete yet. Enter your last name"
        return validate;
      }
    } catch (exception) { }
    if (user.nic.length < 5) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter your valid NIC number."
      return validate;
    }
    if (user.studentAddress.length <= 1) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Enter your address"
      return validate;
    }
    if (user.city.length <= 1) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Enter your city"
      return validate;
    }
    if ((user.province === "--Select--") || (user.province === "")) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please select your province"
      return validate;
    }
    if (user.postalCode.length !== 5) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Please enter your postal code."
      return validate;
    }
    if ((!this.validateTelephone(user.studentTp1)) && (!this.validateTelephone(user.studentTp2))) {
      validate.isValid = false;
      validate.validationMsg = "Your profile is not complete yet. Empty telephone details"
      return validate;
    }

    validate.isValid = true;
    validate.validationMsg = "no errors"
    return validate;
  }

  private validateTelephone(number: string): boolean {
    if ((number === " ") || (number === "")) {
      return false;
    }
    return true;
  }

  public isProfileValid(): boolean {
    return this.validUser;
  }

  public getUserProfilePic(): string {
    if ((this.student !== null) && (this.student !== undefined) && (this.student.profilePic !== ""))
      return this.student.profilePic;
    else
      return this.NO_DIP;
  }

  public getUser(): Student {
    return this.student;
  }
}