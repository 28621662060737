import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { database } from 'firebase';
import { AnimationOptions } from 'ngx-lottie';
import { ClassAd } from '../models/classad';
import { Video } from '../models/video';
import { AdvertisementService } from '../services/advertisement.service';
import { VideoComponent } from '../video/video.component';

@Component({
  selector: 'app-advertisement-display',
  templateUrl: './advertisement-display.component.html',
  styleUrls: ['./advertisement-display.component.scss'],
})
export class AdvertisementDisplayComponent implements OnInit {
  @Input() grade = undefined;
  thisgrade = '';
  advertisement: ClassAd;
  constructor(
    private adService: AdvertisementService,
    private elementRef: ElementRef
  ) {}
  isNotDestoyed = true;
  load = false;
  ngOnInit(): void {
    this.getData();
  }

  loadingDot: AnimationOptions = {
    path: '/assets/lti/lti_loading.json',
    loop: true,
  };

  @HostListener('unloaded')
  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
    this.isNotDestoyed = false;
  }
  async getData() {
    this.load = false;
    let timer = 0;
    if (this.isNotDestoyed) {
      if (this.grade === undefined) {
        this.adService.getNewAds().then((res: ClassAd) => {
          this.advertisement = res;

          const imgEle = document.createElement('img');

          imgEle.src = res.adFileUrl;
          imgEle.onload = () => {
            if (imgEle.width > 0) {
              this.load = true;
            }
          };

          if (this.advertisement.adFileType == 0) {
            timer = setTimeout(() => {
              this.getData();
            }, 10000);
          } else {
            const videoEle = document.createElement('video');
            videoEle.src = this.advertisement.adFileUrl;
            videoEle.preload = 'metadata';

            videoEle.onloadedmetadata = () => {
              if (videoEle.duration > 0) {
                this.load = true;
              }
              timer = setTimeout(
                () => this.getData(),
                videoEle.duration * 1000
              );
            };
          }
        });
      } else {
        this.adService
          .getGradeAdvertisement(this.grade)
          .then((res: ClassAd) => {
            this.advertisement = res;
            if (this.advertisement.adFileType == 0) {
              const imgEle = document.createElement('img');

              imgEle.src = res.adFileUrl;
              imgEle.onload = () => {
                if (imgEle.width > 0) {
                  this.load = true;
                }
              };
              setTimeout(() => {
                this.getData();
              }, 10000);
            } else {
              const videoEle = document.createElement('video');
              videoEle.src = this.advertisement.adFileUrl;
              videoEle.preload = 'metadata';
              videoEle.onloadedmetadata = () => {
                if (videoEle.duration > 0) {
                  this.load = true;
                }
                setTimeout(() => this.getData(), videoEle.duration * 1000);
              };
            }
          });
      }
    }
  }
}
