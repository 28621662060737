import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DatabaseManager } from '../database/database-manager';
import { Class } from '../models/class';
import { Payment } from '../models/payment';
import { PostalCharge } from '../models/postalCharge';
import { UploadService } from '../services/upload.service';
import { PaymentSet } from '../payment-manager/payment-set';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { DateTime } from '../util/datetime';
import { PaymentValidator } from '../payment-manager/payment-validator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnimationOptions } from 'ngx-lottie';
import { MatStepper } from '@angular/material/stepper';
import { ProfileValidatorService } from '../services/profile-validator.service';
import { Student } from '../models/student';
import { AlertService } from '../services/alert.service';
import {
  MessageButtons,
  MessageType,
} from '../messagebox/messagebox.component';
import { PayhereClsVerification } from '../models/payhereclass';

declare var payhere;
export interface ClassPayment {
  myClass: Class;
  date: Date;
}

@Component({
  selector: 'app-payment-manager',
  templateUrl: './payment-manager.component.html',
  styleUrls: ['./payment-manager.component.scss'],
})
export class PaymentManagerComponent implements OnInit {
  //const
  private readonly MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  //private variables
  //private readonly MERCHANT_ID: string = "1220895";
  //private readonly MERCHANT_ID: string = "1220895";// sandbox key
  private paymentInfo: PaymentSet;
  private slipImagePath: string;
  private amount: number = 0.0;
  private bankSlip: FileList;
  private orderId: string;

  //default variables
  postalPaid: boolean = false;
  panelOpenState: boolean = false;
  validProfile: boolean = false;
  processDone: boolean = false;
  paymentState: boolean = true;
  fileUploaded: boolean = false;
  systemError: boolean = false;
  dataLoaded: boolean = false;
  postalChargesAdded: boolean = false;
  dayAndTime: string = '';
  myClass: Class = null;
  postalFee: string = '';
  payYear: string = '';
  payMonth: string = '';
  paymentMethod: any = '2';
  postalService: boolean = false;
  weeks: boolean[] = [false, false, false, false];
  weekPricesStr: string[] = ['', '', '', ''];
  weekPrices: number[] = [0, 0, 0, 0];
  disabledWeeks: boolean[] = [false, false, false, false];
  borderWeeks: boolean[] = [false, false, false, false];
  progress: { percentage: number } = { percentage: 0 };
  @ViewChild('payhere', { static: true }) payherepayment: ElementRef;
  @ViewChild('stepper', { static: false }) myStepper: MatStepper;

  constructor(
    private databaseManager: DatabaseManager,
    private authService: AuthService,
    private datepipe: DatePipe,
    private alertService: AlertService,
    private dataService: DataService,
    private uploadService: UploadService,
    private paymentValidator: PaymentValidator,
    @Inject(MAT_DIALOG_DATA) public data: ClassPayment,
    public dialogRef: MatDialogRef<PaymentManagerComponent>,
    private profileValidator: ProfileValidatorService
  ) {
    if (data !== undefined && data !== null) {
      this.myClass = data.myClass;
      // dataService.loadRequiredData();
      this.loadData();
    } else {
      this.systemError = true;
      console.log('Payment error');
    }
  }

  ngOnInit(): void {}

  //starting lottie config
  option1: AnimationOptions = {
    path: '/assets/lti/lti_check.json',
    loop: false,
  };
  option2: AnimationOptions = {
    path: '/assets/lti/lti_wait.json',
    loop: true,
  };
  option3: AnimationOptions = {
    path: '/assets/lti/lti_error.json',
    loop: false,
  };
  option4: AnimationOptions = {
    path: '/assets/lti/lti_noprofile.json',
    loop: true,
  };

  private loadData(): void {
    //check the profile validity
    this.validProfile = this.profileValidator.isProfileValid();

    //load price details
    //console.log(this.data);
    this.databaseManager.getPriceData().then((result) => {
      this.paymentInfo = result;

      //set year and month
      this.payYear = this.data.date.getFullYear().toString();
      this.payMonth = this.MONTH_NAMES[this.data.date.getMonth()];

      //set date info
      let dt: DateTime = new DateTime();
      this.dayAndTime =
        this.myClass.day +
        ' ' +
        dt.get12HrsTime(this.myClass.startingTime) +
        ' - ' +
        dt.get12HrsTime(this.myClass.endingTime);
      this.calculatePrice();

      // this.subscribeThisClass();
      this.generateOrderId();

      this.paymentValidator
        .getPaidWeeksServices(
          this.authService.userData.uid,
          this.myClass.classId,
          this.data.date
        )
        .then((token) => {
          if (token.week1 === 1) this.disabledWeeks[0] = true;
          if (token.week2 === 1) this.disabledWeeks[1] = true;
          if (token.week3 === 1) this.disabledWeeks[2] = true;
          if (token.week4 === 1) this.disabledWeeks[3] = true;
          this.postalFee = this.getCurrencyStr(token.postalFee);
          this.postalPaid = token.postalFeePaid;
        });
      this.dataLoaded = true;
      //highlight required week
      this.borderWeeks[this.paymentValidator.whichWeek(this.data.date)] = true;
    });
  }

  setWeek(id: number): void {
    this.weeks[id] = !this.weeks[id];
    this.calculatePrice();
  }

  onPostalChange(e: MatCheckboxChange) {
    this.calculatePrice();
  }

  getCurrencyStr(value: number): string {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'LKR',
    });
    return formatter.format(value);
  }

  getOrderId(): string {
    return this.orderId;
  }

  private generateOrderId(): void {
    var date = new Date();
    var number = date.getTime().toString(16).toUpperCase();
    this.orderId = number;
  }

  private calculatePrice(): void {
    //reset the amount
    this.amount = 0;

    var perWeekFree = this.myClass.classFee / 4;
    var perWeekStr = this.getCurrencyStr(perWeekFree);

    //set week prices
    this.weekPricesStr = [perWeekStr, perWeekStr, perWeekStr, perWeekStr];
    this.weekPrices = [perWeekFree, perWeekFree, perWeekFree, perWeekFree];

    //change price according to the week
    if (this.weeks[0] === true) this.addAmount(this.weekPrices[0]);
    if (this.weeks[1] === true) this.addAmount(this.weekPrices[1]);
    if (this.weeks[2] === true) this.addAmount(this.weekPrices[2]);
    if (this.weeks[3] === true) this.addAmount(this.weekPrices[3]);

    //setPostal charges
    if (this.postalService) {
      this.addAmount(this.paymentInfo.PostalService);
    }
  }

  private addAmount(amount: number): void {
    this.amount += amount;
  }

  private subscribeThisClass(): void {
    if (!this.dataService.isClassSubscribed(this.myClass.classId)) {
      this.databaseManager.subscribeClass(
        this.myClass,
        this.authService.userData.uid
      );
    }
    if (!this.dataService.isTeacherSubscribed(this.myClass.teacherId)) {
      this.databaseManager.subscribeTeacher(
        this.myClass.teacherId,
        this.authService.userData.uid
      );
    }
  }

  selectFile(event) {
    this.bankSlip = event.target.files;
  }

  proceedUpload() {
    this.uploadService
      .pushBankSlip(
        this.bankSlip[0],
        this.progress,
        this.authService.userData.uid,
        this.myClass.classId,
        this.generateFileId()
      )
      .then((res) => {
        this.fileUploaded = true;
        this.slipImagePath = res;
      });
  }

  generateFileId() {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (
      S4() +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      '-' +
      S4() +
      S4() +
      S4()
    );
  }
  finishUpload() {
    this.payForClass(
      true,
      this.slipImagePath,
      this.databaseManager.createDocID()
    );
  }

  getPostalCharge(): string {
    return this.getCurrencyStr(this.paymentInfo.PostalService);
  }

  getTotal(): string {
    return this.getCurrencyStr(this.amount);
  }

  //**Do not delete this segment
  // payViaPayHere(std: Student) {
  //   // my sand 1214048
  //   var documentID = this.databaseManager.createDocID();
  //   var payment = {
  //     "sandbox": true,
  //     "merchant_id": this.MERCHANT_ID,
  //     "return_url": "https://digipanthiya.com/my-account",
  //     "cancel_url": "https://digipanthiya.com",
  //     "notify_url": "https://us-central1-digipanthiya-bc66f.cloudfunctions.net/VerifyClassPayment",
  //     "order_id": this.orderId,
  //     "items": "Class Payment - For " + this.myClass.teacherName,
  //     "amount": this.amount,
  //     "currency": "LKR",
  //     "first_name": std.firstName,
  //     "last_name": std.lastName,
  //     "email": std.email,
  //     "phone": std.studentTp1,
  //     "address": std.studentAddress,
  //     "city": std.city,
  //     "country": "Sri Lanka",
  //     "delivery_address": std.studentAddress,
  //     "delivery_city": std.city,
  //     "delivery_country": "Sri Lanka",
  //     "custom_1": std.studentId,
  //     "custom_2": documentID,
  //   };

  //   // Called when user completed the payment. It can be a successful payment or failure
  //   payhere.onCompleted = () => {
  //     this.paymentState = false;
  //     this.databaseManager.getPayherePay(std.studentId, documentID,1).then((result: PayhereClsVerification) => {
  //       this.paymentState = true;
  //       if (result.verification === 1) {
  //         this.payForClass(false, "-", documentID);
  //         this.processDone = true;
  //       } else {
  //         this.systemError = true;
  //       }
  //     })
  //   }

  //   // Called when user closes the payment without completing
  //   payhere.onDismissed = () => {
  //     //Note: Prompt user to pay again or show an error page
  //     console.log("Payment dismissed");
  //     this.alertService.showAlert("Payment dismissed", MessageButtons.ok, MessageType.warning);
  //   };

  //   // Called when error happens when initializing payment such as invalid parameters
  //   payhere.onError = (error) => {
  //     // Note: show an error page
  //     console.log("Error:" + error);
  //     this.alertService.showAlert("Payment initializing failed. Please try again.", MessageButtons.ok, MessageType.warning);
  //   };

  //   payhere.startPayment(payment);
  // }
  //**Do not delete this segment
  // async payHere() {
  //   this.databaseManager.getStudent(this.authService.userData.uid).then(std => {
  //     this.payViaPayHere(std);
  //   });
  // }

  private payForClass(
    isBankSlip: boolean,
    bankSlipUrl: string,
    documentId: string
  ): void {
    var myPayment: Payment = {
      documentId: documentId,
      paymentId: this.orderId,
      paymentStatus: isBankSlip ? 0 : 1,
      studentName: '-',
      studentEmail: '-',
      studentId: this.authService.userData.uid,
      dayAndTime: this.dayAndTime,
      teacherId: this.myClass.teacherId,
      teacherName: this.myClass.teacherName,
      subjectName: this.myClass.subjectName,
      gradeName: this.myClass.gradeName,
      classId: this.myClass.classId,
      purchaseDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
      purchaseTime: this.datepipe.transform(new Date(), 'HH:mm'),
      amount: this.amount,
      postalService: this.postalService,
      postalFee:
        this.postalService === true ? this.paymentInfo.PostalService : 0,
      isBankPayment: isBankSlip,
      bankSlipUrl: bankSlipUrl,
      week1: this.weeks[0] === true ? 1 : 0,
      week2: this.weeks[1] === true ? 1 : 0,
      week3: this.weeks[2] === true ? 1 : 0,
      week4: this.weeks[3] === true ? 1 : 0,
      week1Amount: this.weeks[0] === true ? this.weekPrices[0] : 0,
      week2Amount: this.weeks[1] === true ? this.weekPrices[1] : 0,
      week3Amount: this.weeks[2] === true ? this.weekPrices[2] : 0,
      week4Amount: this.weeks[3] === true ? this.weekPrices[3] : 0,
      year: this.data.date.getFullYear(),
      month: this.data.date.getMonth() + 1,
    };
    this.databaseManager
      .getStudent(this.authService.userData.uid)
      .then((std) => {
        myPayment.studentName = std.firstName + ' ' + std.lastName;
        myPayment.studentEmail = std.email;

        if (this.postalService) {
          this.addPostalCharge(myPayment, std.studentTp1);
        }

        this.databaseManager.payForClass(
          myPayment,
          this.authService.userData.uid
        );
        this.subscribeThisClass();
        this.processDone = true;
      });
  }

  moveStep(step: number) {
    switch (this.myStepper.selectedIndex) {
      case 0:
        this.myStepper.next();
        break;
      case 1:
        if (this.amount !== 0) this.myStepper.next();
        break;
    }
  }

  closeMe() {
    this.dialogRef.close();
  }

  addPostalCharge(payment: Payment, studentTp: string) {
    var postalCharge: PostalCharge = {
      TeacherId: payment.teacherId,
      classId: payment.classId,
      dayAndTime: payment.dayAndTime,
      documentId: payment.documentId,
      gradeName: payment.gradeName,
      year: payment.year,
      month: payment.month,
      paymentId: payment.paymentId,
      paymentStatus: payment.paymentStatus,
      postalChargeId: '',
      postalFee: payment.postalFee,
      posted: false,
      purchaseDate: payment.purchaseDate,
      purchaseTime: payment.purchaseTime,
      studentEmail: payment.studentEmail,
      studentId: payment.studentId,
      studentName: payment.studentName,
      studentTp: studentTp,
      subjectName: payment.subjectName,
    };

    this.databaseManager.addPostalTransaction(postalCharge);
  }
}
