<div class="py-2">
  <h1>My Account</h1>
  <mat-tab-group
    animationDuration="0ms"
    #tabRef
    (selectedTabChange)="loadMySubs($event)"
  >
    <mat-tab label="My Profile" *ngIf="auth.userData as user">
      <div class="row m-0 mt-3" *ngIf="studentForm" [formGroup]="studentForm">
        <div class="col-12">
          <div class="row m-0">
            <label
              (click)="openProfileUploader()"
              class="rounded-circle profilepic pointer"
              [ngStyle]="{
                'background-image': profileValidator.getUserProfilePic()
                  ? 'url(' + profileValidator.getUserProfilePic() + ')'
                  : './../assets/images/noprofile.png'
              }"
              (mouseover)="overImage = false"
              (mouseout)="overImage = true"
            >
              <span
                class="material-icons rounded-circle text-white camera"
                [hidden]="overImage"
                >photo_camera</span
              >
            </label>
            <input type="file" accept="image/*" id="profilepic" hidden />
          </div>
        </div>
        <div class="col-lg-5 col-12 col-md-10">
          <div class="mt-4 border rounded p-2">
            <label class="text-secondary">First Name</label>
            <input
              #firstName
              class="form-control"
              id="firstName"
              formControlName="firstName"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['firstName'].invalid &&
                  (studentForm.controls['firstName'].dirty ||
                    studentForm.controls['firstName'].touched)
              }"
              placeholder="ex:- John"
              (keydown.enter)="lastName.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['firstName'].invalid &&
                (studentForm.controls['firstName'].dirty ||
                  studentForm.controls['firstName'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['firstName'].errors.required">
                Please enter your first name.
              </div>
              <div *ngIf="studentForm.controls['firstName'].errors.pattern">
                Please don't use numbers or other special characters in your
                first name.
              </div>
              <div *ngIf="studentForm.controls['firstName'].errors.minlength">
                First name should have more than 3 characters.
              </div>
              <div *ngIf="studentForm.controls['firstName'].errors.maxlength">
                First name should be less than 15 characters.
              </div>
            </div>
            <label class="text-secondary mt-2">Last Name</label>
            <input
              #lastName
              class="form-control"
              id="lastName"
              formControlName="lastName"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['lastName'].invalid &&
                  (studentForm.controls['lastName'].dirty ||
                    studentForm.controls['lastName'].touched)
              }"
              placeholder="ex:- Smith"
              (keydown.enter)="nic.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['lastName'].invalid &&
                (studentForm.controls['lastName'].dirty ||
                  studentForm.controls['lastName'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['lastName'].errors.required">
                Please enter your last name.
              </div>
              <div *ngIf="studentForm.controls['lastName'].errors.pattern">
                Please don't use numbers or other special characters in your
                last name.
              </div>
              <div *ngIf="studentForm.controls['lastName'].errors.minlength">
                Last name should have more than 3 characters.
              </div>
              <div *ngIf="studentForm.controls['lastName'].errors.maxlength">
                Last name should be less than 15 characters.
              </div>
            </div>
            <label class="text-secondary mt-2">Email</label>
            <input
              #email
              class="form-control"
              type="text"
              id="email"
              formControlName="email"
              disabled
            />
            <label class="text-secondary mt-2">NIC</label>
            <input
              #nic
              class="form-control"
              id="nic"
              formControlName="nic"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['nic'].invalid &&
                  (studentForm.controls['nic'].dirty ||
                    studentForm.controls['nic'].touched)
              }"
              placeholder="NIC /Passport /Driving Licence No"
              (keydown.enter)="studentTp1.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['nic'].invalid &&
                (studentForm.controls['nic'].dirty ||
                  studentForm.controls['nic'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['nic'].errors.required">
                Please fill the field NIC.
              </div>
              <div *ngIf="studentForm.controls['nic'].errors.minlength">
                NIC should have more than 9 characters.
              </div>
              <div *ngIf="studentForm.controls['nic'].errors.maxlength">
                NIC should be less than 12 characters.
              </div>
            </div>
            <label class="text-secondary mt-2">Telephone Number 1</label>
            <input
              #studentTp1
              class="form-control"
              id="studentTp1"
              formControlName="studentTp1"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['studentTp1'].invalid &&
                  (studentForm.controls['studentTp1'].dirty ||
                    studentForm.controls['studentTp1'].touched)
              }"
              placeholder="ex:- 07XXXXXXXX"
              (keydown.enter)="studentTp2.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['studentTp1'].invalid &&
                (studentForm.controls['studentTp1'].dirty ||
                  studentForm.controls['studentTp1'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['studentTp1'].errors.required">
                Please enter your telephone number.
              </div>
              <div *ngIf="studentForm.controls['studentTp1'].errors.pattern">
                Phone numbers must contain numbers only.
              </div>
              <div *ngIf="studentForm.controls['studentTp1'].errors.minlength">
                Phone number must have 10 characters.
              </div>
              <div *ngIf="studentForm.controls['studentTp1'].errors.maxlength">
                Phone number should be less than 11 characters.
              </div>
            </div>
            <label class="text-secondary mt-2"
              >Telephone Number 2(Optional)</label
            >
            <input
              #studentTp2
              class="form-control"
              id="studentTp2"
              formControlName="studentTp2"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['studentTp2'].invalid &&
                  (studentForm.controls['studentTp2'].dirty ||
                    studentForm.controls['studentTp2'].touched)
              }"
              placeholder="ex:- 07XXXXXXXX"
              (keydown.enter)="studentAddress.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['studentTp2'].invalid &&
                (studentForm.controls['studentTp2'].dirty ||
                  studentForm.controls['studentTp2'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['studentTp2'].errors.pattern">
                Phone numbers must contain numbers only.
              </div>
              <div *ngIf="studentForm.controls['studentTp2'].errors.minlength">
                Phone number must have 10 characters.
              </div>
              <div *ngIf="studentForm.controls['studentTp2'].errors.maxlength">
                Phone number should be less than 11 characters.
              </div>
            </div>
          </div>
        </div>
        <div class="offset-lg-1 col-lg-5 col-12 col-md-10">
          <div class="mt-4 border rounded p-2">
            <label class="text-secondary">Address</label>
            <input
              #studentAddress
              class="form-control"
              id="studentAddress"
              formControlName="studentAddress"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['studentAddress'].invalid &&
                  (studentForm.controls['studentAddress'].dirty ||
                    studentForm.controls['studentAddress'].touched)
              }"
              placeholder="Address"
              (keydown.enter)="city.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['studentAddress'].invalid &&
                (studentForm.controls['studentAddress'].dirty ||
                  studentForm.controls['studentAddress'].touched)
              "
              class="invalidField"
            >
              <div
                *ngIf="studentForm.controls['studentAddress'].errors.required"
              >
                Please enter your address.
              </div>
              <div
                *ngIf="studentForm.controls['studentAddress'].errors.minlength"
              >
                Address must be more than 5 characters.
              </div>
            </div>
            <label class="text-secondary mt-2">City</label>
            <input
              #city
              class="form-control"
              id="city"
              formControlName="city"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['city'].invalid &&
                  (studentForm.controls['city'].dirty ||
                    studentForm.controls['city'].touched)
              }"
              placeholder="City"
              (keydown.enter)="province.focus()"
            />
            <div
              *ngIf="
                studentForm.controls['city'].invalid &&
                (studentForm.controls['city'].dirty ||
                  studentForm.controls['city'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['city'].errors.required">
                Please enter your city.
              </div>
              <div *ngIf="studentForm.controls['city'].errors.minlength">
                City must be more than 4 characters.
              </div>
            </div>
            <label class="text-secondary mt-2">Province</label>
            <select
              #province
              name="province"
              id="province"
              class="form-control"
              formControlName="province"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['province'].invalid &&
                  (studentForm.controls['province'].dirty ||
                    studentForm.controls['province'].touched)
              }"
              (keydown.enter)="postalCode.focus()"
            >
              <option *ngFor="let province of provinces" [value]="province">
                {{ province }}
              </option>
            </select>
            <div
              *ngIf="
                studentForm.controls['province'].invalid &&
                (studentForm.controls['province'].dirty ||
                  studentForm.controls['province'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['province'].errors.pattern">
                Please select your province.
              </div>
            </div>
            <label class="text-secondary mt-2">Postal Code</label>
            <input
              #postalCode
              class="form-control"
              id="postalCode"
              formControlName="postalCode"
              [ngClass]="{
                'border-danger':
                  studentForm.controls['postalCode'].invalid &&
                  (studentForm.controls['postalCode'].dirty ||
                    studentForm.controls['postalCode'].touched)
              }"
              placeholder="Postal Code"
              (keydown.enter)="saveStudent()"
            />
            <div
              *ngIf="
                studentForm.controls['postalCode'].invalid &&
                (studentForm.controls['postalCode'].dirty ||
                  studentForm.controls['postalCode'].touched)
              "
              class="invalidField"
            >
              <div *ngIf="studentForm.controls['postalCode'].errors.required">
                Please enter your postal code.
              </div>
              <div *ngIf="studentForm.controls['postalCode'].errors.pattern">
                Postal code should only contain numbers.
              </div>
              <div *ngIf="studentForm.controls['postalCode'].errors.minlength">
                Postal code must have minimum 3 digits.
              </div>
              <div *ngIf="studentForm.controls['postalCode'].errors.maxlength">
                Postal code cannot have more than 5 digits.
              </div>
            </div>
            <!-- <label class="text-secondary mt-2">Country</label>
                        <br>
                        <img loading="lazy" src='https://lipis.github.io/flag-icon-css/flags/4x3/lk.svg' alt=""
                            width="22" height="14">
                        <span class="ml-3">Sri Lanka</span> -->
          </div>
          <div class="row m-0">
            <div class="mt-4" style="margin-left: auto; margin-right: 0%">
              <span
                class="btn btn-danger pointer"
                (click)="loadStudent()"
                style="font-size: 0.8em"
                >Cancel</span
              >
              <span
                class="btn btn-purple ml-4 pointer text-center text-white"
                (click)="saveStudent()"
                style="font-size: 0.8em"
                >Save</span
              >
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="My Subscriptions">
      <div class="row m-0 p-0 mt-3" *ngIf="studentClasses">
        <ng-template
          [ngIf]="studentClasses.length > 0"
          [ngIfElse]="nosubscriptionlottie"
        >
          <div
            class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4"
            *ngFor="let class of studentClasses; index as i"
          >
            <div class="pointer">
              <div class="overBlock">
                <img
                  src="{{ class.subjectPic }}"
                  alt="{{ class.subjectName }}"
                  class="col-12 m-0 p-0 mb-1 item-image-size"
                />
                <div class="overlay">
                  <span
                    class="material-icons btnSettings"
                    [matMenuTriggerFor]="clzSettings"
                  >
                    settings</span
                  >
                  <mat-menu #clzSettings="matMenu">
                    <button
                      mat-menu-item
                      (click)="unEnroll(class, i)"
                      class="bg-white border rounded"
                    >
                      <mat-icon>exit_to_app</mat-icon>
                      <span>Unenroll</span>
                    </button>
                  </mat-menu>
                  <span
                    class="payButton center"
                    *ngIf="paidLock[i]; else paid"
                    (click)="payNow(class)"
                    >PAY NOW</span
                  >
                  <ng-template #paid>
                    <!-- <span class="material-icons" style="float: right; color: white; font: bold;">
                                      check_circle</span> -->
                    <span style="color: white; float: right"
                      >Last Payment On {{ paidDates[i] }}</span
                    >
                    <span class="disPayButton center">PAID</span>
                  </ng-template>
                </div>
              </div>
              <div class="row m-0 p-0" style="align-items: center">
                <span class="item-title-fs"
                  ><b
                    >{{ class.day }} {{ getTime(class.startingTime) }} -
                    {{ getTime(class.endingTime) }}</b
                  ></span
                >
                <span
                  class="px-3 px-md-2 px-lg-3 rounded-pill text-white"
                  style="font-size: 0.7em; margin-left: auto; margin-right: 0%"
                  [style.background-color]="
                    class.classType === 'paper'
                      ? '#EE5A24'
                      : class.classType === 'group'
                      ? '#B53471'
                      : '#1B1464'
                  "
                >
                  {{ class.classType }}
                </span>
              </div>
              <div class="row m-0 p-0" style="align-items: center">
                <img
                  src="{{ getImgUrl(class.teacherPic) }}"
                  class="rounded-circle"
                  alt="{{ class.teacherPic }}"
                  width="30"
                  height="30"
                />
                <span class="ml-2" style="font-size: 0.8em">{{
                  class.teacherName
                }}</span>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template #nosubscriptionlottie>
          <div class="w-100">
            <div class="mx-auto text-center" style="width: fit-content">
              <ng-lottie
                [options]="nosubscription"
                class="mx-auto text-center"
                width="350px"
                height="auto"
              >
              </ng-lottie>
              <div class="mx-auto"><h2>You have no Subscriptions</h2></div>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab label="Payments History">
      <ng-template [ngIf]="historyList.length > 0" [ngIfElse]="nopaymentlottie">
        <div
          class="mx-md-4 mb-4 border mt-2"
          *ngFor="let item of historyList"
          [ngStyle]="{
            'background-color': item.paymentStatus === 1 ? 'white' : '#e4e9ed'
          }"
        >
          <div>
            <div class="px-3 py-2 border-bottom">
              <span style="float: left; font-weight: bold; font-size: 16px"
                >Payment Ref {{ item.paymentId }}</span
              >
              <span
                *ngIf="item.paymentStatus === 1"
                class="material-icons"
                style="float: right; color: green; font: bold"
              >
                check_circle</span
              >
              <span
                *ngIf="item.paymentStatus === 0"
                style="float: right; color: #f03434; font: bold"
                >Verification Pending</span
              >
              <br />
              <span
                [ngStyle]="{
                  color: item.paymentStatus === 1 ? 'green' : '#f03434'
                }"
                >Purchased on <b>{{ item.purchaseDate }}</b> at
                <b>{{ dateTime.get12HrsTime(item.purchaseTime) }}</b></span
              >
            </div>
            <div class="px-3 py-2 border-bottom">
              <span>Payment Method: </span>
              <span *ngIf="item.isBankPayment">Bank Deposite</span>
              <span *ngIf="!item.isBankPayment"> Payhere</span>
              <br />
              <a
                *ngIf="item.isBankPayment"
                href="{{ item.bankSlipUrl }}"
                target="_blank"
                class="row m-0"
                style="
                  align-items: center;
                  width: fit-content;
                  text-decoration: none;
                "
              >
                <span>Attachment </span>
                <span class="material-icons" style="transform: rotateZ(90deg)"
                  >attach_file</span
                >
              </a>
              <br />
              <mat-expansion-panel
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Class Details </mat-panel-title>
                  <mat-panel-description>
                    Click to {{ panelOpenState ? "hide" : "view" }}
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div class="mt-2">
                  <div class="row m-0">
                    <span class="optionValue col-4">Teacher's Name : </span>
                    <span class="optionValue">{{ item.teacherName }}</span>
                  </div>
                  <div class="row m-0">
                    <span class="optionValue col-4">Subject : </span>
                    <span class="optionValue">{{ item.subjectName }}</span>
                  </div>
                  <div class="row m-0">
                    <span class="optionValue col-4">Grade : </span>
                    <span class="optionValue">{{ item.gradeName }}</span>
                  </div>
                  <div class="row m-0">
                    <span class="optionValue col-4">Day & Time :</span>
                    <span class="optionValue">{{ item.dayAndTime }}</span>
                  </div>
                  <div class="row m-0">
                    <span class="optionValue col-4">Month :</span>
                    <span class="optionValue">{{
                      MONTH_NAMES[item.month]
                    }}</span>
                  </div>
                  <div class="row m-0">
                    <span class="optionValue col-4">Year :</span>
                    <span class="optionValue">{{ item.year }}</span>
                  </div>
                </div>
              </mat-expansion-panel>
              <br />
              <div style="width: 170px; color: #2e3131; font-size: 0.8em">
                <span *ngIf="item.week1 === 1">Week1 </span>
                <span *ngIf="item.week1 === 1" style="float: right">{{
                  getCurrencyStr(item.week1Amount)
                }}</span>
                <br *ngIf="item.week1 === 1" />
                <span *ngIf="item.week2 === 1">Week2 </span>
                <span *ngIf="item.week2 === 1" style="float: right">{{
                  getCurrencyStr(item.week2Amount)
                }}</span>
                <br *ngIf="item.week2 === 1" />
                <span *ngIf="item.week3 === 1">Week3 </span>
                <span *ngIf="item.week3 === 1" style="float: right">{{
                  getCurrencyStr(item.week3Amount)
                }}</span>
                <br *ngIf="item.week3 === 1" />
                <span *ngIf="item.week4 === 1">Week4 </span>
                <span *ngIf="item.week4 === 1" style="float: right">{{
                  getCurrencyStr(item.week4Amount)
                }}</span>
                <br *ngIf="item.week4 === 1" />
                <span *ngIf="item.postalService">Postal Charge </span>
                <span *ngIf="item.postalService" style="float: right">{{
                  getCurrencyStr(item.postalFee)
                }}</span>
              </div>
            </div>
            <div
              class="px-3 py-2"
              style="width: 200px; font-size: 1.1em; font-weight: bold"
            >
              <span>Total </span>
              <span style="float: right">{{
                getCurrencyStr(item.amount)
              }}</span>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #nopaymentlottie>
        <div class="w-100">
          <div class="mx-auto text-center" style="width: fit-content">
            <ng-lottie
              [options]="nopayment"
              class="mx-auto text-center"
              width="350px"
              height="auto"
            >
            </ng-lottie>
            <div class="mx-auto"><h2>No payment histroy</h2></div>
          </div>
        </div>
      </ng-template>
      <div class="center" *ngIf="historyList.length > 0">
        <div>
          <mat-button
            class="px-3 py-2 rounded pointer"
            (click)="prevPHistory()"
            [disabled]="!previousButton"
            [ngClass]="previousButton ? 'btn-purple' : 'btn-purple-disabled'"
            style="font-size: 0.8em"
          >
            Prev</mat-button
          >
          <button
            class="py-2 px-3 rounded ml-4 pointer text-center"
            [disabled]="!nextButton"
            (click)="nextPHistory()"
            [ngClass]="nextButton ? 'btn-purple' : 'btn-purple-disabled'"
            style="font-size: 0.8em"
          >
            Next
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
