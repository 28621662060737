<div>
  <div class="row">
    <div class="col-lg-10 border-bottom" style="align-items: center">
      <div class="row m-0 p-0 pointer my-2" (click)="hideFilter()">
        <i class="material-icons" style="font-size: 1.5em">filter_alt</i>
        <span style="font-size: 1.2em">FILTER</span>
      </div>
      <div
        class="row m-0 p-0 my-3"
        [class.hiddenFilter]="isFilterHidden == true"
      >
        <div class="mr-5 filterDivs">
          <h4 class="mx-2 py-2 border-bottom text-secondary"><b>Field</b></h4>
          <div>
            <span class="px-2 py-1" (click)="setSearchType('srhVideoTitle')"
              >Video Title</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="teacher"
      class="col-12"
      style="
        height: 150px;
        background-size: cover;
        background-repeat: no-repeat;
      "
      [ngStyle]="{
        'background-image':
          this.teacher.coverPic !== ''
            ? 'linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),url(' +
              this.teacher.coverPic +
              ')'
            : 'linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5)),url(./assets/images/coverpic.png)'
      }"
    >
      <div class="row text-white" style="align-items: center">
        <img
          src="{{ teacher.profilePic }}"
          alt=""
          width="70px"
          height="70px"
          class="rounded-circle my-3 ml-2 ml-sm-3"
        />
        <span class="pl-2 pl-sm-4" style="font-size: 1.3em; font-weight: 500">
          {{ teacher.teacherName }}
        </span>
      </div>
    </div>
    <div class="container-fluid" *ngIf="videoList.length !== 0">
      <div class="row m-0 p-0 mt-4">
        <div
          class="col-xl-3 col-md-4 col-sm-6 col-12 mb-4"
          *ngFor="let video of videoList; index as i"
        >
          <div
            class="col-12 row m-0 p-0 p-0 item-image-size"
            style="align-items: center"
            *ngIf="!videoLock[i]; else thumbnail"
          >
            <img
              [src]="
                video.videoThumbnail === undefined ||
                video.videoThumbnail === ''
                  ? this.NO_THUMBNAIL
                  : video.videoThumbnail
              "
              alt=""
              width="100%"
              height="100%"
              class="col-12 m-0 p-0 mb-1 pointer item-image-size"
              style="position: absolute; opacity: 0.2"
            />
            <div
              class="col-12 text-center p-0"
              (click)="getMessage(video.classId, video.uploadedDate)"
            >
              <i class="material-icons pointer" style="font-size: 3em">lock</i
              ><br />
              <span class="pointer" style="font-size: 1.2em"
                >Unlock This Video</span
              ><br />
            </div>
          </div>
          <ng-template #thumbnail (click)="playThis(video)">
            <img
              [src]="
                video.videoThumbnail === undefined ||
                video.videoThumbnail === ''
                  ? this.NO_THUMBNAIL
                  : video.videoThumbnail
              "
              alt=""
              class="col-12 m-0 p-0 mb-1 pointer item-image-size"
              (click)="playThis(video)"
            />
          </ng-template>
          <div>
            <span
              ><b>{{ video.videoTitle }}</b></span
            ><br />
            <span style="font-size: 0.7em">{{ video.uploadedDate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="center" *ngIf="loadingList && videoList.length !== 0">
      <ng-lottie
        style="
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 100px;
          padding-bottom: 50px;
        "
        [options]="loadingDot"
        width="80px"
        height="auto"
      >
      </ng-lottie>
    </div>
    <div
      class="row container-fluid m-0 p-0 mt-5 text-center"
      *ngIf="videoList.length === 0"
    >
      <ng-lottie
        class="lottie-container"
        style="margin-left: auto; margin-right: auto"
        [options]="option"
        width="100%"
        height="auto"
      >
      </ng-lottie>
      <div class="col-12 mt-4">
        <h2>No Videos Available...</h2>
      </div>
    </div>
  </div>
</div>
