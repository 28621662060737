<div class="bg-white rounded container">
    <div class="row px-3 py-3 rounded-top border-bottom text-white"
        [class.bg-danger]="data.messageType === messageTypes.accessBlocked"
        [class.bg-warning]="data.messageType === messageTypes.warning"
        [class.bg-success]="data.messageType !== messageTypes.accessBlocked && data.messageType !== messageTypes.warning"
        style="align-items: center">
        <span class="material-icons"
            *ngIf="data.messageType !== messageTypes.accessBlocked && data.messageType !== messageTypes.warning">done</span>
        <span class="material-icons" *ngIf="data.messageType === messageTypes.accessBlocked">block</span>
        <span class="material-icons" *ngIf="data.messageType === messageTypes.warning">warning</span>
        <div>
            <span class="ml-3" style="font-weight: 500;">{{data.messageType}}</span>
        </div>
        <span mat-dialog-close style="margin-left: auto; margin-right: 0%" class="material-icons">close</span>
    </div>
    <div class="rounded py-3 px-3">
        <p style="text-align: center;">{{data.message}}</p>
        <div class="row m-0" style="justify-content: center;">
            <span class="mt-2 px-3 py-2 rounded btn-success text-white hand"
                *ngIf="data.messageButtons===messageButtons.ok && (data.messageType !== messageTypes.accessBlocked && data.messageType !== messageTypes.warning)"
                (click)="clickOk()" style="font-size: .8em;">OK</span>
            <span class="mt-2 px-3 py-2 rounded btn-warning text-white hand"
                *ngIf="data.messageButtons===messageButtons.ok && data.messageType === messageTypes.warning" (click)="clickOk()"
                style="font-size: .8em;">OK</span>
            <span class="mt-2 px-3 py-2 rounded btn-danger text-white hand"
                *ngIf="data.messageButtons===messageButtons.ok && data.messageType === messageTypes.accessBlocked" (click)="clickOk()"
                style="font-size: .8em;">OK</span>
            <span class="mt-2 px-3 py-2 rounded btn-secondary hand" *ngIf="data.messageButtons===messageButtons.YesNo"
                (click)="setResult(RESULT_CLOSE)" style="font-size: .8em;">NO</span>
            <span class="mt-2 px-3 py-2 rounded ml-4 btn-danger text-white hand"
                *ngIf="data.messageButtons===messageButtons.YesNo && data.messageType === messageTypes.accessBlocked"
                (click)="setResult(RESULT_YES)" style="font-size: .8em;">YES</span>
            <span class="mt-2 px-3 py-2 rounded ml-4 btn-warning text-white hand"
                *ngIf="data.messageButtons===messageButtons.YesNo && data.messageType === messageTypes.warning"
                (click)="setResult(RESULT_YES)" style="font-size: .8em;">YES</span>
            <span class="mt-2 px-3 py-2 rounded btn-purple text-white hand" *ngIf="data.messageButtons===messageButtons.resend_verification_email"
                (click)="setResult(RESEND_VERIFY_EMAIL)" style="font-size: .8em;">Resend Verification Email</span>
        </div>
    </div>
</div>