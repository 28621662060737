import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ebrary',
  templateUrl: './ebrary.component.html',
  styleUrls: ['./ebrary.component.scss']
})
export class EbraryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
