<div class="p-2">
    <div class="row m-0 p-0">
        <div class="offset-2 col-8">
            <div class="mt-3 text-center" style="display: grid;">
                <span style="font-size: 1.2em;font-weight: 900;">Something Unclear to You?</span>
                <span class="mt-2" style="font-size: 1.6em;font-weight: 800;color: #1B1464;">Ask form Us</span>
            </div>
        </div>
        <div class=" offset-3 offset-lg-0 col-lg-2 col-6 offset-md-4 col-md-4">
            <img src="../../assets/images/Copy of Contact us-amico.png" class="col-12" width="100%" height="100%"
                alt="">
        </div>
        <div class="offset-lg-2 col-lg-8">
            <div>
                <label for="question" style="font-size: 1.2em;font-weight: 500;">Your Question</label>
                <textarea name="" id="question" rows="5" placeholder="type your question..." class="form-control"
                    style="resize: none;overflow: auto;box-shadow: 0px 0px 4px 0px rgba(80,80,80,0.43)"
                    [(ngModel)]="message"></textarea>
            </div>
            <div class="row m-0 p-0 mt-3">
                <div class="col-sm-5 p-0">
                    <label for="name" style="font-size: 1.2em;font-weight: 500;">Your Name</label>
                    <input type="name" id="name" placeholder="name..." class="form-control" [ngModel]="userName"
                        style="box-shadow: 0px 0px 4px 0px rgba(80,80,80,0.43);">
                </div>
                <div class="col-sm-7 p-0 mt-3 mt-sm-0 pl-sm-5">
                    <label for="email" style="font-size: 1.2em;font-weight: 500;">Your Email</label>
                    <input type="email" id="email" placeholder="email..." class="form-control" [ngModel]="userEmail"
                        [readonly]="emailLock" style="box-shadow: 0px 0px 4px 0px rgba(80,80,80,0.43)">
                </div>
                <span class="btn btn-custom text-white px-4 mt-3" (click)="addInquiry()"
                    style="margin-left: auto;margin-right: 0%;height: max-content; background-color: #1b1464;">Send</span>
            </div>
        </div>
        <div class="container-fluid" style="margin-top: 100px; margin-bottom: 50px;">
            <div class="row">
                <div class="center" style="width: 100%;">
                    <p style="font-size: 1.6em;font-weight: 800;color: #1B1464;">Get in touch</p>
                    <div class="social">
                        <div class="socialBlock">
                            <a href="tel:+9476 939 4630" class="fa fa-phone-square"></a>
                            <br>
                            <span><a href="tel:+9476 939 4630" style="color: #1b1464;">(+94) 076 939 4630</a></span>
                        </div>
                        <div class="socialBlock">
                            <a href="mailto:tutor@digipanthiya.com" class="fa fa-envelope"></a>
                            <br>
                            <span> <a href="mailto:student@digipanthiya.com"
                                    style="color: #1b1464;">student@digipanthiya.com</a></span>
                        </div>
                        <div class="socialBlock">
                            <a href="https://www.facebook.com/digipanthiya2022" class="fa fa-facebook"></a>
                            <br>
                            <span><a href="https://www.facebook.com/digipanthiya2022"
                                    style="color: #1b1464;">digipanthiya2022</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>