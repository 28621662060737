<div class="container" style="max-width: 700px">
  <div class="my-3">
    <div class="w-100 text-center">
      <img
        src="{{ conferenceDetails.conferenceBannerUrl }}"
        class="mx-auto"
        style="height: auto; max-height: 200px; max-width: 100%"
        alt="Banner"
      />
    </div>
  </div>
  <h1>Conference Details</h1>
  <div class="row">
    <div class="col-md-6">
      <div>
        <label class="w-50">Conference Title : </label>
        <label class="w-50">{{ conferenceDetails.conferenceTitle }}</label>
      </div>

      <div>
        <label class="w-50">Conference Description :</label>
        <label class="w-50">{{ conferenceDetails.confernceDesciption }}</label>
      </div>
    </div>

    <div class="col-md-6">
      <div>
        <label class="w-50">Schedule Date : </label>
        <label class="w-50">{{ conferenceDetails.day }}</label>
      </div>

      <div>
        <label class="w-50">Schedule Time : </label>
        <label class="w-50"
          >{{ conferenceDetails.startingTime }} -
          {{ conferenceDetails.endingTime }}</label
        >
      </div>
    </div>

    <button
      class="btn w-100 mt-md-5 text-white"
      style="background-color: #d49d07"
      (click)="enrollNow()"
    >
      Enroll Now :
      {{ conferenceDetails.conferencePrice | currency: "LKR ":true }}
    </button>
  </div>
</div>
