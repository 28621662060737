import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from "../services/auth.service";
import { Observable } from 'rxjs';
import { CustomParm, ParmType } from '../util/custom-parms';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard implements CanActivate {

    constructor(
        public authService: AuthService,
        public router: Router,
        //public dialog: MatDialog
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.authService.isLoggedIn) {
            var parm: CustomParm = {
                type: ParmType.login,
            }
            this.router.navigate(['home'], { state: { parm } })
        }
        return true;

    }

}