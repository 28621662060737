<!-- <app-advertisement-display></app-advertisement-display> -->
<div class="p-0" oncontextmenu="return false;">
  <div
    class="row m-0 p-0"
    *ngIf="currentVideo !== undefined && currentVideo !== null; else novideo"
  >
    <div class="col-lg-8 m-0 p-0">
      <div class="row">
        <video
          controls
          controlsList="nodownload"
          id="masterVideo"
          #masterVideo
          style="width: 100%; height: 60%"
          [src]="videoSrc"
          type="video/mp4"
          [autoplay]="autoPlay"
        ></video>
        <div class="buffering" *ngIf="buffer">
          <div>
            <ng-lottie
              [options]="buffering"
              width="200px"
              height="200px"
            ></ng-lottie>
          </div>
          <div class="text-center">Loading . . .</div>
        </div>
      </div>
      <div class="row m-0 mt-2 mb-2">
        <div class="container-fluid row m-0 p-0 border-bottom pb-3">
          <h1 class="video-title">
            <b>{{ currentVideo.videoTitle }}</b>
          </h1>
          <div class="container-fluid row m-0 p-0" style="align-items: center">
            <div class="p-0">
              <span class="text-secondary video-day"
                >{{ currentVideo.views }} views • Uploaded
                {{ uploadedDate }}</span
              >
            </div>
            <div class="row" style="margin-left: auto; margin-right: 0%">
              <div
                class="row videomenu ml-4 pointer"
                [class.text-primary]="isVideoLiked"
                style="align-items: center"
                (click)="likeVideo()"
                *ngIf="!isFeatureWindow"
              >
                <span class="material-icons mr-2 video-icons">thumb_up</span>
                <span>{{ currentVideo.likes }}</span>
              </div>
              <div
                class="row videomenu ml-4 pointer"
                [class.text-primary]="isVideoUnliked"
                style="align-items: center"
                (click)="unlikeVideo()"
                *ngIf="!isFeatureWindow"
              >
                <span class="material-icons mr-2 video-icons">thumb_down</span>
                <span>{{ currentVideo.dislikes }}</span>
              </div>
              <div
                class="row videomenu ml-4 pointer"
                style="align-items: center"
                (click)="loadBkInput(masterVideo)"
              >
                <span class="material-icons mr-2 video-icons">bookmark</span>
                <span>BOOKMARK</span>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid pb-3">
          <div class="row" style="align-items: center">
            <img
              [src]="teacherPic"
              alt=""
              class="rounded-circle my-3 teacher-pic"
            />
            <span class="ml-3 teacher-name">{{
              currentVideo.teacherName
            }}</span>
          </div>
          <div class="offset-lg-1 video-details">
            <!-- <div [ngClass]="{'limitTextHeight': isReadMore}">{{currentVideo.videoDescription}} </div>
                        <button type="button" style="outline: 0; color:#1b1464" (click)="showMoreText()">
                            {{ isReadMore ? 'Read More': 'Read Less' }}
                        </button> -->
            <ang-readmore
              [text]="currentVideo.videoDescription"
              [length]="360"
              linkColor="#1b1464"
            >
            </ang-readmore>
          </div>
        </div>
        <div class="container-fluid row m-0 p-0 mt-1">
          <table class="text-center col-md-10 border">
            <thead class="border-bottom">
              <tr>
                <th class="border-right py-2 text-center col-7 video-day">
                  Bookmark description
                </th>
                <th class="border-right py-2 text-center col-3 video-day">
                  Time
                </th>
                <th class="border-right py-2 text-center col-2"></th>
              </tr>
            </thead>
            <tbody>
              <tr id="bkinput" style="display: none" class="border-bottom">
                <td class="py-2 text-center col-7 video-day border-right">
                  <input
                    class="col-12 p-0 px-1 form-control"
                    type="text"
                    #bookmarkText
                  />
                </td>
                <td class="py-2 text-center col-3 video-day border-right">
                  <p>{{ myCurTimeline }}</p>
                </td>
                <td class="p-0 py-2 text-center col-2">
                  <span
                    class="material-icons text-success pointer"
                    (click)="
                      addThisBookmark(bookmarkText.value, masterVideo);
                      bookmarkText.value = ''
                    "
                    >done</span
                  >
                  <span
                    class="material-icons text-danger pointer ml-0 ml-sm-2 ml-lg-3"
                    (click)="hideBkInput(); bookmarkText.value = ''"
                    >close</span
                  >
                </td>
              </tr>
              <tr *ngFor="let myMark of myBookmarks">
                <td
                  class="border-right py-2 text-center col-7 video-day"
                  (click)="seekToPosition(myMark.time, masterVideo)"
                >
                  <span>{{ myMark.description }}</span>
                </td>
                <td
                  class="border-right py-2 text-center col-3 video-day"
                  (click)="seekToPosition(myMark.time, masterVideo)"
                >
                  <span>{{ myMark.timeString }}</span>
                </td>
                <td class="border-right py-2 text-center col-2">
                  <button
                    class="material-icons text-danger pointer"
                    (click)="removeBookmark(myMark.bookmarkId)"
                  >
                    delete
                  </button>
                </td>
              </tr>
              <tr class="video-details" *ngIf="myBookmarks.length === 0">
                <td class="py-3" colspan="3">No Bookmarks</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="container-fluid row m-0 p-0 mt-3 border-top pt-3"
          *ngIf="!isFeatureWindow"
        >
          <span
            *ngIf="discussions.length <= 1"
            class="container-fluid p-0 video-day"
            ><b>{{ discussions.length }} Discussion</b></span
          >
          <span
            *ngIf="discussions.length > 1"
            class="container-fluid p-0 video-day"
            ><b>{{ discussions.length }} Discussions</b></span
          >
          <div class="col-12 col-lg-8 row m-0 p-0 mt-4">
            <div class="container-fluid p-0">
              <img
                [src]="validator.getUserProfilePic()"
                class="rounded-circle discussion-img"
                alt="student img"
              />
              <input
                class="col-10 ml-3 video-day"
                type="text"
                #commentTxt
                placeholder="Start a Discussion"
                style="
                  border: none;
                  background-color: inherit;
                  border-bottom: solid 0.3px rgba(121, 121, 121, 0.322);
                  padding-left: 0;
                "
              />
            </div>
            <div class="py-1" style="margin-left: auto; margin-right: 0%">
              <span
                class="btn-outline-dark rounded px-3 py-1 video-details pointer"
                (click)="commentTxt.value = ''"
                >Cancel</span
              >
              <span
                class="btn-purple rounded px-4 py-1 ml-3 video-details pointer"
                (click)="addDiscussion(commentTxt.value); commentTxt.value = ''"
                >Add</span
              >
            </div>
          </div>
        </div>
        <div class="col-lg-12 m-0 p-0 pb-3" *ngIf="!isFeatureWindow">
          <mat-spinner *ngIf="loadingDiscussion"></mat-spinner>
          <div
            class="container-fluid mt-3 m-0 p-0"
            *ngFor="let comment of discussions"
          >
            <div class="pt-3 row m-0" style="align-items: center">
              <img
                src="{{ comment.userPic }}"
                width="50"
                height="50"
                class="rounded-circle"
                alt=""
              />
              <div class="ml-3">
                <span style="font-size: 1.3em"
                  ><b>{{ comment.userName }}</b></span
                >
                <span *ngIf="!comment.isStudent" class="tutorBadge">TUTOR</span>
                <br />
                <span class="text-secondary" style="font-size: 0.9em">{{
                  comment.ago
                }}</span>
              </div>
              <span
                class="material-icons"
                [matMenuTriggerFor]="msgOption"
                style="margin-left: auto; float: right; cursor: pointer"
                *ngIf="comment.userId === myId && comment.isDeletable"
              >
                more_vert
              </span>
              <mat-menu #msgOption="matMenu">
                <button
                  mat-menu-item
                  *ngIf="comment.isEditable"
                  (click)="showEditDis(comment.messageId)"
                >
                  <span>Edit</span>
                </button>
                <button
                  mat-menu-item
                  *ngIf="comment.isDeletable"
                  (click)="removeDiscussion(comment.messageId)"
                >
                  <span>Delete</span>
                </button>
              </mat-menu>
            </div>
            <div class="pt-3 px-4 ml-3">
              <p class="mx-4" [id]="comment.messageId + 'input'">
                {{ comment.message }}
              </p>
              <div
                class="col-12 col-lg-8 row m-0 p-0 mt-4"
                style="display: none"
                [id]="comment.messageId + 'edt'"
              >
                <div class="container-fluid p-0">
                  <input
                    class="col-10 ml-2 video-day"
                    type="text"
                    [id]="comment.messageId + 'TEXT'"
                    value="{{ comment.message }}"
                    style="
                      border: none;
                      background-color: inherit;
                      border-bottom: solid 0.3px rgba(121, 121, 121, 0.322);
                      padding-left: 0;
                    "
                  />
                </div>
                <div class="py-1" style="margin-left: auto; margin-right: 0%">
                  <span
                    class="btn-outline-dark rounded px-3 py-1 video-details pointer"
                    (click)="hideEditDis(comment.messageId)"
                    >Cancel</span
                  >
                  <span
                    class="btn-purple rounded px-4 py-1 ml-3 video-details pointer"
                    (click)="editDiscussion(comment, comment.messageId)"
                    >Edit</span
                  >
                </div>
              </div>
            </div>
            <span
              (click)="showSubDiscussion(comment.messageId)"
              class="offset-lg-1 pointer"
              style="font-size: 0.9em"
              ><b>Reply</b></span
            >
            <div
              class="row m-0 p-0 mt-3 ml-5 pl-3 pb-2"
              [id]="comment.messageId"
              style="display: none"
            >
              <div class="m-0 p-0 col-11 col-lg-8 row">
                <div class="container-fluid p-0">
                  <img
                    [src]="validator.getUserProfilePic()"
                    class="rounded-circle discussion-img"
                    alt="student img"
                  />
                  <input
                    class="col-10 ml-3 video-day"
                    type="text"
                    #subcommentTxt
                    placeholder="Start a Discussion"
                    style="
                      border: none;
                      background-color: inherit;
                      border-bottom: solid 0.3px rgba(121, 121, 121, 0.322);
                      padding-left: 0;
                    "
                  />
                </div>
                <div class="py-1" style="margin-left: auto; margin-right: 0%">
                  <span
                    class="btn-outline-dark rounded px-3 py-1 video-details pointer"
                    (click)="hideSubDiscussion(comment.messageId)"
                    >Cancel</span
                  >
                  <span
                    class="btn-purple rounded px-4 py-1 ml-3 video-details pointer"
                    (click)="
                      addSubDiscussion(subcommentTxt.value, comment);
                      subcommentTxt.value = '';
                      hideSubDiscussion(comment.messageId)
                    "
                    >Add</span
                  >
                </div>
              </div>
            </div>
            <div class="offset-lg-1 mt-1">
              <mat-accordion
                class="example-headers-align"
                *ngIf="comment.replyAvailable"
              >
                <mat-expansion-panel
                  (opened)="loadSubDiscussion(comment.messageId)"
                  hideToggle
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <b style="font-size: 0.9em">View replies</b>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <mat-spinner
                    style="margin-left: auto; margin-right: auto"
                    *ngIf="replies.get(comment.messageId) === undefined"
                  ></mat-spinner>
                  <div
                    class="container-fluid m-0 p-0"
                    *ngFor="let reply of replies.get(comment.messageId)"
                  >
                    <div class="row m-0" style="align-items: center">
                      <img
                        src="{{ reply.userPic }}"
                        width="30"
                        height="30"
                        class="rounded-circle"
                        alt=""
                      />
                      <div class="ml-3">
                        <span style="font-size: 1em"
                          ><b>{{ reply.userName }}</b></span
                        >
                        <span *ngIf="!reply.isStudent" class="tutorBadge"
                          >TUTOR</span
                        >
                        <br />
                        <span class="text-secondary" style="font-size: 0.8em">{{
                          reply.ago
                        }}</span>
                      </div>
                      <span
                        class="material-icons"
                        [matMenuTriggerFor]="msgOption"
                        style="margin-left: auto; float: right; cursor: pointer"
                        *ngIf="reply.userId === myId && reply.isDeletable"
                      >
                        more_vert
                      </span>
                      <mat-menu #msgOption="matMenu">
                        <button mat-menu-item *ngIf="reply.isEditable">
                          <span>Edit</span>
                        </button>
                        <button
                          mat-menu-item
                          *ngIf="reply.isDeletable"
                          (click)="
                            removeSubDiscussion(comment, reply.messageId)
                          "
                        >
                          <span>Delete</span>
                        </button>
                      </mat-menu>
                    </div>
                    <div class="ml-5 mt-2">
                      <p>{{ reply.message }}</p>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 m-0 p-0 pl-md-4">
      <div class="row m-0 p-0" style="align-items: center">
        <span><b>Up Next</b></span>
        <div
          class="row"
          style="margin-left: auto; margin-right: 0%; align-items: center"
        >
          <span class="text-secondary" style="font-size: 0.9em"
            ><b>AUTOPLAY</b></span
          >
          <mat-slide-toggle
            class="ml-1"
            [(ngModel)]="autoPlay"
          ></mat-slide-toggle>
        </div>
      </div>
      <div
        class="row container-fluid m-0 p-0 mt-5 text-center"
        *ngIf="upNext.length === 0"
      >
        <ng-lottie
          class="lottie-container"
          style="margin-left: auto; margin-right: auto"
          [options]="option"
          width="100%"
          height="auto"
        >
        </ng-lottie>
        <div class="col-12 mt-0" style="color: gray">
          <h4>No Videos</h4>
        </div>
      </div>
      <div
        *ngFor="let video of upNext; index as i"
        class="row m-0 p-0 mt-3 mb-2"
      >
        <ng-template [ngIf]="upNextLock[i]" [ngIfElse]="nextvideo">
          <div
            *ngIf="upNextPlaying[i]"
            class="col-12 col-sm-5 col-lg-6 p-0 row m-0 next-video-image"
            style="align-items: center"
          >
            <img
              [src]="
                video.videoThumbnail === undefined ||
                video.videoThumbnail === ''
                  ? this.NO_THUMBNAIL
                  : video.videoThumbnail
              "
              alt=""
              class="col-12 m-0 p-0 mb-1 pointer next-video-image"
              style="position: absolute; opacity: 0.8"
            />
            <div class="col-12 text-center text-white">
              <i class="material-icons pointer" style="font-size: 3em"
                >play_circle_filled</i
              ><br />
              <span class="pointer" style="font-size: 1.2em">now playing</span
              ><br />
            </div>
          </div>
          <img
            (click)="playThis(video, i)"
            *ngIf="!upNextPlaying[i]"
            alt=""
            class="col-12 col-sm-5 col-lg-6 p-0 next-video-image"
            [src]="
              video.videoThumbnail === undefined || video.videoThumbnail === ''
                ? this.NO_THUMBNAIL
                : video.videoThumbnail
            "
          />
          <div
            (click)="playThis(video)"
            class="col-12 col-sm-7 col-lg-6 m-0 p-0 mt-2 mt-md-0 pl-sm-3"
          >
            <p class="next-video-p m-0 p-0">
              <b>{{ video.videoTitle }}</b>
            </p>
            <span class="text-secondary mt-0" style="font-size: 0.8em"
              ><b>{{ video.teacherName }}</b></span
            >
          </div>
        </ng-template>
        <ng-template #nextvideo>
          <div
            class="col-12 col-sm-5 col-lg-6 p-0 pointer"
            (click)="
              getMessage(video.classId, video.uploadedDate, video.uploadedTime)
            "
          >
            <div
              class="col-12 p-0 row m-0 next-video-image"
              style="align-items: center"
            >
              <img
                [src]="
                  video.videoThumbnail === undefined ||
                  video.videoThumbnail === ''
                    ? this.NO_THUMBNAIL
                    : video.videoThumbnail
                "
                alt=""
                class="col-12 m-0 p-0 mb-1 pointer next-video-image"
                style="position: absolute; opacity: 0.2"
              />
              <div class="col-12 text-center">
                <i class="material-icons pointer" style="font-size: 3em">lock</i
                ><br />
                <span class="pointer" style="font-size: 1.2em"
                  >Unlock This Video</span
                ><br />
              </div>
            </div>
          </div>
          <div
            (click)="
              getMessage(video.classId, video.uploadedDate, video.uploadedTime)
            "
            class="col-12 col-sm-7 col-lg-6 m-0 p-0 mt-2 mt-md-0 pl-sm-3"
          >
            <p class="next-video-p m-0 p-0">
              <b>{{ video.videoTitle }}</b>
            </p>
            <span class="text-secondary mt-0" style="font-size: 0.8em"
              ><b>{{ video.teacherName }}</b></span
            >
          </div>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #novideo>
    <div></div>
  </ng-template>
</div>
