

<div class="py-2">
  <h1>Digi Conference</h1>
  <mat-tab-group class="mx-auto">
    <mat-tab label="Conferences">
      <div class="container-fluid">
        <ng-template [ngIf]="activeconf !== 0" [ngIfElse]="noconference">
          <div class="col-12">
            <div class="row">
              <ng-container
                *ngFor="let conference of conferences; let i = index"
              >
                <div
                  class="px-2 col-12 col-md-6 col-lg-3 my-3"
                  *ngIf="!isExpired(conference.day)"
                >
                  <div
                    class="card my-3 w-100 image"
                    style="width: 18rem"
                    (click)="
                      openZoomMeating(
                        i,
                        getRemainingTime(
                          conference.day,
                          conference.startingTime,
                          true
                        )
                      )
                    "
                  >
                    <img
                      class="card-img-top mx-auto"
                      src="{{ conference.conferenceBannerUrl }}"
                      alt="Card image cap"
                    />
                    <div
                      class="join"
                      *ngIf="
                        getRemainingTime(
                          conference.day,
                          conference.startingTime,
                          true
                        )
                      "
                    >
                      <div class="bg-success p-2 rounded text-white px-2">
                        <div
                          class="bg-white rounded-circle d-inline-block mx-2"
                          style="height: 12px; width: 12px"
                        ></div>
                        <span class="pb-1">Join Now</span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-3">
                        <img
                          src="{{ conference.teacherPic }}"
                          alt="Profile"
                          width="40px"
                          class="mt-2 mx-3 rounded-circle"
                        />
                      </div>
                      <div class="col-9 h-100 py-2">
                        <p
                          class="time m-0"
                          style="height: fit-content; font-weight: bold"
                        >
                          {{ conference.conferenceTitle }}
                        </p>

                        <span
                          *ngIf="conference.conferencePrice !== '0'"
                          class="text-danger"
                          >{{
                            conference.conferencePrice | currency: "LKR "
                          }}</span
                        >
                        <span
                          *ngIf="conference.conferencePrice === '0'"
                          class="text-success"
                          >FREE</span
                        >
                      </div>
                    </div>
                    <div class="w-100 mx-3 my-2">
                      <h5 class="card-title m-0">
                        Schedule : {{ conference.day }} |
                        {{ conference.startingTime }} -
                        {{ conference.endingTime }} |
                        {{
                          getRemainingTime(
                            conference.day,
                            conference.startingTime
                          )
                        }}
                      </h5>

                      <h5 class="card-title m-0"></h5>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-template>

        <ng-template #noconference>
          <div class="container" style="overflow: hidden">
            <div class="col-12 offset-md-4 col-md-4">
              <ng-lottie
                [options]="option"
                class="mx-auto m-0 text-center"
                width="100%"
                height="auto"
              >
              </ng-lottie>
              <h2 class="text-center mx-auto">No Active Conferences</h2>
            </div>
          </div>
        </ng-template>
      </div>
    </mat-tab>
    <mat-tab label="Conference Payments">
      <ng-template [ngIf]="payedConferences.length > 0" [ngIfElse]="nopayments">
        <div class="col-12">
          <div class="row">
            <div
              class="px-2 col-12 col-md-6 col-lg-3 my-3"
              *ngFor="let conference of payedConferences; let i = index"
            >
              <div
                class="card my-3 w-100 image"
                style="width: 18rem"
                (click)="openPayedZoomMeating(i, true)"
              >
                <img
                  class="card-img-top mx-auto"
                  src="{{ conference.conferenceBanner }}"
                  alt="Card image cap"
                />

                <div class="row mx-2">
                  <div class="col-12 p-0 h-100 py-2">
                    <p
                      class="time m-0"
                      style="height: fit-content; font-weight: bold"
                    >
                      {{ conference.conferenceTitle }}
                    </p>
                    <p class="">Paid Date : {{ conference.dateandtime }}</p>

                    <a
                      *ngIf="conference.bnkSlipUrl"
                      href="{{ conference.bnkSlipUrl }}"
                      target="_blank"
                      class="row m-0"
                      style="
                        align-items: center;
                        width: fit-content;
                        text-decoration: none;
                      "
                    >
                      <span>Attachment </span>
                      <span
                        class="material-icons"
                        style="transform: rotateZ(90deg)"
                        >attach_file</span
                      >
                    </a>
                    <span
                      >Payment Status :
                      <span
                        [ngClass]="
                          conference.paymentState === 1
                            ? 'text-success'
                            : conference.paymentState === 0
                            ? 'text-warning'
                            : 'text-danger'
                        "
                        >{{
                          conference.paymentState === 1
                            ? "Approved"
                            : conference.paymentState === 0
                            ? "Pending"
                            : "Cancelled"
                        }}</span
                      ></span
                    >

                    <!-- {'text-success': conference.paymentState === 'Allowed', 'text-warning': conference.paymentState === 'Pending', 'text-danger': conference.paymentState === 'Cancelled'} -->
                  </div>
                </div>
                <div class="w-100 mx-2 my-2">
                  <h5 class="card-title m-0">
                    Schedule : {{ conference.schedule }}
                  </h5>

                  <h5 class="card-title m-0"></h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #nopayments>
        <div class="container" style="overflow: hidden">
          <div class="col-12 offset-md-4 col-md-4">
            <ng-lottie
              [options]="option"
              class="mx-auto m-0 text-center"
              width="100%"
              height="auto"
            >
            </ng-lottie>
            <h2 class="text-center mx-5">No Conference Payment</h2>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
