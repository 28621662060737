import { DatePipe } from '@angular/common';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { AnimationOptions } from 'ngx-lottie';
import { elementAt } from 'rxjs/operators';
import { DatabaseManager } from '../database/database-manager';
import { MessageButtons, MessageType } from '../messagebox/messagebox.component';
import { Inquiry } from '../models/inquiry';
import { Student } from '../models/student';
import { AlertService } from '../services/alert.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  //vr
  userName: string = "";
  userEmail: string = "";
  message: string = "";
  telNumber: string = "";
  emailLock: boolean = false;

  searchContainer = document.getElementById("searchContainer");

  constructor(private auth: AuthService, private dataService: DatabaseManager, private alertService: AlertService, private datepipe: DatePipe, private title: Title, private metaTagService: Meta) {
    //wait until user connecting with the server
    this.searchContainer.hidden = true;
    this.loadContent();
  }

  ngOnInit(): void {
    this.title.setTitle("Contact Us | Digi Panthiya");
    this.metaTagService.updateTag(
      { name: 'description', content: 'We\'d love to connect you with the largest learning management system in Sri Lanka. Join us.' }
    )
  }

  loadContent() {
    this.auth.isUserAvailable().then(res => {
      if (res) {
        this.dataService.getStudent(this.auth.userData.uid).then((element: Student) => {
          this.userName = element.firstName + " " + element.lastName;
          this.userEmail = element.email;
          this.telNumber = element.studentTp1;
        });
        this.emailLock = true;
      } else {
        this.userEmail = "";
        this.userName = "";
        this.emailLock = false;
      }
    });
  }

  addInquiry() {
    if (this.message === "" || this.userName === "" || this.userEmail === "") {
      this.alertService.showAlert("Empty Fields...", MessageButtons.ok, MessageType.accessBlocked);
    } else {
      var inquiry: Inquiry = {
        email: this.userEmail,
        message: this.message,
        name: this.userName,
        isStudent: true,
        telNumber: this.telNumber,
        date: this.datepipe.transform(new Date(), "yyyy-MM-dd"),
        time: this.datepipe.transform(new Date(), "HH:mm")
      }
      this.dataService.addInquiry(inquiry);
      this.alertService.showAlert("Message Sent Successfully", MessageButtons.ok, MessageType.wow).afterClosed().subscribe(result => {
        this.loadContent();
        this.message = "";
      });
    }
  }

}
