import { JitEmitterVisitor } from '@angular/compiler/src/output/output_jit';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { AnimationOptions } from 'ngx-lottie';
import { DatabaseManager } from '../database/database-manager';
import { MessageButtons, MessageType } from '../messagebox/messagebox.component';
import { AlertService } from '../services/alert.service';
import { ProfileValidatorService } from '../services/profile-validator.service';

export class Constants {
  static readonly KEY_DEFAULT_LANGUAGE: string = "lang";
  static readonly EMAIL: string = "email";
  static readonly FIRST_TYPE: number = 1;
  static readonly SECOND_TYPE: number = 2;
  static readonly PROFILE_PIC_WIDTH: number = 800;
  static readonly PROFILE_PIC_HEIGHT: number = 800;
  static readonly MAX_PROFILE_PIC_SIZE: number = 6000;
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  imageChangedEvent: any = "";
  croppedImage: string = "";
  isImageValid: boolean = false;
  showCropped: boolean = false;
  selectedFile: File;
  width: number;
  height: number;
  backText: string = "Close";

  option: AnimationOptions = {
    path: '/assets/lti/lti_gallery.json', loop: false
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, private imageCompress: NgxImageCompressService,
    private dbManager: DatabaseManager, private alertService: AlertService,
    private dialogRef: MatDialogRef<ImageUploadComponent>,
    public profileValidator: ProfileValidatorService
  ) { }

  ngOnInit() { }

  fileChangeEvent(event: any): void {
    this.selectedFile = event.target.files[0];
    var fSize = event.target.files[0].size / 1024;
    if (fSize > Constants.MAX_PROFILE_PIC_SIZE) {
      this.alertService.showAlert("The maximum size for the image upload is 6MB.", MessageButtons.ok, MessageType.accessBlocked);
      return;
    }
    if (event.target.files[0] && event.target.files.length) {
      let file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // if (this.checkImgValidity(width, height)) {
          this.isImageValid = true;
          this.imageChangedEvent = event;
          // } else {
          //   this.isImageValid = true;
          //   alert("image should be smaller than 800 X 800");
          // }
        };
      };
    }
  }
  imageCropped(event: ImageCroppedEvent) {
    // this.croppedImage = event.base64;
    // console.warn('Size in KB was:', (this.imageCompress.byteCount(event.base64)));
    this.imageCompress.compressFile(event.base64, -1, 50, 50).then(
      result => {
        this.croppedImage = result;
        // console.warn('Size in kB was:', (this.imageCompress.byteCount(result)));
      });
    /* const imageBlob = this.dataURItoBlob(event.base64);
    const imageFile = new File([imageBlob], this.selectedFile.name, {
      type: this.selectedFile.type,
    }); */
  }
  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  save() {
    this.showCropped = true;
    this.backText = "Back";
  }
  back() {
    if (this.showCropped) {
      this.showCropped = false;
      this.backText = "Close"
    } else {
      this.dialogRef.close();
    }
  }

  checkImgValidity(width, height) {
    var value =
      width <= Constants.PROFILE_PIC_WIDTH &&
        height <= Constants.PROFILE_PIC_HEIGHT
        ? true
        : false;
    return value;
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/jpg" });
    return blob;
  }

  removeImage() {
    this.dbManager.updateStudent(
      this.data.studentId,
      { profilePic: "" }
    );
    this.close();
  }

  upload() {
    this.dbManager.updateStudent(
      this.data.studentId,
      { profilePic: this.croppedImage }
    );
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
