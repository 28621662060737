import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { DatabaseManager } from '../database/database-manager';
import { Notice } from '../models/notice';
import { AuthService } from '../services/auth.service';
import { DateTime } from '../util/datetime';
import { AnimationOptions } from 'ngx-lottie';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.scss'],
})
export class NoticesComponent implements OnInit {
  searchContainer = document.getElementById('searchContainer');
  expandId = -1;
  hideDEL: number = -1;
  myLineHeight = 0;
  studentNotices: Notice[] = [];
  option: AnimationOptions = {
    path: '/assets/lti/lti_notices.json',
    loop: false,
  };

  constructor(
    private dataservice: DatabaseManager,
    private auth: AuthService,
    private datetime: DateTime,
    private title: Title
  ) {
    this.searchContainer.hidden = true;
    //wait until user connecting with the server
    this.auth.isUserAvailable().then((res) => {
      if (res) {
        this.loadNotices();
      }
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Notices | Digi Panthiya');
  }

  loadNotices() {
    this.dataservice
      .getStudentNotices('cznRbANZ4TVYAvDQ4aIEcQyIpHI2')
      .then((elements) => {
        console.log(elements);
        this.studentNotices = elements;
      });
  }

  getTime(time: string) {
    var data = '--';
    try {
      data = this.datetime.get12HrsTime(time);
    } catch (e) {
      console.log(e.message);
    }
    return data;
  }

  showDEL(id: number) {
    this.hideDEL = id;
  }

  removeDEL() {
    this.hideDEL = -1;
  }

  deleteNotice(id: string) {
    this.dataservice.deleteStudentNotice(id, this.auth.userData.uid);
    this.loadNotices();
  }
}
