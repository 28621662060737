<div class="bg-white rounded container p-0">
    <div class="row m-0">
        <span class="material-icons rounded-circle bg-white p-2" style="margin-left: auto;margin-right: 0%;"
            mat-dialog-close>close</span>
        <div class="float-right">
            <img [src]="data.class.subjectPic" alt="data.class.subjectName" width="100%" height="100%">
        </div>
    </div>
    <div class="row m-0 my-3">
        <div class="p-0 col-md-6 px-3">
            <h2><b>Class Details</b></h2>
            <div class="row m-0">
                <span class="class-details col-6 col-sm-4 col-md-8 p-0">Default Day: </span>
                <span class="class-details">{{data.class.day}}</span>
            </div>
            <div class="row m-0">
                <span class="class-details col-6 col-sm-4 col-md-8 p-0">Default Starting time: </span>
                <span class="class-details">{{data.class.startingTime}}</span>
            </div>
            <div class="row m-0">
                <span class="class-details col-6 col-sm-4 col-md-8 p-0">Default Ending: </span>
                <span class="class-details">{{data.class.endingTime}}</span>
            </div>
            <div class="row m-0">
                <span class="class-details col-6 col-sm-4 col-md-8 p-0">Class Type: </span>
                <span class="class-details">{{data.class.classType}}</span>
            </div>
        </div>
        <div class="p-0 col-md-6 px-3 mt-3 mt-md-0">
            <h3><b>Some Extra classes were held in:</b></h3>
            <div class="row m-0">
                <span class="class-details col-4 col-sm-3 col-md-4 p-0">Monday: </span>
                <span class="class-details">8.00 - 10.00</span>
            </div>
            <div class="row m-0">
                <span class="class-details col-4 col-sm-3 col-md-4 p-0">Tuesday: </span>
                <span class="class-details">18.00 - 20.00</span>
            </div>
        </div>
    </div>
    <div class="offset-md-2 col-md-8 offset-1 col-10 text-white mt-5" *ngIf="data.classStatus">
        <div class="row rounded pointer pb-2 pt-3 mb-4" style="background-color: #D49D07;" (click)="payNow()"
            *ngIf="data.classStatus == 2">
            <div class="col-6 p-0">
                <div class="text-center row m-0">
                    <div style="font-size: 1.2em;" class="col-12">{{getCurrencyStr(data.class.classFee)}}</div>
                    <div style="font-size: .7em;margin-top: -5px;" class="col-12">per month</div>
                </div>
            </div>
            <div class="col-6 p-0">
                <div class="row m-0" style="align-items: center;">
                    <i class="material-icons" style="font-size: 1.7em;">meeting_room</i>
                    <Span class="ml-2 ml-sm-3" style="font-size: 1.3em;">Enroll Me</Span>
                </div>
            </div>
        </div>
        <div class="row rounded pointer py-3 mb-4 text-center"
            style="background-color: #d6b65e;justify-content: center;" *ngIf="data.classStatus == 1">
            <span style="font-size: 1.3em;">Already Enrolled!</span>
        </div>
    </div>
</div>