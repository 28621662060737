import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum MessageButtons {
  ok,
  YesNo,
  resend_verification_email,
}

export enum MessageType {
  wow = "ALMOST THERE",
  accessBlocked = "OOPS!",
  warning = "UH-OH!",
  success = "Done!",
  accountCreated = "Account Created"
}

export interface MsgBoxData {
  messageType: MessageType;
  messageButtons: MessageButtons;
  message: string;
}

export enum DialogResult {
  close = "close",
  ok = "ok",
  yes = "yes",
  resend_verification_email = "Resend Verification Email",
}

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss']
})
export class MessageboxComponent implements OnInit {

  messageButtons = MessageButtons;
  messageTypes = MessageType;
  //form var
  RESULT_OK: DialogResult = DialogResult.ok;
  RESULT_YES: DialogResult = DialogResult.yes;
  RESULT_CLOSE: DialogResult = DialogResult.close;
  RESEND_VERIFY_EMAIL: DialogResult = DialogResult.resend_verification_email;

  constructor(public dialogRef: MatDialogRef<MessageboxComponent>, @Inject(MAT_DIALOG_DATA) public data: MsgBoxData) {
  }

  ngOnInit() {
  }

  onClose(parm?: boolean): void {
    this.dialogRef.close(DialogResult.close);
  }

  setResult(result: DialogResult) {
    this.dialogRef.close(result);
  }

  clickOk() {
    this.dialogRef.close(DialogResult.ok);
  }
}
